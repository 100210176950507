export const english_strings = {
  accept_clients: 'Accept New Clients',
  analytics: 'Analytics',
  administration: 'Administration',
  assessments: 'Assessments',
  billing: 'Billing',
  billing_setup: 'Billing Setup',
  blog: 'Blog',
  calendar: 'Calendar',
  challenges: 'Challenges',
  chat: 'Chat',
  classes: 'Classes',
  client_billing: 'Client Billing',
  clients: 'Clients',
  customer_service: 'Customer Service',
  workout_recording_explanation:
    'This assessment is used to help your trainer understand where you are currently. A recording of your webcam will be shared with your trainer to review when you click Finish. If you would like to work out without recording, click Cancel Recording.',
  cancel_recording: 'Cancel Recording',
  finish_recording: 'Finish Recording',
  download: 'Download',
  community: 'Community',
  connect_to_trainers: 'Connect To Trainers',
  connections: 'Connections',
  employees: 'Employees',
  help: 'Help',
  insights: 'Insights',
  launch_class: 'Launch Class',
  leaderboard: 'Leaderboard',
  logout: 'Logout',
  manage: 'Manage',
  manage_gyms: 'Manage Gyms',
  my_goals: 'My Goals',
  notifications: 'Notifications',
  on_demand: 'On-Demand',
  on_demand_videos: 'On-Demand Videos',
  plans: 'Plans',
  profile: 'Profile',
  progress: 'Progress',
  report_bug: 'Report Bug',
  rewards: 'Rewards',
  schedule: 'Schedule',
  swag_store: 'Swag Store',
  trainers_clients: 'Trainers / Clients',
  view: 'View',
  workout_results: 'Workout Results',
  share_twitter: 'Share to Twitter',
  no_data: 'No data collected yet!',
  your_score: 'Your Score',
  your_stats: 'Your Stats',
  waiting: ({ others }: any) => `Waiting for ${others} to join . . .`,
  others: 'others',
  one_hour_consulting: 'One Hour of Consulting',
  ten_hours_consulting: 'Ten Hours of Consulting',
  payment: 'Payment',
  individual_training_description:
    'Each month we will meet live for a private 30 minute class, which will be edited into an exclusive video and added to your private library for continued use. Includes an initial 30 minute consultation and access to MotivateU’s PRO app.',
  individual: 'Individual',
  small_group_description_1:
    'Small group classes must be arranged by the class members themselves, meaning that all class members must agree to be in a class together and agree on the scheduled meeting times.',
  small_group_description_2:
    'The first person of each group to register should select a one hour time slot for the first class (subsequent monthly classes are 30 minutes). On the calendar registration please list the other members of your group. All group members must register and pay but only one member should schedule the first class. Upon receipt of payment members will receive a calendar link and future information. Thank you.',
  small_group_2_3: 'Small Group (2 to 3)',
  small_group_4_8: 'Small Group (4 to 8)',
  no_classes: 'You do not have any classes scheduled with your trainer',
  join_session: 'Join Session',
  not_connected: 'You are not currently connected to a trainer. You can',
  choose_training_option: 'Choose Training Option',
  you_need_connect:
    'You need to connect to a trainer to take a live class. Would you like to connect to a trainer for one of these class types?',
  hide_webcam: 'Hide your webcam feed from other clients in the class',
  show_webcam: 'Share your webcam feed with other clients in the class',
  hang_up: 'Hang up',
  current_challenge: 'Current Challenge',
  past_challenges: 'Past Challenges',
  community_only: 'Community Fitness Videos Only',
  gym_only: 'My Trainer / Gym Only',
  all_recordings: 'All Videos',
  join_waiting_list: 'Join Waiting List',
  sign_up: 'Get Started',
  create: 'Create',
  manage_menu: 'Manage Menu',
  account_settings: 'Account Settings',
};
