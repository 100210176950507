export const sum = (values: number[]) => values.reduce((a, b) => a + b, 0);
export const product = (values: number[]) => values.reduce((a, b) => a * b, 1);
export const mean = (values: number[]) => (values.length ? sum(values) / values.length : 0.5);

export function choose<T>(options: T[]): T {
  return options[Math.floor(Math.random() * 0.99999999 * options.length)];
}

export function linReg(xs: number[], ys: number[]): number[] {
  const sx = sum(xs); // Σx
  const sx2 = sum(xs.map((x) => x * x)); // Σx^2
  const sy = sum(ys); // Σy
  const n = xs.length; // len(x)
  const sxy = sum(xs.map((x, i) => x * ys[i])); // Σxy

  // formula from https://www.wallstreetmojo.com/regression-formula/
  const denom = n * sx2 - sx * sx;
  const m = (n * sxy - sx * sy) / denom;
  const b = (sy * sx2 - sx * sxy) / denom;
  return [m, b];
}
