import React from 'react';
import { useUser } from 'services';
import { Redirect } from 'react-router-dom';

const pages = {
  CONSUMER: '/recording-list',
  CLIENT: '/recording-list',
  INSTRUCTOR: '/instructor/manage-sessions',
  EMPLOYER: '/employer/manage-users',
  OWNER: '/manage-trainers',
  FRANCHISE: '/manage-trainers',
  PARTNER: '/video-library',
  ADMIN: '/admin',
};

export const Home: React.FC = () => {
  const user = useUser();
  if (user?.userType === 'CONSUMER' && user.gym?.whiteLabel) return <Redirect to='/gym-plans' />;
  if (user) return <Redirect to={pages[(localStorage.overrideUserType as 'ADMIN') || user.userType]} />;

  return <div />;
};
