import React, { HTMLProps, ReactNode } from 'react';
import './UserDisplay.scss';
import { User, UserPublish } from 'common';
import { UserPhoto } from '../UserPhoto/UserPhoto';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

interface UserDisplayProps<T extends User | UserPublish> extends HTMLProps<HTMLDivElement> {
  user: T;
  onDelete?: (user: T) => void;
  extras?: (user: T) => ReactNode;
  details?: (user: T) => ReactNode;
}

export function UserDisplay<T extends User | UserPublish>({ user, onDelete, extras, details, ...props }: UserDisplayProps<T>) {
  return (
    <div className='user-display' {...props}>
      <UserPhoto user={user} />
      <div className='user-name'>
        <span className='user-name mu-text-md'>{user.name}</span>
        <span className='user-email mu-text-sm'>
          {details ? details(user) || <React.Fragment>&nbsp;</React.Fragment> : (user as User).email || user.username}
        </span>
      </div>
      {onDelete ? <FontAwesomeIcon icon={faTimes} onClick={() => onDelete(user)} /> : null}
      {extras ? extras(user) : null}
    </div>
  );
}
