import React from 'react';
import './ProgressBar.scss';

interface ProgressBarProps {
  progress: number;
  text?: string;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ progress, text }) => {
  return (
    <div className='progress-bar' style={text ? { height: 20 } : {}}>
      <div className='progress' style={{ width: progress * 100 + '%' }}>
        {progress >= 0.5 && text ? text : null}
      </div>
      <div className='remainder' style={{ width: (1 - progress) * 100 + '%' }}>
        {progress < 0.5 && text ? text : null}
      </div>
    </div>
  );
};
