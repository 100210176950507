import React, { ReactNode, useState } from 'react';
import { EntityRest } from 'common';
import { MuButton, MuCheckbox, MuInput, MuSelect } from 'components/input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import ReactModal from 'react-modal';

type ID = { id: number };

interface UseSaveFunctionProps<T extends ID> {
  item?: T;
  required?: any[];
  struct?: Partial<T>;
  api?: EntityRest<T>;

  onClose?(): void;
}

interface UseSaveFunctionResults {
  saving: boolean;
  showRequired: boolean;

  save(): Promise<void>;

  setSaving(value: boolean): void;

  setShowRequired(value: boolean): void;
}

export function useSaveFunction<T extends ID>({ item, required, struct, api, onClose }: UseSaveFunctionProps<T>): UseSaveFunctionResults {
  const [saving, setSaving] = useState(false);
  const [showRequired, setShowRequired] = useState(!!item);

  async function save() {
    setShowRequired(true);
    if (required) for (const val of required) if (!val) return;
    setSaving(true);
    if (api && struct) {
      if (item) await api.update({ id: item.id, ...struct });
      else await api.create(struct);
    }
    setSaving(false);
    onClose && onClose();
  }

  return { save, saving, setSaving, showRequired, setShowRequired };
}

interface EditField<T extends ID> {
  label: string;
  field: keyof T;
  default: any;
  type?: 'text' | 'number' | 'select' | 'checkbox';
  selectOptions?: { value: any; display: string }[];
  required?: boolean;
}

interface RecordEditorProps<T extends ID> {
  createButtonText?: string;
  fields?: EditField<T>[];
  api?: EntityRest<T>;
}

interface RecordEditorResults<T extends ID> {
  create: boolean;
  edit: T | undefined;
  createButton: ReactNode;
  createButtonWrapper: ReactNode;
  modal: ReactNode | null;

  setCreate(val: boolean): void;

  setEdit(val: T | undefined): void;

  closeModal(): void;
}

interface EditItemProps<T extends ID> {
  item: T | undefined;
  fields: EditField<T>[];
  api: EntityRest<T>;

  onClose(): void;
}

function EditItem<T extends ID>({ item, api, fields, onClose }: EditItemProps<T>) {
  const states: { [field in keyof T]: [any, (val: any) => void] } = {} as any;
  for (const field of fields) {
    states[field.field] = useState(item ? item[field.field] : field.default);
  }

  const struct: Partial<T> = {};
  const required: any[] = [];
  for (const field of fields) {
    struct[field.field] = states[field.field][0];
    if (field.required) required.push(states[field.field][0]);
  }

  const { save, saving, showRequired } = useSaveFunction({ item, required, struct, api, onClose });

  const form: ReactNode[] = [];

  for (const field of fields) {
    switch (field.type) {
      case undefined:
      case null:
      case 'text':
        form.push(
          <MuInput
            value={states[field.field][0]}
            onChange={states[field.field][1]}
            label={field.label}
            required={field.required && showRequired}
          />,
        );
        break;
      case 'number':
        form.push(
          <MuInput
            type='number'
            value={states[field.field][0]}
            onChange={states[field.field][1]}
            label={field.label}
            required={field.required && showRequired}
          />,
        );
        break;
      case 'select':
        form.push(
          <MuSelect
            value={states[field.field][0]}
            onChange={states[field.field][1]}
            options={field.selectOptions || []}
            label={field.label}
            required={field.required && showRequired}
          />,
        );
        break;
      case 'checkbox':
        form.push(<MuCheckbox checked={states[field.field][0]} onChange={states[field.field][1]} label={field.label} />);
        break;
    }
  }

  return (
    <div>
      {form}
      <br />
      <MuButton onClick={save} loading={saving} loadingText='Saving . . .' className='full-width'>
        Save
      </MuButton>
    </div>
  );
}

export function useRecordEditor<T extends ID>({ createButtonText, api, fields }: RecordEditorProps<T>): RecordEditorResults<T> {
  const [create, setCreate] = useState(false);
  const [edit, setEdit] = useState<T>();

  function closeModal() {
    setCreate(false);
    setEdit(undefined);
  }

  const createButton = (
    <MuButton onClick={() => setCreate(true)} narrow className='outline'>
      <FontAwesomeIcon icon={faPlus} /> &nbsp;{createButtonText || 'Create'}
    </MuButton>
  );
  const createButtonWrapper = <div className='page-actions'>{createButton}</div>;

  const modal =
    api && fields ? (
      <ReactModal isOpen={create || !!edit} onRequestClose={closeModal}>
        <EditItem<T> item={edit} api={api} fields={fields} onClose={closeModal} />
      </ReactModal>
    ) : null;

  return { create, setCreate, edit, setEdit, closeModal, createButton, createButtonWrapper, modal };
}
