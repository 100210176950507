import React, { useState } from 'react';
import { StepType, TourProvider } from '@reactour/tour';
import { getPreload, MotivateU, useEffectAsync, useUser } from 'services';
import type { InfoGuide, InfoGuideStep } from 'common';

export const TourWrapper: React.FC = ({ children }) => {
  const [tour, setTour] = useState(false);
  const [steps, setSteps] = useState<StepType[]>([]);
  const user = useUser();

  useEffectAsync(async () => {
    if (!user) return;
    const preload = await getPreload();
    for (const view of preload.infoViews) localStorage[view.infoName] = true;

    const guides = preload.infoGuides.filter((g: InfoGuide) => !localStorage[g.infoName]);

    if (guides.length) {
      const guide = guides[0];
      const steps = await MotivateU.api.infoGuideSteps.filter({ limit: -1, filters: { guideId: guide.id } });
      setSteps(steps.map((step: InfoGuideStep) => ({ selector: step.elementId, content: step.text })));
      setTour(true);
      localStorage[guide.infoName] = true;
      await MotivateU.api.infoViews.create({ infoName: guide.infoName });
    }
  }, [user]);

  if (tour)
    return (
      <TourProvider steps={steps} defaultOpen>
        {children}
      </TourProvider>
    );
  return <>{children}</>;
};
