import { MotivateU } from './constants';
import { DependencyList, useEffect } from 'react';

export function readFile(file: File): Promise<string> {
  return new Promise((resolve) => {
    const fr = new FileReader();
    fr.onloadend = (event) => {
      resolve(event.target!.result as string);
    };
    fr.readAsDataURL(file);
  });
}

export function sleep(ms: number) {
  return new Promise((resolve) => setInterval(resolve, ms));
}

export async function startPayment(priceId: string) {
  window.location.href = await MotivateU.api.getPaymentUrl({ priceId });
}

export function useEffectAsync(effect: () => Promise<void>, inputs?: DependencyList) {
  useEffect(() => {
    effect();
  }, inputs);
}
