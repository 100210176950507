import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode, useState } from 'react';
import './MuAlert.scss';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import type { InfoView } from 'common';
import { MotivateU, useEffectAsync } from 'services';

interface MuAlertProps {
  content: string | ReactNode;
  className?: string;
  backgroundColor?: string;
  color?: string;
  close?: boolean;
  infoView?: string;
  onClose?: () => void;
}

export const MuAlert: React.FC<MuAlertProps> = ({
  content,
  className = '',
  color = 'black',
  backgroundColor = 'var(--c2)',
  close,
  infoView,
  onClose,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [views, setViews] = useState<InfoView[]>([]);

  useEffectAsync(async () => {
    if (!loaded && infoView && !localStorage[infoView]) {
      let user = MotivateU.getUserInfoStatic();
      console.log('Trying to get user info', user);
      if (!user) user = await MotivateU.getUserInfo();
      console.log('User:', user);
      const views = await MotivateU.api.infoViews.filter({ limit: -1, filters: { userId: user.id } });
      for (const view of views) localStorage[view.infoName] = true;
      setViews(views);
      setLoaded(true);
    }
  }, []);

  if (infoView) {
    if (localStorage[infoView]) return null;
    if (!loaded) return null;
    for (const view of views) if (view.infoName === infoView) return null;
  }

  async function closeInfo() {
    if (infoView) {
      const view = await MotivateU.api.infoViews.create({ infoName: infoView });
      localStorage[infoView] = true;
      setViews(views.concat(view));
    }
  }

  return (
    <div className={'mu-alert ' + className} style={{ color, backgroundColor }}>
      {content}
      {close ? <FontAwesomeIcon icon={faTimes} className='close' onClick={onClose} /> : null}
      {infoView ? <FontAwesomeIcon icon={faTimes} className='close' onClick={closeInfo} /> : null}
    </div>
  );
};
