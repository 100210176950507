import { MotivateUSDK } from 'common';
import { getToken } from './auth';
import { getPreload } from 'services/util/preload';

export const USER_TYPE_OPTIONS = [
  { value: '', display: '-' },
  { value: 'CONSUMER', display: 'Consumer' },
  { value: 'CLIENT', display: 'Client' },
  { value: 'INSTRUCTOR', display: 'Instructor' },
  { value: 'EMPLOYER', display: 'Employer' },
  { value: 'OWNER', display: 'Gym Owner' },
  { value: 'FRANCHISE', display: 'Franchise Owner' },
  { value: 'PARTNER', display: 'Partner' },
  { value: 'ADMIN', display: 'Admin' },
];

export const CONSUMER_OPTIONS = [
  { value: '', display: '-', business: false },
  { value: 'FREE', display: 'Preview', business: false },
  { value: 'PREMIUM', display: 'Premium', business: false },
  { value: 'PRO', display: 'Pro', business: false },
  { value: 'PERFORMANCE', display: 'Performance', business: true },
  { value: 'PLATINUM', display: 'Platinum', business: false },
  { value: 'PERFORMANCE_PLUS', display: 'Performance Plus', business: true },
];

export const MotivateU = new MotivateUSDK(getToken, async () => (await getPreload()).user);
