import { getToken } from 'services/util';

enum SyncServerStatus {
  CONNECTING,
  CONNECTED,
  RECONNECTING,
  CLOSED
}

const BASE_URL = process.env.REACT_APP_SYNC_SERVER || 'ws://localhost:8123';

export class SyncServer {
  ws: WebSocket;
  status: SyncServerStatus = SyncServerStatus.CONNECTING;
  startUrl = window.location.href;
  messageQueue: string[] = [];
  syncHandlers: { [type: string]: ((message: any) => void)[] } = {};

  constructor(public config: { sessionId?: number; recordingId?: number }) {
    const { sessionId, recordingId } = config;
    this.ws = new WebSocket(sessionId ? `${BASE_URL}/session/${sessionId}` : `${BASE_URL}/recording/${recordingId}`);
    this.ws.onclose = this.handleClose.bind(this);

    const handleChange = () => (window.location.href !== this.startUrl ? this.ws.close() : null);
    window.addEventListener('popstate', handleChange);
    const oldReplaceState = history.replaceState;
    history.replaceState = (...args) => {
      handleChange();
      oldReplaceState(...args);
    };
    const oldPushState = history.pushState;
    history.pushState = (...args) => {
      handleChange();
      return oldPushState(...args);
    };
  }

  async init() {
    this.ws.send(JSON.stringify({ token: await getToken() }));
    await new Promise((resolve) => (this.ws.onmessage = resolve));
    this.ws.onmessage = this.handleMessage.bind(this);
    this.status = SyncServerStatus.CONNECTED;
    for (const msg of this.messageQueue.splice(0)) this.ws.send(msg);
  }

  closeCount = 0;
  private async handleClose() {
    if (window.location.href !== this.startUrl) return (this.status = SyncServerStatus.CLOSED);

    this.status = SyncServerStatus.RECONNECTING;
    this.closeCount++;
    if (this.closeCount > 5) {
      this.status = SyncServerStatus.CLOSED;
      alert('Could not connect to the server');
      return (window.location.href = '/');
    }
    const { sessionId, recordingId } = this.config;
    this.ws = new WebSocket(sessionId ? `${BASE_URL}/session/${sessionId}` : `${BASE_URL}/recording/${recordingId}`);
    this.ws.onclose = this.handleClose.bind(this);
    await this.init();
  }

  registerHandler(type: string, handler: (message: any) => void) {
    this.syncHandlers[type] = this.syncHandlers[type] || [];
    this.syncHandlers[type].push(handler);
  }

  sendMessage(type: string, message: any) {
    const msg = JSON.stringify({ type, ...message });
    if (this.status === SyncServerStatus.CONNECTED) this.ws.send(msg);
    else this.messageQueue.push(msg);
  }

  close() {
    this.status = SyncServerStatus.CLOSED;
    this.startUrl = '';
    this.ws.close();
  }

  private handleMessage = (msg: MessageEvent) => {
    msg = JSON.parse(msg.data);
    if (msg.type in this.syncHandlers) for (const handler of this.syncHandlers[msg.type]) handler(msg);
  };
}
