import * as c from '../types/custom';
import * as e from '../types/entities';
import * as events from 'events';
import { createAPI } from './api';
export * from './util';

export const USER_TYPES: e.UserType[] = ['CONSUMER', 'CLIENT', 'INSTRUCTOR', 'EMPLOYER', 'OWNER', 'FRANCHISE', 'PARTNER', 'ADMIN'];
export const CONSUMER_TYPES: e.ConsumerVersion[] = ['FREE', 'PREMIUM', 'PRO', 'PERFORMANCE', 'PLATINUM', 'PERFORMANCE_PLUS'];

function wrapUser(user: c.UserWithGym | undefined): c.UserWithGym | undefined {
  if (!user) return user;
  const result = { ...user };
  const { overrideUserType, overrideConsumerType } = localStorage;
  if (overrideUserType && USER_TYPES.indexOf(overrideUserType) <= USER_TYPES.indexOf(user.userType)) result.userType = overrideUserType;
  if (overrideConsumerType && CONSUMER_TYPES.indexOf(overrideConsumerType) <= CONSUMER_TYPES.indexOf(user.consumerVer))
    result.consumerVer = overrideConsumerType;
  return result;
}

const baseUrl: string = process.env.REACT_APP_API_URL || 'https://api.motivateu.live';

export class MotivateUSDK {
  private userInfo?: c.UserWithGym;
  private eventEmitter = new events.EventEmitter();
  private userFetchStarted = false;

  constructor(private getToken: () => Promise<string | null>, private getUser?: () => Promise<c.UserWithGym>) {}

  private async makeAPICall(path: string, method: string, body?: string, headers?: any, options?: any, retries?: number): Promise<any> {
    if (method === 'GET' && retries == null) retries = 3;
    const idToken = await this.getToken();
    try {
      const resp = await fetch(`${baseUrl}/${path}`, {
        method,
        body,
        headers: { Authorization: `Bearer ${idToken}`, ...headers },
        ...options,
      });
      return await resp.json();
    } catch (e) {
      if (retries == null || retries <= 0) throw e;
      return this.makeAPICall(path, method, body, headers, options, retries - 1);
    }
  }

  public async updateUserInfo() {
    if (this.userFetchStarted) return new Promise((resolve) => this.eventEmitter.on('fetched', resolve));
    this.userFetchStarted = true;
    this.userInfo = await this.makeAPICall('get-user-info', 'GET', undefined, undefined, { cache: 'reload' });
    this.userFetchStarted = false;
    this.eventEmitter.emit('fetched');
  }

  public async getUserInfo(): Promise<c.UserWithGym> {
    if (!this.userInfo && this.getUser) this.userInfo = await this.getUser();
    if (!this.userInfo) await this.updateUserInfo();
    return wrapUser(this.userInfo)!;
  }

  public getUserInfoStatic(): c.UserWithGym | undefined {
    return wrapUser(this.userInfo) || undefined;
  }

  public updateUserCache(user: c.UserWithGym) {
    this.userInfo = user;
  }

  public api = createAPI(this.makeAPICall.bind(this));
}
