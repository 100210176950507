import * as c from '../types/custom';
import * as e from '../types/entities';
import type { HTTP } from './util';
import { EntityRest, createGetMethod, createPostMethod } from './util';

export function createAPI(http: HTTP) {
  return {
    accountSessions: new EntityRest<e.AccountSession>(http, 'account-sessions'),
    assessments: new EntityRest<e.Assessment>(http, 'assessments'),
    attendanceRecords: new EntityRest<e.AttendanceRecord>(http, 'attendance-records'),
    chatMessages: new EntityRest<e.ChatMessage>(http, 'chat-messages'),
    chatRooms: new EntityRest<e.ChatRoom>(http, 'chat-rooms'),
    clientSubscriptions: new EntityRest<e.ClientSubscription>(http, 'client-subscriptions'),
    emailTargets: new EntityRest<e.EmailTarget>(http, 'email-targets'),
    emailTemplates: new EntityRest<e.EmailTemplate>(http, 'email-templates'),
    emailTracks: new EntityRest<e.EmailTrack>(http, 'email-tracks'),
    emailTypes: new EntityRest<e.EmailType>(http, 'email-types'),
    employerPartnerships: new EntityRest<e.EmployerPartnership>(http, 'employer-partnerships'),
    franchises: new EntityRest<e.Franchise>(http, 'franchises'),
    goals: new EntityRest<e.Goal>(http, 'goals'),
    goalTypes: new EntityRest<e.GoalType>(http, 'goal-types'),
    gyms: new EntityRest<e.Gym>(http, 'gyms'),
    gymLinks: new EntityRest<e.GymLink>(http, 'gym-links'),
    gymOfferings: new EntityRest<e.GymOffering>(http, 'gym-offerings'),
    gymOfferingCategories: new EntityRest<e.GymOfferingCategory>(http, 'gym-offering-categories'),
    gymOfferingClients: new EntityRest<e.GymOfferingClient>(http, 'gym-offering-clients'),
    hiFives: new EntityRest<e.HiFive>(http, 'hi-fives'),
    infoGuides: new EntityRest<e.InfoGuide>(http, 'info-guides'),
    infoGuideSteps: new EntityRest<e.InfoGuideStep>(http, 'info-guide-steps'),
    infoViews: new EntityRest<e.InfoView>(http, 'info-views'),
    loginAttempts: new EntityRest<e.LoginAttempt>(http, 'login-attempts'),
    offeringCategories: new EntityRest<e.OffCategory>(http, 'offering-categories'),
    partnerUserGroups: new EntityRest<e.PartnerUserGroup>(http, 'partner-user-groups'),
    paymentPlans: new EntityRest<e.PaymentPlan>(http, 'payment-plans'),
    recipientGroups: new EntityRest<e.RecipientGroup>(http, 'recipient-groups'),
    recordings: new EntityRest<e.Recording>(http, 'recordings'),
    recordingViewers: new EntityRest<e.RecordingViewer>(http, 'recording-viewers'),
    rewards: new EntityRest<e.Reward>(http, 'rewards'),
    salesPartnerships: new EntityRest<e.SalesPartnership>(http, 'sales-partnerships'),
    servers: new EntityRest<e.Server>(http, 'servers'),
    sessions: new EntityRest<e.Session>(http, 'sessions'),
    sessionClients: new EntityRest<e.SessionClient>(http, 'session-clients'),
    sessionInstanceServers: new EntityRest<e.SessionInstanceServer>(http, 'session-instance-servers'),
    tacTypes: new EntityRest<e.TACType>(http, 'tac-types'),
    tppAllowedUsers: new EntityRest<e.TPPAllowedUser>(http, 'tpp-allowed-users'),
    tags: new EntityRest<e.Tag>(http, 'tags'),
    tagTypes: new EntityRest<e.TagType>(http, 'tag-types'),
    trainerAcceptClients: new EntityRest<e.TrainerAcceptClients>(http, 'trainer-accept-clients'),
    trainerPaymentPlans: new EntityRest<e.TrainerPaymentPlan>(http, 'trainer-payment-plans'),
    users: new EntityRest<e.User>(http, 'users'),
    userActions: new EntityRest<e.UserAction>(http, 'user-actions'),
    userMeasurements: new EntityRest<e.UserMeasurement>(http, 'user-measurements'),
    userParameters: new EntityRest<e.UserParameter>(http, 'user-parameters'),
    userRelations: new EntityRest<e.UserRelationship>(http, 'user-relations'),

    getAvailableRecordings: createGetMethod<c.RecordingWithTags[]>(http, 'get-available-recordings/'),
    getChatMessages: createGetMethod<c.ChatData[]>(http, 'get-chat-messages/'),
    getClientGoals: createGetMethod<c.ClientGoals>(http, 'get-client-goals/'),
    getClientSessions: createGetMethod<e.Session[]>(http, 'get-client-sessions/'),
    getEditableRecordings: createGetMethod<e.Recording[]>(http, 'get-editable-recordings/'),
    getGoalTypes: createGetMethod<e.GoalType[]>(http, 'get-goal-types/'),
    getInstructorClients: createGetMethod<e.User[]>(http, 'get-instructor-clients/'),
    getLeaderboardData: createGetMethod<c.GoalPublish[]>(http, 'get-leaderboard-data/'),
    getMyAssessments: createGetMethod<e.Assessment[]>(http, 'get-my-assessments/'),
    getMyFriends: createGetMethod<c.UserPublish[]>(http, 'get-my-friends/'),
    getNotificationCount: createGetMethod<number>(http, 'get-notification-count/'),
    getNotifications: createGetMethod<c.Notification[]>(http, 'get-notifications/'),
    getOfferingSignups: createGetMethod<(e.GymOfferingClient & { user: e.User })[]>(http, 'get-offering-signups/'),
    getOfferings: createGetMethod<c.GetOfferingResults>(http, 'get-offerings/'),
    getOtherSessionClients: createGetMethod<c.UserPublish[]>(http, 'get-other-session-clients/'),
    getPasswordInfo: createGetMethod<{ hasPassword: boolean; requirePassword: boolean }>(http, 'get-password-info/'),
    getPaymentMethods: createGetMethod<c.PaymentMethod[]>(http, 'get-payment-methods/'),
    getPlatinumUsage: createGetMethod<c.PlatinumUsage>(http, 'get-platinum-usage/'),
    getReactionShareUpload: createGetMethod<{ guid: string; url: string }>(http, 'get-reaction-share-upload/'),
    getRecordingUploadUrl: createGetMethod<string>(http, 'recordings/upload-url/'),
    getRecordingUploadUrlCf: createGetMethod<{ baseUrl: string; queryString: string }>(http, 'recordings/upload-url-cf/'),
    getSessionClients: createGetMethod<c.SessionClient2[]>(http, 'get-session-clients/'),
    getSessionsWithClients: createGetMethod<c.SessionWithClients[]>(http, 'get-sessions-with-clients/'),
    getTppSub: createGetMethod<string>(http, 'get-tpp-sub/'),
    getUnreadChatCount: createGetMethod<number>(http, 'get-unread-chat-count/'),
    getUserInfo: createGetMethod<c.UserWithGym>(http, 'get-user-info/'),
    signVideoPlayback: createGetMethod<{ baseUrl: string; queryString: string }>(http, 'sign-video-playback/'),

    addHiFive: createPostMethod<{ goalId: number }, number>(http, 'add-hi-five/'),
    assignRecordingPermission: createPostMethod<{ recordingId: number; groupId: number }, number>(http, 'assign-recording-permission/'),
    associateGym: createPostMethod<{ gymId: number }, 'ok'>(http, 'associate-gym/'),
    associatePaymentMethod: createPostMethod<{ id: string; detach?: boolean }, 'ok'>(http, 'associate-payment-method/'),
    cancelSubscription: createPostMethod<void, string>(http, 'cancel-subscription/'),
    cancelTrainerSubscription: createPostMethod<{ franchise: boolean }, string>(http, 'cancel-trainer-subscription/'),
    connectStripe: createPostMethod<{ code: string; franchise?: boolean }, string>(http, 'connect-stripe/'),
    connectToFriend: createPostMethod<c.ConnectToFriendInput, string>(http, 'connect-to-friend/'),
    convertReaction: createPostMethod<{ guid: string }, { jobId?: string }>(http, 'convert-reaction/'),
    createAdmin: createPostMethod<c.CreateTrainerInput, e.User | false>(http, 'create-admin/'),
    createChatRoom: createPostMethod<{ name: string; users: number[] }, c.ChatData>(http, 'create-chat-room/'),
    createClient: createPostMethod<c.CreateClientInput, e.User | false>(http, 'create-client/'),
    createPartnerKey: createPostMethod<{ publicKey?: string }, { keyPairId: string; privateKey?: string }>(http, 'create-partner-key/'),
    createPartnerUser: createPostMethod<c.CreatePartnerUserInput, { id: number }>(http, 'create-partner-user/'),
    createPartnerUserGroup: createPostMethod<{ name: string; publicKey?: string; guid?: string; partnerId?: number }, e.PartnerUserGroup>(http, 'partner-user-groups/create/'),
    createTilledAcct: createPostMethod<void, string>(http, 'create-tilled-acct/'),
    createTrainer: createPostMethod<c.CreateTrainerInput, e.User | false>(http, 'create-trainer/'),
    emailOptOut: createPostMethod<{ code: string }, boolean>(http, 'email-opt-out/'),
    finishConsumerPayment: createPostMethod<void, boolean>(http, 'finish-consumer-payment/'),
    finishNonInteractiveSignup: createPostMethod<{ code: string; password: string }, boolean>(http, 'finish-non-interactive-signup/'),
    finishProcessing: createPostMethod<{ guid: string }, 'ok'>(http, 'finish-processing/:guid/'),
    getAccountPrice: createPostMethod<{ userType: 'OWNER' | 'INSTRUCTOR' | 'CLIENT' }, number>(http, 'get-account-price/'),
    getAssessmentRecordingUrl: createPostMethod<{ guid: string }, c.AssessmentRecordingUrl>(http, 'get-assessment-recording-url/'),
    getAttendance: createPostMethod<{ userId?: number }, c.SessionAttendance>(http, 'get-attendance/'),
    getAutocompleteUsers: createPostMethod<c.AutocompleteInput, c.UserPublish[]>(http, 'get-autocomplete-users/'),
    getClassPaymentUrl: createPostMethod<{ optionId: number }, string>(http, 'get-class-payment-url/'),
    getConsumerPaymentUrl: createPostMethod<c.ConsumerPayment, string>(http, 'get-consumer-payment-url/'),
    getEmailStats: createPostMethod<{ userType?: e.UserType; consumerType?: e.ConsumerVersion }, c.EmailStat[]>(http, 'get-email-stats/'),
    getEmailSubscriptionOptions: createPostMethod<{ code: string }, c.SubOption[] | null>(http, 'get-email-subscription-options/'),
    getEmployerPaymentUrl: createPostMethod<c.EmployerPayment, string>(http, 'get-employer-payment-url/'),
    getInstructorClientsPost: createPostMethod<{ trainerId?: number; includeInactive?: boolean }, e.User[]>(http, 'get-instructor-clients/'),
    getMonthlyActiveUsers: createPostMethod<void, { month: string; cnt: number }[]>(http, 'get-monthly-active-users/'),
    getMonthlyUserDetails: createPostMethod<{ month: string }, c.MonthlyUserDetails>(http, 'get-monthly-user-details/'),
    getMonthlyVideoStats: createPostMethod<{ trainerId: number }, { month: string; views: number; watchTime: number }[]>(http, 'get-monthly-video-stats/'),
    getNonInteractiveSignupEmail: createPostMethod<{ code: string }, string>(http, 'get-non-interactive-signup-email/'),
    getPasswordStats: createPostMethod<void, c.PasswordStats>(http, 'get-password-stats/'),
    getPaymentUrl: createPostMethod<  { priceId: string; redirectUrl?: string; cancelUrl?: string; mode?: 'payment' | 'setup' | 'subscription'; quantity?: number },  string>(http, 'get-payment-url/'),
    getRecordingSuccessRate: createPostMethod<void, { week: number; succeeded: number; total: number }[]>(http, 'get-recording-success-rate/'),
    getSessionServers: createPostMethod<c.GetSessionServersInput, c.SessionServers>(http, 'get-session-servers/'),
    getSuggestedAutoMessages: createPostMethod<void, c.AutoMessageSuggestion[]>(http, 'get-suggested-auto-messages/'),
    getTrainerPaymentUrl: createPostMethod<c.TrainerPayment, string>(http, 'get-trainer-payment-url/'),
    getUserParameters: createPostMethod<{ users: number[]; paramName: string }, string[]>(http, 'get-user-parameters/'),
    getVideoStats: createPostMethod<{ month: string; trainerId: number }, c.VideoStat[]>(http, 'get-video-stats/'),
    getWhiteLabelGym: createPostMethod<{ gym: string }, e.Gym | null>(http, 'get-white-label-gym/'),
    getWhiteLabelInfo: createPostMethod<{ gym: string }, c.WhiteLabelInfo | null>(http, 'get-white-label-info/'),
    importClients: createPostMethod<c.ImportClientsInput, e.User[] | false>(http, 'import-clients/'),
    initiatePasswordlessLogin: createPostMethod<{ email: string }, { valid: boolean; requirePassword: boolean }>(http, 'initiate-passwordless-login/'),
    joinChatRoom: createPostMethod<{ roomId: number; userId: number }, string>(http, 'join-chat-room/'),
    joinSharedWorkout: createPostMethod<{ guid: string }, c.ChimeResults>(http, 'join-shared-workout/'),
    leaveChatRoom: createPostMethod<{ roomId: number }, string>(http, 'leave-chat-room/'),
    loginPartnerToken: createPostMethod<{ token: string }, c.LoginPartnerTokenResponse>(http, 'login-partner-token/'),
    loginPassword: createPostMethod<{ email: string; password: string }, { idToken: string; refreshToken: string } | any>(http, 'login-password/'),
    loginPasswordless: createPostMethod<{ code: string }, { idToken: string; refreshToken: string } | any>(http, 'login-passwordless/'),
    playbackWorkout: createPostMethod<{ workoutId: number }, c.WorkoutPlaybackDetails>(http, 'playback-workout/'),
    preload: createPostMethod<c.PreloadInput, c.PreloadData>(http, 'preload/'),
    processRecording: createPostMethod<{ id: number }, 'ok'>(http, 'recordings/process/'),
    readChat: createPostMethod<{ roomId: number; timestamp: number }, string>(http, 'read-chat/'),
    readNotification: createPostMethod<{ id: number }, boolean>(http, 'read-notification/'),
    recordFrontendError: createPostMethod<{ url: string; error: string }, string>(http, 'record-frontend-error/'),
    redeemRewardCoupon: createPostMethod<{ amount: number }, string>(http, 'redeem-reward-coupon/'),
    refreshToken: createPostMethod<{ idToken: string; refreshToken: string }, string | null>(http, 'refresh-token/'),
    removeGymUser: createPostMethod<{ userId: number }, { instructorError?: boolean }>(http, 'remove-gym-user/'),
    removePartnerUser: createPostMethod<{ id: number }, 'ok'>(http, 'remove-partner-user/'),
    renameChatRoom: createPostMethod<{ roomId: number; name: string }, string>(http, 'rename-chat-room/'),
    resubscribeAll: createPostMethod<{ code: string }, boolean>(http, 'resubscribe-all/'),
    revokeSession: createPostMethod<{ sessionId: number }, 'ok'>(http, 'revoke-session/'),
    sendAutoMessages: createPostMethod<{ messages: c.SendAutoMessage[] }, string>(http, 'send-auto-messages/'),
    sendChatMessage: createPostMethod<{ roomId: number; message: string }, e.ChatMessage>(http, 'send-chat-message/'),
    setSignupPageVideo: createPostMethod<{ id: number }, 'ok'>(http, 'set-signup-page-video/'),
    signout: createPostMethod<{ refreshToken: string }, 'ok'>(http, 'signout/'),
    signupConsumer: createPostMethod<c.SignupConsumerInput, c.SignupConsumerOutput>(http, 'signup-consumer/'),
    signupGeneralAccount: createPostMethod<c.SignupGeneralInput, string>(http, 'signup-general-account/'),
    signupOffering: createPostMethod<{ id: number; signup?: boolean }, { forward: string }>(http, 'signup-offering/'),
    signupOfferingAccount: createPostMethod<{ name: string; email: string; gymId: number }, string>(http, 'signup-offering-account/'),
    startSharedWorkout: createPostMethod<{ recordingId: number }, c.ChimeResults>(http, 'start-shared-workout/'),
    unsendChatMessage: createPostMethod<{ messageId: number }, string>(http, 'unsend-chat-message/'),
    unsubscribeAll: createPostMethod<{ code: string }, boolean>(http, 'unsubscribe-all/'),
    updateConsumerPaymentPlan: createPostMethod<c.ConsumerPayment, '' | 'ok'>(http, 'update-consumer-payment-plan/'),
    updateEmailSubscriptions: createPostMethod<{ code: string; subscriptions: c.SubOption[] }, boolean>(http, 'update-email-subscriptions/'),
    updatePassword: createPostMethod<{ currentPassword?: string | null; newPassword: string }, boolean>(http, 'update-password/'),
    updatePasswordRequired: createPostMethod<c.UpdatePasswordRequired, boolean>(http, 'update-password-required/'),
    updateUserParameter: createPostMethod<c.UpdateUserParameter, boolean>(http, 'update-user-parameter/'),
    uploadProfileImage: createPostMethod<{ image: string; userId?: number }, string>(http, 'upload-profile-image/'),
    uploadReactionToTwitter: createPostMethod<c.UploadReactionInput, { mediaId: string }>(http, 'upload-reaction-to-twitter/'),
    uploadThumbnail: createPostMethod<{ id: number; thumbnail: string }, 'ok'>(http, 'upload-thumbnail/'),
    validatePartnerToken: createPostMethod<{ token: string }, c.UserPublish | null>(http, 'validate-partner-token/'),
    verifyGymPayment: createPostMethod<void, boolean>(http, 'verify-gym-payment/'),
    verifyOfferingPayment: createPostMethod<void, { forward: string }>(http, 'verify-offering-payment/'),
  };
}
