import React, { useRef, useState } from 'react';
import './MuChatbot.scss';
import chatbotButton from './chatbot_button.png';
import { MuButton } from 'components/input';
import { MuInput } from 'components/input';
import { useUser } from 'services';
import { MuLogo } from 'components/page/MuLogo/MuLogo';

const CHATBOT_URL = 'https://coqp3ynkkd.execute-api.us-east-1.amazonaws.com/prod/query';

interface Message {
  query: boolean;
  message: string;
  pending: boolean;
}

const STANDARD_MESSAGES: Message[] = [
  {
    query: false,
    message: "Hi there! I'm the MotivateU chatbot and I'm here to answer any questions you may have about MotivateU's services!",
    pending: false
  },
  {
    query: false,
    message:
      'You can ask me how to make new classes, how to view your schedule, how to add and remove clients, as well as various other questions. How can I help you today?',
    pending: false
  }
];

const LoadingEllipses: React.FC = () => {
  return (
    <div className='loading-ellipses'>
      <div className='loading-ellipsis-1' />
      <div className='loading-ellipsis-2' />
      <div className='loading-ellipsis-3' />
    </div>
  );
};

export const MuChatbot: React.FC = () => {
  const [query, setQuery] = useState('');
  const messages = useRef<Message[]>(STANDARD_MESSAGES);
  const setLoading = useState(false)[1];
  const messagesRef = useRef<HTMLDivElement>(null);

  async function send() {
    setQuery('');
    setLoading(true);
    const i = messages.current.length + 1;
    messages.current.push(
      { pending: false, query: true, message: query },
      {
        pending: true,
        query: false,
        message: ''
      }
    );
    setTimeout(() => messagesRef.current?.scrollTo(0, 10000000000000), 1);
    const resp = await fetch(CHATBOT_URL, { method: 'POST', body: JSON.stringify({ query }) });
    messages.current[i].message = await resp.json();
    messages.current[i].pending = false;
    setLoading(false); // make sure we trigger a rerender
    setTimeout(() => messagesRef.current?.scrollTo(0, 10000000000000), 1);
  }

  return (
    <div id='chatbox'>
      <div className='chatbox-header'>
        <div className='flex-space' />
        <MuLogo />
        <span>Chat With MotivateU</span>
        <div className='flex-space' />
      </div>
      <div className='chatbox-messages' ref={messagesRef}>
        {messages.current.map((msg, i) => (
          <div key={i} className={'message ' + (msg.query ? 'query' : 'response')}>
            {msg.pending ? <LoadingEllipses /> : msg.message}
          </div>
        ))}
      </div>
      <div className='chatbox-footer'>
        <MuInput
          value={query}
          onChange={setQuery}
          placeholder='Type a message . . .'
          onKeyDown={(event) => {
            console.log(event.code);
            event.code === 'Enter' ? send() : null;
          }}
        />
        <MuButton onClick={send} narrow className='outline'>
          Send
        </MuButton>
      </div>
    </div>
  );
};
