const MAX_THREADS = 3;

export class MultipartUpload {
  uploadId?: string;
  etags: { [n: number]: string } = {};
  maxN = 0;
  consumedThreads = 0;
  threadCallback?: () => void;

  constructor(private baseUrl: string, private queryString: string, private path: string, private progress?: (delta: number) => void) {}

  async init() {
    const initResp = await fetch(`${this.baseUrl}/${this.path}?uploads&${this.queryString}`, {
      method: 'POST',
      headers: { 'x-amz-acl': ' bucket-owner-full-control' }
    });
    const uploadRE = /<UploadId>(.*)<\/UploadId>/;
    this.uploadId = uploadRE.exec(await initResp.text())![1];
  }

  async queueReady(): Promise<void> {
    if (this.consumedThreads < MAX_THREADS) return;
    return new Promise<void>((res) => (this.threadCallback = res));
  }

  async drainQueue() {
    while (this.consumedThreads) await new Promise<void>((res) => (this.threadCallback = res));
  }

  async uploadPart(n: number, content: Blob) {
    this.consumedThreads++;
    this.maxN = Math.max(this.maxN, n + 1);
    const url = `${this.baseUrl}/${this.path}?partNumber=${n + 1}&uploadId=${this.uploadId}&${this.queryString}`;
    const resp = await fetch(url, { method: 'PUT', body: content });
    if (this.progress) this.progress(content.size);
    this.etags[n + 1] = resp.headers.get('ETag')!;
    this.consumedThreads--;
    if (this.threadCallback) this.threadCallback();
  }

  async finalize() {
    const partResults: string[] = [];
    for (let i = 1; i <= this.maxN; i++) {
      partResults.push(`<Part>
    <ETag>${JSON.parse(this.etags[i])}</ETag>
    <PartNumber>${i}</PartNumber>
  </Part>`);
    }

    const body = `<CompleteMultipartUpload xmlns='http://s3.amazonaws.com/doc/2006-03-01/'>
  ${partResults.join('\n')}
</CompleteMultipartUpload>`;
    await fetch(`${this.baseUrl}/${this.path}?uploadId=${this.uploadId}&${this.queryString}`, { method: 'POST', body });
  }
}

const N = 5242880;

interface MultipartUploadProps {
  baseUrl: string;
  queryString: string;
  content: Blob;
  path: string;

  progress(delta: number): void;
}

export async function multipartUpload({ baseUrl, queryString, path, content, progress }: MultipartUploadProps) {
  const upload = new MultipartUpload(baseUrl, queryString, path, progress);
  await upload.init();
  for (let i = 0; i < Math.min(content.size, MAX_THREADS * N); i += N) upload.uploadPart(i / N, content.slice(i, i + N));
  for (let i = MAX_THREADS * N; i < content.size; i += N) {
    await upload.queueReady();
    upload.uploadPart(i / N, content.slice(i, i + N));
  }
  await upload.drainQueue();
  await upload.finalize();
}
