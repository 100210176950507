interface Thresholds {
  easy: number;
  medium: number;
  hard: number;
}

const GOOD_THRESHOLDS: Thresholds = {
  easy: 0.55,
  medium: 0.7,
  hard: 0.85,
};

const OK_THRESHOLDS: Thresholds = {
  easy: 0.3,
  medium: 0.5,
  hard: 0.5,
};

export function rating(score: number | undefined, system: keyof Thresholds) {
  if (score === 0.5) return 'none';
  if (!score) return 'poor';
  if (score >= GOOD_THRESHOLDS[system]) return 'good';
  else if (score >= OK_THRESHOLDS[system]) return 'ok';
  return 'poor';
}
