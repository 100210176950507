import { useState } from 'react';
import { useEffectAsync } from './misc';

type APIWithoutInput<T> = {
  api: () => Promise<T>;
  dependencies?: any[];
  conditions?: boolean[];
  input?: undefined;
};

type APIWithInput<T, K> = {
  api: (input: K) => Promise<T>;
  dependencies?: any[];
  conditions?: boolean[];
  input: K;
};

export function useAPI<T, K>({ api, dependencies, conditions, input }: APIWithInput<T, K> | APIWithoutInput<T>): [T | undefined, boolean] {
  const [result, setResult] = useState<T>();
  const [loading, setLoading] = useState(false);
  useEffectAsync(async () => {
    for (const c of conditions || []) if (!c) return;
    setLoading(true);
    setResult(await api(input!));
    setLoading(false);
  }, dependencies || []);
  return [result, loading];
}
