import React, { HTMLProps } from 'react';
import './MuCard.scss';

interface MuCardProps extends HTMLProps<HTMLDivElement> {
  title?: string;
}

export const MuCard: React.FC<MuCardProps> = ({ title, children, className, ...props }) => {
  return (
    <div className={'mu-card ' + (className || '')} {...props}>
      {title ? <div className='card-title'>{title}</div> : null}
      {children}
    </div>
  );
};
