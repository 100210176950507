import React, { ReactNode, useEffect, useRef, useState } from 'react';
import './MuUserAutocomplete.scss';
import { MuLoading, UserDisplay } from 'components/input';
import { MotivateU, useEffectAsync } from 'services';
import { MuInput, MuInputPropsString } from '../MuInput/MuInput';
import type { UserPublish } from 'common';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

interface MuUserAutocompleteProps extends Omit<MuInputPropsString, 'value' | 'onChange' | 'onSelect'> {
  defaultSearchText?: string;
  value?: number;
  user?: UserPublish;
  minLength?: number;
  onSearch?: (searchText: string) => Promise<UserPublish[]>;
  users?: UserPublish[];
  renderUser?: (user: UserPublish) => ReactNode;
  onSelect?: (user: UserPublish | undefined) => void;
  onChange?: (userId: number) => void;
  clearable?: boolean;
}

export const MuUserAutocomplete: React.FC<MuUserAutocompleteProps> = ({
  defaultSearchText = '',
  value,
  user,
  minLength = 3,
  onSearch,
  users,
  renderUser,
  onSelect,
  onChange,
  clearable,
  ...inputProps
}) => {
  const defaultName = defaultSearchText || user?.name || users?.filter((u) => u.id === value)[0]?.name;
  const [searchText, setSearchText] = useState(defaultName || '');
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<UserPublish[]>([]);
  const [allUsers, setAllUsers] = useState<UserPublish[]>([]);
  const [changed, setChanged] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const open = searchText.length >= minLength && changed;
  const wrapper = useRef<HTMLDivElement>(null);
  const resultsDiv = useRef<HTMLDivElement>(null);

  useEffectAsync(async () => {
    if (searchText.length >= minLength) {
      setLoading(true);
      if (users) {
        setResults(users.filter((user) => user.name.toLowerCase().includes(searchText?.toLowerCase())));
      } else if (onSearch) {
        setResults(await onSearch(searchText));
      } else {
        if (!allUsers.length) {
          setAllUsers(await MotivateU.api.users.filter({ limit: -1 }));
        }
        setResults(allUsers.filter((user) => user.name.toLowerCase().includes(searchText?.toLowerCase())));
      }
      setLoading(false);
    }
  }, [searchText, allUsers, users]);

  useEffect(() => {
    if (value === 0) {
      setSearchText('');
    } else if (value && users) {
      setChanged(false);
      setSearchText(users?.filter((u) => u.id === value)[0]?.name || '');
    }
  }, [value, users]);

  const select = (user?: UserPublish) => () => {
    if (onSelect) onSelect(user);
    if (onChange) onChange(user?.id || 0);
    setChanged(false);
    setSearchText(user ? defaultName || '' : '');
  };
  const icon = clearable && value ? faTimes : undefined;
  const width = open ? wrapper.current?.getBoundingClientRect().width : undefined;

  const onChangeText = (text: string) => {
    setSearchText(text);
    setChanged(true);
  };

  function hover(idx: number) {
    setHoveredIndex(idx);
    if (resultsDiv.current && idx >= 0) {
      const top = resultsDiv.current.getBoundingClientRect().y;
      const hoverTop = resultsDiv.current.children[idx].getBoundingClientRect().y;
      resultsDiv.current.parentElement?.scrollTo(0, hoverTop - top);
    }
  }
  inputProps.onFocus = () => (minLength === 0 ? setChanged(true) : undefined);
  inputProps.onKeyDown = (event) => {
    if (event.key === 'ArrowUp') hover(Math.max(-1, hoveredIndex - 1));
    else if (event.key === 'ArrowDown') hover(Math.min(results.length - 1, hoveredIndex + 1));
    else if (event.key === 'Enter' && hoveredIndex >= 0 && hoveredIndex < results.length) select(results[hoveredIndex])();
  };

  return (
    <div className='mu-user-autocomplete-wrapper' ref={wrapper} style={inputProps.noMargin ? { margin: 0 } : {}}>
      <div className={'mu-user-autocomplete' + (open ? ' open' : '')} style={{ width }}>
        <MuInput value={searchText} onChange={onChangeText} iconAfter={icon} onIconAfterClick={select()} {...inputProps} noMargin />
        {open ? (
          <div className='results'>
            {loading ? (
              <MuLoading />
            ) : results.length === 0 ? (
              <div className='no-results'>No results found</div>
            ) : (
              <div className='search-results' ref={resultsDiv}>
                {results.map((user, idx) => (
                  <div
                    className={'search-result' + (hoveredIndex === idx ? ' hovered' : '')}
                    key={idx}
                    onClick={select(user)}
                    onMouseOver={() => setHoveredIndex(idx)}
                  >
                    {renderUser ? renderUser(user) : <UserDisplay user={user} />}
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};
