import React from 'react';
import './MuLoading.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export const MuLoading: React.FC<{ text?: string }> = ({ text }) => {
  return (
    <div className='mu-loading'>
      <FontAwesomeIcon icon={faSpinner} spin /> &nbsp; {text || 'Loading'}
    </div>
  );
};
