import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import './menu.scss';
import {
  faChartLine,
  faAward,
  faBlog,
  faBullseye,
  faCalendar,
  faCalendarAlt,
  faClipboardList,
  faClock,
  faCog,
  faComment,
  faDumbbell,
  faEye,
  faFileSignature,
  faHandshake,
  faList,
  faListOl,
  faMinus,
  faPlus,
  faStore,
  faTrophy,
  faUser,
  faUserFriends,
  faUserPlus,
  faUsers,
  faVideo,
  IconPrefix
} from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { Link } from 'react-router-dom';
import { MuButton } from '../../input';
import {
  ConsumerAppVersion,
  getPreload,
  getToken,
  isActive,
  MotivateU,
  String,
  useConsumerAppVersion,
  useEffectAsync,
  useString,
  useUser
} from 'services';
import type { GymLink, Session } from 'common';
import { IconName } from '@fortawesome/free-regular-svg-icons';
import '../../../pages/admin/ManageMenu/icon-types';

type UserType = 'ADMIN' | 'PARTNER' | 'FRANCHISE' | 'OWNER' | 'EMPLOYER' | 'INSTRUCTOR' | 'CLIENT' | 'CONSUMER';

interface MenuItem {
  icon: IconDefinition | [IconPrefix, IconName];
  title: string;
  url: string;
  external?: boolean;
  userTypes?: UserType[];
  consumerType?: ConsumerAppVersion;
  onClick?: () => void;
  subMenus?: MenuItem[];
  whiteLabel?: boolean;
  hasClasses?: boolean;
}

async function openCalendar() {
  const idToken = (await getToken()) || '';
  const form = document.createElement('form');
  form.method = 'POST';
  form.action = 'https://calendar.motivateu.live/mu-auth';
  const input = document.createElement('input');
  input.name = 'token';
  input.value = idToken;
  input.type = 'hidden';
  form.appendChild(input);
  document.body.appendChild(form);
  form.submit();
}

const all: UserType[] = ['ADMIN', 'PARTNER', 'FRANCHISE', 'OWNER', 'EMPLOYER', 'INSTRUCTOR', 'CLIENT', 'CONSUMER'];

const STORE_URL = 'https://www.motivateu.net/store/c2/All_Products.html#/';
const BLOG_URL = 'https://www.motivateu.net/blog';

function useMenuItems(): MenuItem[] {
  const user = useUser();
  const consumerType = useConsumerAppVersion();

  const menuItems: MenuItem[] = [
    { icon: faList, title: useString('plans'), whiteLabel: true, url: '/gym-plans', userTypes: ['CONSUMER'] },
    {
      icon: faDumbbell,
      title: user?.gym?.name || 'Gym',
      whiteLabel: true,
      url: '#',
      userTypes: ['CLIENT'],
      subMenus: [
        { icon: faVideo, title: useString('on_demand_videos'), url: '/recording-list', userTypes: ['CLIENT', 'CONSUMER'] },
        { icon: faFileSignature, title: useString('assessments'), url: '/assessments', consumerType: ConsumerAppVersion.FREE },
        { icon: faClipboardList, title: useString('classes'), url: '/client/sessions', hasClasses: true },
        ...(user?.gym?.links.map((l: GymLink) => ({
          icon: [l.icon.substring(0, 3), l.icon.substring(4)] as [IconPrefix, IconName],
          title: l.name,
          url: l.url,
          external: true
        })) || [])
      ]
    },
    {
      icon: faHandshake,
      title: useString('clients'),
      url: '#',
      userTypes: ['INSTRUCTOR'],
      subMenus: [
        { icon: faHandshake, title: useString('manage'), url: '/instructor/manage-users', userTypes: ['INSTRUCTOR'] },
        { icon: faClock, title: useString('workout_results'), url: '/class-stats/0', userTypes: ['INSTRUCTOR'] },
        { icon: faBullseye, title: useString('progress'), url: '/client-goals/0', userTypes: ['INSTRUCTOR'] },
        {
          icon: faFileSignature,
          title: useString('assessments'),
          url: '/instructor/manage-assessments',
          userTypes: ['INSTRUCTOR']
        },
        { icon: faUserPlus, title: useString('accept_clients'), url: '/instructor/accept-clients', userTypes: ['INSTRUCTOR'] }
      ]
    },
    {
      icon: faHandshake,
      title: useString('employees'),
      url: '#',
      userTypes: ['EMPLOYER'],
      subMenus: [
        { icon: faHandshake, title: useString('manage'), url: '/employer/manage-users', userTypes: ['EMPLOYER'] },
        { icon: faClock, title: useString('workout_results'), url: '/class-stats/0', userTypes: ['EMPLOYER'] },
        { icon: faBullseye, title: useString('progress'), url: '/client-goals/0', userTypes: ['EMPLOYER'] },
        { icon: faFileSignature, title: useString('assessments'), url: '/instructor/manage-assessments', userTypes: ['EMPLOYER'] }
      ]
    },
    { icon: faHandshake, title: useString('manage_gyms'), url: '/manage-gyms', userTypes: ['ADMIN', 'FRANCHISE'] },
    {
      icon: faHandshake,
      title: useString('trainers_clients'),
      url: '#',
      userTypes: ['ADMIN', 'OWNER'],
      subMenus: [
        { icon: faHandshake, title: useString('manage'), url: '/manage-trainers', userTypes: ['ADMIN', 'OWNER'] },
        { icon: faClock, title: useString('workout_results'), url: '/class-stats/0', userTypes: ['ADMIN', 'OWNER'] },
        { icon: faBullseye, title: useString('progress'), url: '/client-goals/0', userTypes: ['ADMIN', 'OWNER'] },
        {
          icon: faFileSignature,
          title: useString('assessments'),
          url: '/instructor/manage-assessments',
          userTypes: ['ADMIN', 'OWNER']
        },
        {
          icon: faUserPlus,
          title: useString('accept_clients'),
          url: '/instructor/accept-clients',
          userTypes: ['ADMIN', 'OWNER']
        },
        {
          icon: faComment,
          title: 'Auto Messages',
          url: '/send-auto-messages',
          userTypes: ['ADMIN']
        }
      ]
    },
    {
      icon: faClipboardList,
      title: useString('classes'),
      url: '#',
      userTypes: ['ADMIN', 'FRANCHISE', 'OWNER', 'INSTRUCTOR'],
      subMenus: [
        {
          icon: faClipboardList,
          title: useString('classes'),
          url: '/instructor/manage-sessions',
          userTypes: ['ADMIN', 'FRANCHISE', 'OWNER', 'INSTRUCTOR']
        },
        // TODO: packages
        {
          icon: faCalendarAlt,
          title: useString('schedule'),
          url: '/schedule',
          userTypes: ['ADMIN', 'FRANCHISE', 'OWNER', 'INSTRUCTOR']
        },
        {
          icon: faCalendar,
          title: useString('calendar'),
          url: '#',
          onClick: openCalendar,
          userTypes: ['ADMIN', 'FRANCHISE', 'OWNER', 'INSTRUCTOR']
        }
      ]
    },
    {
      icon: faVideo,
      title: useString('on_demand'),
      url: '#',
      userTypes: ['ADMIN', 'INSTRUCTOR', 'PARTNER', 'EMPLOYER', 'OWNER', 'FRANCHISE'],
      subMenus: [
        { icon: faCog, title: useString('manage'), url: '/video-library', consumerType: ConsumerAppVersion.FREE },
        { icon: faEye, title: useString('view'), url: '/recording-list', consumerType: ConsumerAppVersion.FREE }
      ]
    },
    { icon: faVideo, title: useString('on_demand_videos'), url: '/recording-list', userTypes: ['CLIENT', 'CONSUMER'], whiteLabel: false },
    { icon: faTrophy, title: useString('challenges'), url: '/challenges', whiteLabel: false },
    {
      icon: faFileSignature,
      title: useString('assessments'),
      url: '/assessments',
      consumerType: ConsumerAppVersion.FREE,
      whiteLabel: false
    },
    { icon: faBullseye, title: useString('my_goals'), url: '/goals', consumerType: ConsumerAppVersion.FREE },
    {
      icon: faUserFriends,
      title: useString('community'),
      url: '#',
      subMenus: [
        { icon: faBlog, title: useString('blog'), url: BLOG_URL, external: true, whiteLabel: false },
        { icon: faComment, title: useString('chat'), url: '/chat' },
        { icon: faUser, title: useString('connections'), url: '/profile' },
        { icon: faListOl, title: useString('leaderboard'), url: '/leaderboard', consumerType: ConsumerAppVersion.FREE },
        { icon: faAward, title: useString('rewards'), url: '/rewards', consumerType: ConsumerAppVersion.FREE, whiteLabel: false },
        { icon: faStore, title: useString('swag_store'), url: STORE_URL, external: true, whiteLabel: false }
      ]
    },
    //
    // { icon: faLightbulb, title: useString('insights'), url: '/insights', userTypes: ['ADMIN', 'FRANCHISE', 'OWNER', 'INSTRUCTOR'] },
    // { icon: faDollarSign, title: useString('billing_setup'), url: '/manage-payments', userTypes: ['ADMIN', 'FRANCHISE', 'OWNER', 'INSTRUCTOR'] },
    {
      icon: faClipboardList,
      title: useString('classes'),
      url: '/client/sessions',
      userTypes: ['CLIENT', 'CONSUMER'],
      hasClasses: true,
      whiteLabel: false
    },
    {
      icon: faHandshake,
      title: useString('connect_to_trainers'),
      url: '/connect-to-trainer',
      userTypes: ['CLIENT', 'CONSUMER'],
      whiteLabel: false
    },
    { icon: faClock, title: useString('workout_results'), url: '/class-stats', userTypes: ['CLIENT', 'CONSUMER'] },
    { icon: faCog, title: useString('administration'), url: '/admin', userTypes: ['ADMIN'] },
    { icon: faChartLine, title: useString('analytics'), url: '/analytics', userTypes: ['ADMIN', 'INSTRUCTOR', 'OWNER', 'FRANCHISE'] },
    { icon: faUsers, title: useString('customer_service'), url: '/customer-service', userTypes: ['ADMIN'] }
  ];

  if (!user && localStorage.menuCache) return JSON.parse(localStorage.menuCache);

  function filterItems(menuItem: MenuItem): boolean {
    if (menuItem.subMenus) menuItem.subMenus = menuItem.subMenus.filter(filterItems);
    if (menuItem.userTypes) if (!menuItem.userTypes.includes(user?.userType || 'CLIENT')) return false;
    if (menuItem.consumerType) if (menuItem.consumerType > consumerType) return false;
    if (menuItem.whiteLabel === true && !user?.gym?.whiteLabel) return false;
    if (menuItem.whiteLabel === false && user?.gym?.whiteLabel) return false;
    if (menuItem.hasClasses === true && !user?.gym?.hasLiveClass) return false;
    if (menuItem.hasClasses === false && user?.gym?.hasLiveClass) return false;
    return true;
  }

  const menu = menuItems.filter(filterItems);
  localStorage.menuCache = JSON.stringify(menu);
  return menu;
}

const MenuItemDisplay: React.FC<{
  menuItem: MenuItem;
  selectedPage: string;
  setSelectedPage(page: string): void;
  setMenu(val: boolean): void;
}> = ({ menuItem, selectedPage, setSelectedPage, setMenu }) => {
  const selected = selectedPage === menuItem.url || (menuItem.subMenus || []).filter((m) => m.url === selectedPage).length > 0;
  const [expanded, setExpanded] = useState(selected);

  function MenuLink({ menuItem, selected }: { menuItem: MenuItem; selected: boolean }) {
    return menuItem.external ? (
      <a href={menuItem.url} key={menuItem.url} target='_blank'>
        <div className={'mu-menu-item' + (selected ? ' selected' : '')}>
          <FontAwesomeIcon icon={menuItem.icon} /> <span>{menuItem.title}</span>
        </div>
      </a>
    ) : (
      <Link
        to={menuItem.url}
        key={menuItem.url}
        onClick={() => {
          if (menuItem.url !== '#') {
            setSelectedPage(menuItem.url);
            setMenu(false);
          }
          setExpanded(selected || !expanded);
          if (menuItem.onClick) menuItem.onClick();
        }}
      >
        <div className={'mu-menu-item' + (selected ? ' selected' : '')}>
          <FontAwesomeIcon icon={menuItem.icon} /> <span>{menuItem.title}</span>
          {menuItem.subMenus ? <FontAwesomeIcon className='expansion' icon={expanded ? faMinus : faPlus} /> : null}
        </div>
      </Link>
    );
  }

  return (
    <div>
      <MenuLink menuItem={menuItem} selected={selected} />
      {(expanded || selected) && menuItem.subMenus ? (
        <div className='submenu'>
          {menuItem.subMenus.map((menu) => (
            <MenuLink menuItem={menu} selected={menu.url === selectedPage} key={menu.url} />
          ))}
        </div>
      ) : null}
    </div>
  );
};

interface MUMenuProps {
  menu: boolean;

  setMenu(val: boolean): void;
}

export const MUMenu: React.FC<MUMenuProps> = ({ menu, setMenu }) => {
  const user = useUser();
  const [nextClass, setNextClass] = useState<Session>();
  const [selectedPage, setSelectedPage] = useState(window.location.pathname);
  const sessions = useRef<Session[]>([]);
  const menuItems = useMenuItems();

  useEffectAsync(async () => {
    const preload = await getPreload();
    setNextClass(preload.launchClass || undefined);
  }, []);

  async function getNextClass() {
    if (user && !sessions.current) {
      sessions.current = [
        ...(await MotivateU.api.sessions.filter({ filters: { instructorId: user.id } })),
        ...(await MotivateU.api.getClientSessions())
      ];
    }
    const sessions2 = sessions.current.filter(isActive);
    if (sessions2.length === 1) setNextClass(sessions2[0]);
    else setNextClass(undefined);
  }

  useEffect(() => {
    setInterval(getNextClass, 60_000);
  }, []);

  return (
    <div id='mu-menu' className={menu ? 'expanded' : 'retracted'}>
      <div id='mu-menu-items-wrapper'>
        {user?.gym?.hasLiveClass !== false ? (
          <div id='launch-class-wrapper'>
            <Link
              to={
                nextClass
                  ? nextClass.instructorId === user?.id
                    ? `/instructor/video-session/${nextClass.id}`
                    : `/client/join-session/${nextClass.id}`
                  : '#'
              }
            >
              <MuButton
                disabled={!nextClass}
                title={!nextClass && user?.userType === 'CONSUMER' ? 'You need to connect with a trainer to join a class' : ''}
              >
                <String name='launch_class' />
              </MuButton>
            </Link>
          </div>
        ) : null}
        {menuItems.map((menuItem) => (
          <MenuItemDisplay
            menuItem={menuItem}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            setMenu={setMenu}
            key={menuItem.title}
          />
        ))}
      </div>
      <div id='final-links'>
        <a href='https://www.motivateu.net/help.html' target='_blank'>
          <String name='help' />
        </a>
        {user?.gym?.whiteLabel ? null : (
          <Link to='/payments'>
            <String name='billing' />
          </Link>
        )}
        {/*{['INSTRUCTOR', 'OWNER', 'ADMIN'].includes(user?.userType || '') ? (*/}
        {/*  <>*/}
        {/*    <a href='/manage-payments'><String name="client_billing"/></a>*/}
        {/*  </>*/}
        {/*) : null}*/}
        <a
          href='mailto:devteam@motivateu.net?subject=Bug Report&body=What behavior did you see?%0a%0a
          %0aWhat behavior did you expect?%0a%0a
          %0aWhat page did this happen on (a URL would be helpful)?%0a%0a
          %0aPlease give any more detail that you think would be helpful and feel free to attach screenshots'
          target='_blank'
        >
          <String name='report_bug' />
        </a>
        <a href='/assets/release-notes.html'>
          <span>Version 2022.40.0</span>
        </a>
      </div>
    </div>
  );
};
