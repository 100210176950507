import React, { useState } from 'react';
import '../../onboard/signup.scss';
import { MuButton, MuCard, MuInput, MuTabs } from 'components/input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { initiatePasswordlessLogin, loginPassword } from 'services';
import { MuLogo } from 'components/page/MuLogo/MuLogo';

export const LoginPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [usePassword, setUsePassword] = useState(false);
  const [requirePassword, setRequirePassword] = useState(false);
  const [showRequired, setShowRequired] = useState(false);
  const [error, setError] = useState('');
  const [complete, setComplete] = useState(false);
  const [loading, setLoading] = useState(false);

  async function initiateLogin() {
    setShowRequired(true);
    if (!email) return;

    setLoading(true);
    try {
      const result = await initiatePasswordlessLogin(email);
      setError('');
      if (result.error) setError('Something went wrong. Please try again.');
      else if (!result.valid) setError('Invalid email address or too many login attempts made recently');
      else if (result.requirePassword) {
        setError('This account requires a password');
        setRequirePassword(true);
      } else setComplete(true);
    } catch (e) {
      setError('Something went wrong. Please try again.');
    }
    setLoading(false);
  }

  async function login() {
    setShowRequired(true);
    if (!email || !password) return;

    setLoading(true);
    try {
      const result = await loginPassword(email, password);
      setError(result ? '' : 'Invalid username or password');
      if (result) window.location.reload();
    } catch (e) {
      setError((e as Error).message);
    }
    setLoading(false);
  }

  return (
    <div id='signup'>
      <div id='color-gradient' />
      <div id='content'>
        <div className='flex-space' />
        <div className='logo-wrapper'>
          <a href='https://motivateu.net' target='_blank'>
            <MuLogo />
          </a>
        </div>
        <div className='error'>{error}</div>
        <MuTabs
          tabs={[
            {
              id: 'login',
              title: 'Login',
              content: () => (
                <div className='login-info'>
                  {complete ? (
                    <div>A link has been sent to your email. Please click the link to log in.</div>
                  ) : (
                    <>
                      <MuInput value={email} onChange={setEmail} label='Email' required={showRequired} />
                      {usePassword || requirePassword ? (
                        <>
                          <MuInput type='password' value={password} onChange={setPassword} label='Password' required={showRequired} />
                          <br />
                          <MuButton onClick={login} className='full-width' loading={loading} loadingText='Logging In . . .'>
                            Login
                          </MuButton>
                          {requirePassword ? null : (
                            <div className='mu-text-sm-light'>
                              <span className='link' onClick={() => setUsePassword(false)}>
                                Sign in without a password
                              </span>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          <br />
                          <MuButton onClick={initiateLogin} className='full-width' loading={loading} loadingText='Sending . . .'>
                            Send Link
                          </MuButton>
                          <div className='mu-text-sm-light'>
                            We'll send you a magic link for a password-free sign in. Or you can{' '}
                            <span className='link' onClick={() => setUsePassword(true)}>
                              sign in with a password instead.
                            </span>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              ),
            },
            {
              id: 'signup',
              title: 'Sign up',
              content: () => (
                <div className='signup-options align-left'>
                  <MuCard className='flex alight-items-center'>
                    <div className='content flex-grow'>
                      <div className='mu-text-md-bold'>Consumer</div>
                      <div className='mu-text-sm-light'>
                        <a href='https://www.motivateu.net/individuals.html'>Learn More</a>
                      </div>
                    </div>
                    <Link to='/consumer-signup' style={{ textDecoration: 'none', color: 'initial' }}>
                      <FontAwesomeIcon icon={faAngleRight} style={{ fontSize: 24 }} />
                    </Link>
                  </MuCard>
                  <MuCard className='flex alight-items-center'>
                    <div className='content flex-grow'>
                      <div className='mu-text-md-bold'>Fitness Business</div>
                      <div className='mu-text-sm-light'>
                        <a href='https://www.motivateu.net'>Learn More</a>
                      </div>
                    </div>
                    <Link to='/trainer-signup' style={{ textDecoration: 'none', color: 'initial' }}>
                      <FontAwesomeIcon icon={faAngleRight} style={{ fontSize: 24 }} />
                    </Link>
                  </MuCard>
                </div>
              ),
            },
          ]}
        />
        <div className='flex-space' />
      </div>
    </div>
  );
};
