import { DAY, MINUTE, two } from '../util';

const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

/**
 * Check that a schedule string is valid
 * @param schedule
 */
export function validate(schedule: string): boolean {
  // Step 1: Check that there are 3 parts of the schedule
  const scheduleParts = schedule.toLowerCase().split(' ');
  if (scheduleParts.length !== 3) {
    return false;
  }
  const [dayMatch, time, timeZone] = scheduleParts;

  // Step 2: check that the time is valid
  const timeParts = time.split(':');
  if (timeParts.length !== 2) {
    return false;
  }
  const hour = parseInt(timeParts[0]);
  const minute = parseInt(timeParts[1]);
  if (isNaN(hour) || hour < 0 || hour >= 24) {
    return false;
  }
  if (isNaN(minute) || minute < 0 || minute >= 60) {
    return false;
  }

  // Step 3: check that the dayMatch is valid
  const dayMatchParts = dayMatch.toLowerCase().replace(')', '').split('(');
  if (dayMatchParts.length !== 2) {
    return false;
  }
  const [rate, params] = dayMatchParts;
  switch (rate) {
    case 'daily':
      if (params.length !== 0) {
        return false;
      }
      break;
    case 'weekly':
      if (params.length < 1) {
        return false;
      }
      for (const d of params.split(',')) {
        if (days.indexOf(d) === -1) {
          return false;
        }
      }
      break;
    case 'monthly':
      if (params.length < 1) {
        return false;
      }
      for (const d of params.split(',')) {
        const di = parseInt(d);
        if (!isNaN(di) && di > 0 && di <= 31) {
          continue;
        } else if (d.length === 4 && days.indexOf(d.substr(0, 3)) !== -1 && '12345'.indexOf(d.substr(3, 1)) !== -1) {
          continue;
        }
        return false;
      }
      break;
    default:
      return false;
  }

  // Step 4: check that timezone is valid
  try {
    Intl.DateTimeFormat(undefined, { timeZone });
    return true;
  } catch {
    return false;
  }
}

function getTzTimestamp(year: number, month: number, day: number, hour: number, minute: number, timezone: string): Date {
  const target = `${year}-${two(month + 1)}-${two(day)} ${two(hour)}:${two(minute)}`;
  const ts = Date.UTC(year, month, day, hour, minute);
  let lo = ts - 2 * DAY;
  let hi = ts + 2 * DAY;
  while (hi - lo > 1) {
    const mid = (hi + lo) / 2;
    const fmt = new Date(mid)
      .toLocaleString('en-US', {
        timeZone: timezone,
        day: '2-digit',
        year: 'numeric',
        month: '2-digit',
        hour12: false,
        hour: '2-digit',
        minute: '2-digit'
      })
      .replace(/(\d+)\/(\d+)\/(\d+), (\d+):(\d+)/, '$3-$1-$2 $4:$5');
    if (fmt < target) lo = mid;
    else hi = mid;
  }
  return new Date(Math.round(lo / MINUTE) * MINUTE);
}

function getDayDetails(year: number, month: number, day: number, hour: number, minute: number, timezone: string) {
  let date: Date;
  try {
    date = getTzTimestamp(year, month, day, hour, minute, timezone);
  } catch (e) {
    return null;
  }

  return {
    timestamp: date.getTime(),
    dow: days[date.getDay()], // i.e. `Sat` for Saturday
    dom: date.getDate(), // i.e. `30` for the 30th of the month
    dowm: days[date.getDay()] + Math.floor((date.getDate() + 6) / 7) // i.e. `Sat3` for 3rd Saturday of the month
  };
}

/**
 * Find the timestamp of the next occurrence of this schedule.
 * @param schedule: string representing the schedule (i.e. `weekly(Mon,Wed,Fri) 15:30 America/New_York`)
 * @param offset: Offset the current date by this much. For example, if set to -3,600,000, find the next
 *   timestamp matching the schedule after one hour ago. That way, for one hour after a session officially
 *   starts it will still be visible as an upcoming session so that users can join it.
 * Returns null if the input is invalid.
 */
export function nextTimestamp(schedule: string, offset: number = -3600000): number | null {
  if (!validate(schedule)) return null;

  const [dayMatch, time, timeZone] = schedule.toLowerCase().split(' ');
  const [rate, params] = dayMatch.toLowerCase().replace(')', '').split('(');
  const [hour, minute] = time.split(':').map((x) => parseInt(x));

  const currentTimestamp = new Date().getTime() + offset;
  const currentYear = new Date(currentTimestamp).getFullYear();
  const currentMonth = new Date(currentTimestamp).getMonth();
  // Idea: We will iterate through the possible dates starting from last year till next year (last year in case
  //   time zones throw things just a little off to Dec 31). We are guaranteed that within 3 months of the current
  //   date we'll find one that matches.
  for (let y = currentYear - 1; y <= currentYear + 1; y++) {
    for (let m = 0; m < 12; m++) {
      if ((y < currentYear && m < 12) || (y === currentYear && m < currentMonth - 1)) {
        continue;
      }
      for (let d = 1; d <= 31; d++) {
        const details = getDayDetails(y, m, d, hour, minute, timeZone);
        if (details == null) continue;
        if (details.timestamp < currentTimestamp) continue;

        switch (rate) {
          case 'daily':
            return details.timestamp;
          case 'weekly':
            const weekDays = params.split(',');
            if (weekDays.indexOf(details.dow) > -1) {
              return details.timestamp;
            }
            break;
          case 'monthly':
            const monthDays = params.split(',').map((x) => parseInt(x) || x);
            if (monthDays.indexOf(details.dowm) > -1 || monthDays.indexOf(details.dom) > -1) {
              return details.timestamp;
            }
        }
      }
    }
  }
  throw new Error(
    'A case has come up that I did not think is possible. The schedule string is deemed to be valid, ' +
      "but we can't find a date in the next year that matches said string. Good luck!"
  );
}

const dayNames: { [x: string]: string } = {
  sun: 'Sundays',
  mon: 'Mondays',
  tue: 'Tuesdays',
  wed: 'Wednesdays',
  thu: 'Thursdays',
  fri: 'Fridays',
  sat: 'Saturdays'
};

/**
 * Turn the list of strings into an English string (i.e. 'A, B, and C')
 * @param items
 */
function describeList(items: string[]): string | null {
  const l = items.length;
  if (items.length === 0) return null;
  if (items.length === 1) return `${items[0]}`;
  if (items.length === 2) return `${items[0]} and ${items[1]}`;
  return items.slice(0, l - 1).join(', ') + `, and ${items[l - 1]}`;
}

console.assert(describeList(['A']) === 'A');
console.assert(describeList(['A', 'B']) === 'A and B');
console.assert(describeList(['A', 'B', 'C']) === 'A, B, and C');

const textOrdinals: string[] = ['zeroth', 'first', 'second', 'third', 'fourth', 'fifth'];
const numberOrdinals: string[] = [
  ...['0th', '1st', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th', '9th', '10th'],
  ...['11th', '12th', '13th', '14th', '15th', '16th', '17th', '18th', '19th', '20th'],
  ...['21st', '22nd', '23rd', '24th', '25th', '26th', '27th', '28th', '29th', '30th', '31st']
];

/**
 * Produce an English description of a schedule string
 * @param schedule: string representing the schedule (i.e. `weekly(Mon,Wed,Fri) 15:30 America/New_York`)
 * @param matchTZ: If this is specified and the timezone on the schedule string doesn't match, add
 *    the timezone of the schedule string to the description
 */
export function describeSchedule(schedule: string, matchTZ?: string): string | null {
  if (!validate(schedule)) return null;

  const [dayMatch, time, timeZone] = schedule.toLowerCase().split(' ');
  const [rate, params] = dayMatch.toLowerCase().replace(')', '').split('(');
  const [hour, minute] = time.split(':').map((x) => parseInt(x));
  const hourA = hour % 12 == 0 ? 12 : hour % 12;
  const am = hour >= 12 ? 'PM' : 'AM';
  const tz = matchTZ && matchTZ !== timeZone ? ' ' + timeZone : '';
  const timeDescription = `${two(hourA)}:${two(minute)} ${am}${tz}`;
  if (rate === 'daily') {
    return `Daily @${timeDescription}`;
  } else if (rate === 'weekly') {
    const days = params.split(',').map((x) => dayNames[x]);
    return `${describeList(days)} @${timeDescription}`;
  } else {
    const days = params.split(',').map((x) => {
      if (!isNaN(Number(x))) return numberOrdinals[Number(x)];
      else return `${textOrdinals[Number(x.substr(3, 1))]} ${dayNames[x.substr(0, 3)].replace('days', 'day')}`;
    });
    return `The ${describeList(days)} of the month @${timeDescription}`;
  }
}
