import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faCircle, faDotCircle, faSquare } from '@fortawesome/free-regular-svg-icons';
import './MuCheckbox.scss';

interface MuCheckboxProps {
  checked: boolean;
  label?: string;
  description?: string;
  radio?: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => void;
}

export const MuCheckbox: React.FC<MuCheckboxProps> = ({ checked, radio, label, description, disabled, onChange }) => {
  const icon = radio ? (checked ? faDotCircle : faCircle) : checked ? faCheckSquare : faSquare;

  return (
    <div className={'mu-checkbox' + (disabled ? ' disabled' : '')} onClick={() => !disabled && onChange(!checked)}>
      <div className='checkbox'>
        <FontAwesomeIcon icon={icon} />
      </div>
      <div className='description'>
        <span className='label mu-text-md'>{label}</span>
        <span className='description mu-text-sm'>{description}</span>
      </div>
    </div>
  );
};
