import * as solid from '@fortawesome/free-solid-svg-icons';
// import * as regular from '@fortawesome/free-regular-svg-icons';
import * as brands from '@fortawesome/free-brands-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

export const icons = [
  // solid.faAd,
  // solid.faAddressBook,
  // solid.faAddressCard,
  // solid.faAdjust,
  // solid.faAirFreshener,
  // solid.faAlignCenter,
  // solid.faAlignJustify,
  // solid.faAlignLeft,
  // solid.faAlignRight,
  // solid.faAllergies,
  // solid.faAmbulance,
  // solid.faAmericanSignLanguageInterpreting,
  // solid.faAnchor,
  // solid.faAngleDoubleDown,
  // solid.faAngleDoubleLeft,
  // solid.faAngleDoubleRight,
  // solid.faAngleDoubleUp,
  // solid.faAngleDown,
  // solid.faAngleLeft,
  // solid.faAngleRight,
  // solid.faAngleUp,
  // solid.faAngry,
  // solid.faAnkh,
  // solid.faAppleAlt,
  // solid.faArchive,
  // solid.faArchway,
  // solid.faArrowAltCircleDown,
  // solid.faArrowAltCircleLeft,
  // solid.faArrowAltCircleRight,
  // solid.faArrowAltCircleUp,
  // solid.faArrowCircleDown,
  // solid.faArrowCircleLeft,
  // solid.faArrowCircleRight,
  // solid.faArrowCircleUp,
  // solid.faArrowDown,
  // solid.faArrowLeft,
  // solid.faArrowRight,
  // solid.faArrowUp,
  // solid.faArrowsAlt,
  // solid.faArrowsAltH,
  // solid.faArrowsAltV,
  // solid.faAssistiveListeningSystems,
  // solid.faAsterisk,
  // solid.faAt,
  // solid.faAtlas,
  // solid.faAtom,
  // solid.faAudioDescription,
  // solid.faAward,
  // solid.faBaby,
  // solid.faBabyCarriage,
  // solid.faBackspace,
  // solid.faBackward,
  // solid.faBacon,
  // solid.faBacteria,
  // solid.faBacterium,
  // solid.faBahai,
  // solid.faBalanceScale,
  // solid.faBalanceScaleLeft,
  // solid.faBalanceScaleRight,
  // solid.faBan,
  // solid.faBandAid,
  // solid.faBarcode,
  // solid.faBars,
  // solid.faBaseballBall,
  // solid.faBasketballBall,
  // solid.faBath,
  // solid.faBatteryEmpty,
  // solid.faBatteryFull,
  // solid.faBatteryHalf,
  // solid.faBatteryQuarter,
  // solid.faBatteryThreeQuarters,
  // solid.faBed,
  // solid.faBeer,
  // solid.faBell,
  // solid.faBellSlash,
  // solid.faBezierCurve,
  // solid.faBible,
  // solid.faBicycle,
  // solid.faBiking,
  // solid.faBinoculars,
  // solid.faBiohazard,
  // solid.faBirthdayCake,
  // solid.faBlender,
  // solid.faBlenderPhone,
  // solid.faBlind,
  // solid.faBlog,
  // solid.faBold,
  // solid.faBolt,
  // solid.faBomb,
  // solid.faBone,
  // solid.faBong,
  // solid.faBook,
  // solid.faBookDead,
  // solid.faBookMedical,
  // solid.faBookOpen,
  // solid.faBookReader,
  // solid.faBookmark,
  // solid.faBorderAll,
  // solid.faBorderNone,
  // solid.faBorderStyle,
  // solid.faBowlingBall,
  // solid.faBox,
  // solid.faBoxOpen,
  // solid.faBoxTissue,
  // solid.faBoxes,
  // solid.faBraille,
  // solid.faBrain,
  // solid.faBreadSlice,
  // solid.faBriefcase,
  // solid.faBriefcaseMedical,
  // solid.faBroadcastTower,
  // solid.faBroom,
  // solid.faBrush,
  // solid.faBug,
  // solid.faBuilding,
  // solid.faBullhorn,
  // solid.faBullseye,
  // solid.faBurn,
  // solid.faBus,
  // solid.faBusAlt,
  // solid.faBusinessTime,
  // solid.faCalculator,
  // solid.faCalendar,
  // solid.faCalendarAlt,
  // solid.faCalendarCheck,
  // solid.faCalendarDay,
  // solid.faCalendarMinus,
  // solid.faCalendarPlus,
  // solid.faCalendarTimes,
  // solid.faCalendarWeek,
  // solid.faCamera,
  // solid.faCameraRetro,
  // solid.faCampground,
  // solid.faCandyCane,
  // solid.faCannabis,
  // solid.faCapsules,
  // solid.faCar,
  // solid.faCarAlt,
  // solid.faCarBattery,
  // solid.faCarCrash,
  // solid.faCarSide,
  // solid.faCaravan,
  // solid.faCaretDown,
  // solid.faCaretLeft,
  // solid.faCaretRight,
  // solid.faCaretSquareDown,
  // solid.faCaretSquareLeft,
  // solid.faCaretSquareRight,
  // solid.faCaretSquareUp,
  // solid.faCaretUp,
  // solid.faCarrot,
  // solid.faCartArrowDown,
  // solid.faCartPlus,
  // solid.faCashRegister,
  // solid.faCat,
  // solid.faCertificate,
  // solid.faChair,
  // solid.faChalkboard,
  // solid.faChalkboardTeacher,
  // solid.faChargingStation,
  // solid.faChartArea,
  // solid.faChartBar,
  // solid.faChartLine,
  // solid.faChartPie,
  // solid.faCheck,
  // solid.faCheckCircle,
  // solid.faCheckDouble,
  // solid.faCheckSquare,
  // solid.faCheese,
  // solid.faChess,
  // solid.faChessBishop,
  // solid.faChessBoard,
  // solid.faChessKing,
  // solid.faChessKnight,
  // solid.faChessPawn,
  // solid.faChessQueen,
  // solid.faChessRook,
  // solid.faChevronCircleDown,
  // solid.faChevronCircleLeft,
  // solid.faChevronCircleRight,
  // solid.faChevronCircleUp,
  // solid.faChevronDown,
  // solid.faChevronLeft,
  // solid.faChevronRight,
  // solid.faChevronUp,
  // solid.faChild,
  // solid.faChurch,
  // solid.faCircle,
  // solid.faCircleNotch,
  // solid.faCity,
  // solid.faClinicMedical,
  // solid.faClipboard,
  // solid.faClipboardCheck,
  // solid.faClipboardList,
  // solid.faClock,
  // solid.faClone,
  // solid.faClosedCaptioning,
  // solid.faCloud,
  // solid.faCloudDownloadAlt,
  // solid.faCloudMeatball,
  // solid.faCloudMoon,
  // solid.faCloudMoonRain,
  // solid.faCloudRain,
  // solid.faCloudShowersHeavy,
  // solid.faCloudSun,
  // solid.faCloudSunRain,
  // solid.faCloudUploadAlt,
  // solid.faCocktail,
  // solid.faCode,
  // solid.faCodeBranch,
  // solid.faCoffee,
  // solid.faCog,
  // solid.faCogs,
  // solid.faCoins,
  // solid.faColumns,
  // solid.faComment,
  // solid.faCommentAlt,
  // solid.faCommentDollar,
  // solid.faCommentDots,
  // solid.faCommentMedical,
  // solid.faCommentSlash,
  // solid.faComments,
  // solid.faCommentsDollar,
  // solid.faCompactDisc,
  // solid.faCompass,
  // solid.faCompress,
  // solid.faCompressAlt,
  // solid.faCompressArrowsAlt,
  // solid.faConciergeBell,
  // solid.faCookie,
  // solid.faCookieBite,
  // solid.faCopy,
  // solid.faCopyright,
  // solid.faCouch,
  // solid.faCreditCard,
  // solid.faCrop,
  // solid.faCropAlt,
  // solid.faCross,
  // solid.faCrosshairs,
  // solid.faCrow,
  // solid.faCrown,
  // solid.faCrutch,
  // solid.faCube,
  // solid.faCubes,
  // solid.faCut,
  // solid.faDatabase,
  // solid.faDeaf,
  // solid.faDemocrat,
  // solid.faDesktop,
  // solid.faDharmachakra,
  // solid.faDiagnoses,
  // solid.faDice,
  // solid.faDiceD20,
  // solid.faDiceD6,
  // solid.faDiceFive,
  // solid.faDiceFour,
  // solid.faDiceOne,
  // solid.faDiceSix,
  // solid.faDiceThree,
  // solid.faDiceTwo,
  // solid.faDigitalTachograph,
  // solid.faDirections,
  // solid.faDisease,
  // solid.faDivide,
  // solid.faDizzy,
  // solid.faDna,
  // solid.faDog,
  // solid.faDollarSign,
  // solid.faDolly,
  // solid.faDollyFlatbed,
  // solid.faDonate,
  // solid.faDoorClosed,
  // solid.faDoorOpen,
  // solid.faDotCircle,
  // solid.faDove,
  // solid.faDownload,
  // solid.faDraftingCompass,
  // solid.faDragon,
  // solid.faDrawPolygon,
  // solid.faDrum,
  // solid.faDrumSteelpan,
  // solid.faDrumstickBite,
  // solid.faDumbbell,
  // solid.faDumpster,
  // solid.faDumpsterFire,
  // solid.faDungeon,
  // solid.faEdit,
  // solid.faEgg,
  // solid.faEject,
  // solid.faEllipsisH,
  // solid.faEllipsisV,
  // solid.faEnvelope,
  // solid.faEnvelopeOpen,
  // solid.faEnvelopeOpenText,
  // solid.faEnvelopeSquare,
  // solid.faEquals,
  // solid.faEraser,
  // solid.faEthernet,
  // solid.faEuroSign,
  // solid.faExchangeAlt,
  // solid.faExclamation,
  // solid.faExclamationCircle,
  // solid.faExclamationTriangle,
  // solid.faExpand,
  // solid.faExpandAlt,
  // solid.faExpandArrowsAlt,
  // solid.faExternalLinkAlt,
  // solid.faExternalLinkSquareAlt,
  // solid.faEye,
  // solid.faEyeDropper,
  // solid.faEyeSlash,
  // solid.faFan,
  // solid.faFastBackward,
  // solid.faFastForward,
  // solid.faFaucet,
  // solid.faFax,
  // solid.faFeather,
  // solid.faFeatherAlt,
  // solid.faFemale,
  // solid.faFighterJet,
  // solid.faFile,
  // solid.faFileAlt,
  // solid.faFileArchive,
  // solid.faFileAudio,
  // solid.faFileCode,
  // solid.faFileContract,
  // solid.faFileCsv,
  // solid.faFileDownload,
  // solid.faFileExcel,
  // solid.faFileExport,
  // solid.faFileImage,
  // solid.faFileImport,
  // solid.faFileInvoice,
  // solid.faFileInvoiceDollar,
  // solid.faFileMedical,
  // solid.faFileMedicalAlt,
  // solid.faFilePdf,
  // solid.faFilePowerpoint,
  // solid.faFilePrescription,
  // solid.faFileSignature,
  // solid.faFileUpload,
  // solid.faFileVideo,
  // solid.faFileWord,
  // solid.faFill,
  // solid.faFillDrip,
  // solid.faFilm,
  // solid.faFilter,
  // solid.faFingerprint,
  // solid.faFire,
  // solid.faFireAlt,
  // solid.faFireExtinguisher,
  // solid.faFirstAid,
  // solid.faFish,
  // solid.faFistRaised,
  // solid.faFlag,
  // solid.faFlagCheckered,
  // solid.faFlagUsa,
  // solid.faFlask,
  // solid.faFlushed,
  // solid.faFolder,
  // solid.faFolderMinus,
  // solid.faFolderOpen,
  // solid.faFolderPlus,
  // solid.faFont,
  // solid.faFontAwesomeLogoFull,
  // solid.faFootballBall,
  // solid.faForward,
  // solid.faFrog,
  // solid.faFrown,
  // solid.faFrownOpen,
  // solid.faFunnelDollar,
  // solid.faFutbol,
  // solid.faGamepad,
  // solid.faGasPump,
  // solid.faGavel,
  // solid.faGem,
  // solid.faGenderless,
  // solid.faGhost,
  // solid.faGift,
  // solid.faGifts,
  // solid.faGlassCheers,
  // solid.faGlassMartini,
  // solid.faGlassMartiniAlt,
  // solid.faGlassWhiskey,
  // solid.faGlasses,
  // solid.faGlobe,
  // solid.faGlobeAfrica,
  // solid.faGlobeAmericas,
  // solid.faGlobeAsia,
  // solid.faGlobeEurope,
  // solid.faGolfBall,
  // solid.faGopuram,
  // solid.faGraduationCap,
  // solid.faGreaterThan,
  // solid.faGreaterThanEqual,
  // solid.faGrimace,
  // solid.faGrin,
  // solid.faGrinAlt,
  // solid.faGrinBeam,
  // solid.faGrinBeamSweat,
  // solid.faGrinHearts,
  // solid.faGrinSquint,
  // solid.faGrinSquintTears,
  // solid.faGrinStars,
  // solid.faGrinTears,
  // solid.faGrinTongue,
  // solid.faGrinTongueSquint,
  // solid.faGrinTongueWink,
  // solid.faGrinWink,
  // solid.faGripHorizontal,
  // solid.faGripLines,
  // solid.faGripLinesVertical,
  // solid.faGripVertical,
  // solid.faGuitar,
  // solid.faHSquare,
  // solid.faHamburger,
  // solid.faHammer,
  // solid.faHamsa,
  // solid.faHandHolding,
  // solid.faHandHoldingHeart,
  // solid.faHandHoldingMedical,
  // solid.faHandHoldingUsd,
  // solid.faHandHoldingWater,
  // solid.faHandLizard,
  // solid.faHandMiddleFinger,
  // solid.faHandPaper,
  // solid.faHandPeace,
  // solid.faHandPointDown,
  // solid.faHandPointLeft,
  // solid.faHandPointRight,
  // solid.faHandPointUp,
  // solid.faHandPointer,
  // solid.faHandRock,
  // solid.faHandScissors,
  // solid.faHandSparkles,
  // solid.faHandSpock,
  // solid.faHands,
  // solid.faHandsHelping,
  // solid.faHandsWash,
  // solid.faHandshake,
  // solid.faHandshakeAltSlash,
  // solid.faHandshakeSlash,
  // solid.faHanukiah,
  // solid.faHardHat,
  // solid.faHashtag,
  // solid.faHatCowboy,
  // solid.faHatCowboySide,
  // solid.faHatWizard,
  // solid.faHdd,
  // solid.faHeadSideCough,
  // solid.faHeadSideCoughSlash,
  // solid.faHeadSideMask,
  // solid.faHeadSideVirus,
  // solid.faHeading,
  // solid.faHeadphones,
  // solid.faHeadphonesAlt,
  // solid.faHeadset,
  // solid.faHeart,
  // solid.faHeartBroken,
  // solid.faHeartbeat,
  // solid.faHelicopter,
  // solid.faHighlighter,
  // solid.faHiking,
  // solid.faHippo,
  // solid.faHistory,
  // solid.faHockeyPuck,
  // solid.faHollyBerry,
  // solid.faHome,
  // solid.faHorse,
  // solid.faHorseHead,
  // solid.faHospital,
  // solid.faHospitalAlt,
  // solid.faHospitalSymbol,
  // solid.faHospitalUser,
  // solid.faHotTub,
  // solid.faHotdog,
  // solid.faHotel,
  // solid.faHourglass,
  // solid.faHourglassEnd,
  // solid.faHourglassHalf,
  // solid.faHourglassStart,
  // solid.faHouseDamage,
  // solid.faHouseUser,
  // solid.faHryvnia,
  // solid.faICursor,
  // solid.faIceCream,
  // solid.faIcicles,
  // solid.faIcons,
  // solid.faIdBadge,
  // solid.faIdCard,
  // solid.faIdCardAlt,
  // solid.faIgloo,
  // solid.faImage,
  // solid.faImages,
  // solid.faInbox,
  // solid.faIndent,
  // solid.faIndustry,
  // solid.faInfinity,
  // solid.faInfo,
  // solid.faInfoCircle,
  // solid.faItalic,
  // solid.faJedi,
  // solid.faJoint,
  // solid.faJournalWhills,
  // solid.faKaaba,
  // solid.faKey,
  // solid.faKeyboard,
  // solid.faKhanda,
  // solid.faKiss,
  // solid.faKissBeam,
  // solid.faKissWinkHeart,
  // solid.faKiwiBird,
  // solid.faLandmark,
  // solid.faLanguage,
  // solid.faLaptop,
  // solid.faLaptopCode,
  // solid.faLaptopHouse,
  // solid.faLaptopMedical,
  // solid.faLaugh,
  // solid.faLaughBeam,
  // solid.faLaughSquint,
  // solid.faLaughWink,
  // solid.faLayerGroup,
  // solid.faLeaf,
  // solid.faLemon,
  // solid.faLessThan,
  // solid.faLessThanEqual,
  // solid.faLevelDownAlt,
  // solid.faLevelUpAlt,
  // solid.faLifeRing,
  // solid.faLightbulb,
  // solid.faLink,
  // solid.faLiraSign,
  // solid.faList,
  // solid.faListAlt,
  // solid.faListOl,
  // solid.faListUl,
  // solid.faLocationArrow,
  // solid.faLock,
  // solid.faLockOpen,
  // solid.faLongArrowAltDown,
  // solid.faLongArrowAltLeft,
  // solid.faLongArrowAltRight,
  // solid.faLongArrowAltUp,
  // solid.faLowVision,
  // solid.faLuggageCart,
  // solid.faLungs,
  // solid.faLungsVirus,
  // solid.faMagic,
  // solid.faMagnet,
  // solid.faMailBulk,
  // solid.faMale,
  // solid.faMap,
  // solid.faMapMarked,
  // solid.faMapMarkedAlt,
  // solid.faMapMarker,
  // solid.faMapMarkerAlt,
  // solid.faMapPin,
  // solid.faMapSigns,
  // solid.faMarker,
  // solid.faMars,
  // solid.faMarsDouble,
  // solid.faMarsStroke,
  // solid.faMarsStrokeH,
  // solid.faMarsStrokeV,
  // solid.faMask,
  // solid.faMedal,
  // solid.faMedkit,
  // solid.faMeh,
  // solid.faMehBlank,
  // solid.faMehRollingEyes,
  // solid.faMemory,
  // solid.faMenorah,
  // solid.faMercury,
  // solid.faMeteor,
  // solid.faMicrochip,
  // solid.faMicrophone,
  // solid.faMicrophoneAlt,
  // solid.faMicrophoneAltSlash,
  // solid.faMicrophoneSlash,
  // solid.faMicroscope,
  // solid.faMinus,
  // solid.faMinusCircle,
  // solid.faMinusSquare,
  // solid.faMitten,
  // solid.faMobile,
  // solid.faMobileAlt,
  // solid.faMoneyBill,
  // solid.faMoneyBillAlt,
  // solid.faMoneyBillWave,
  // solid.faMoneyBillWaveAlt,
  // solid.faMoneyCheck,
  // solid.faMoneyCheckAlt,
  // solid.faMonument,
  // solid.faMoon,
  // solid.faMortarPestle,
  // solid.faMosque,
  // solid.faMotorcycle,
  // solid.faMountain,
  // solid.faMouse,
  // solid.faMousePointer,
  // solid.faMugHot,
  // solid.faMusic,
  // solid.faNetworkWired,
  // solid.faNeuter,
  // solid.faNewspaper,
  // solid.faNotEqual,
  // solid.faNotesMedical,
  // solid.faObjectGroup,
  // solid.faObjectUngroup,
  // solid.faOilCan,
  // solid.faOm,
  // solid.faOtter,
  // solid.faOutdent,
  // solid.faPager,
  // solid.faPaintBrush,
  // solid.faPaintRoller,
  // solid.faPalette,
  // solid.faPallet,
  // solid.faPaperPlane,
  // solid.faPaperclip,
  // solid.faParachuteBox,
  // solid.faParagraph,
  // solid.faParking,
  // solid.faPassport,
  // solid.faPastafarianism,
  // solid.faPaste,
  // solid.faPause,
  // solid.faPauseCircle,
  // solid.faPaw,
  // solid.faPeace,
  // solid.faPen,
  // solid.faPenAlt,
  // solid.faPenFancy,
  // solid.faPenNib,
  // solid.faPenSquare,
  // solid.faPencilAlt,
  // solid.faPencilRuler,
  // solid.faPeopleArrows,
  // solid.faPeopleCarry,
  // solid.faPepperHot,
  // solid.faPercent,
  // solid.faPercentage,
  // solid.faPersonBooth,
  // solid.faPhone,
  // solid.faPhoneAlt,
  // solid.faPhoneSlash,
  // solid.faPhoneSquare,
  // solid.faPhoneSquareAlt,
  // solid.faPhoneVolume,
  // solid.faPhotoVideo,
  // solid.faPiggyBank,
  // solid.faPills,
  // solid.faPizzaSlice,
  // solid.faPlaceOfWorship,
  // solid.faPlane,
  // solid.faPlaneArrival,
  // solid.faPlaneDeparture,
  // solid.faPlaneSlash,
  // solid.faPlay,
  // solid.faPlayCircle,
  // solid.faPlug,
  // solid.faPlus,
  // solid.faPlusCircle,
  // solid.faPlusSquare,
  // solid.faPodcast,
  // solid.faPoll,
  // solid.faPollH,
  // solid.faPoo,
  // solid.faPooStorm,
  // solid.faPoop,
  // solid.faPortrait,
  // solid.faPoundSign,
  // solid.faPowerOff,
  // solid.faPray,
  // solid.faPrayingHands,
  // solid.faPrescription,
  // solid.faPrescriptionBottle,
  // solid.faPrescriptionBottleAlt,
  // solid.faPrint,
  // solid.faProcedures,
  // solid.faProjectDiagram,
  // solid.faPumpMedical,
  // solid.faPumpSoap,
  // solid.faPuzzlePiece,
  // solid.faQrcode,
  // solid.faQuestion,
  // solid.faQuestionCircle,
  // solid.faQuidditch,
  // solid.faQuoteLeft,
  // solid.faQuoteRight,
  // solid.faQuran,
  // solid.faRadiation,
  // solid.faRadiationAlt,
  // solid.faRainbow,
  // solid.faRandom,
  // solid.faReceipt,
  // solid.faRecordVinyl,
  // solid.faRecycle,
  // solid.faRedo,
  // solid.faRedoAlt,
  // solid.faRegistered,
  // solid.faRemoveFormat,
  // solid.faReply,
  // solid.faReplyAll,
  // solid.faRepublican,
  // solid.faRestroom,
  // solid.faRetweet,
  // solid.faRibbon,
  // solid.faRing,
  // solid.faRoad,
  // solid.faRobot,
  // solid.faRocket,
  // solid.faRoute,
  // solid.faRss,
  // solid.faRssSquare,
  // solid.faRubleSign,
  // solid.faRuler,
  // solid.faRulerCombined,
  // solid.faRulerHorizontal,
  // solid.faRulerVertical,
  // solid.faRunning,
  // solid.faRupeeSign,
  // solid.faSadCry,
  // solid.faSadTear,
  // solid.faSatellite,
  // solid.faSatelliteDish,
  // solid.faSave,
  // solid.faSchool,
  // solid.faScrewdriver,
  // solid.faScroll,
  // solid.faSdCard,
  // solid.faSearch,
  // solid.faSearchDollar,
  // solid.faSearchLocation,
  // solid.faSearchMinus,
  // solid.faSearchPlus,
  // solid.faSeedling,
  // solid.faServer,
  // solid.faShapes,
  // solid.faShare,
  // solid.faShareAlt,
  // solid.faShareAltSquare,
  // solid.faShareSquare,
  // solid.faShekelSign,
  // solid.faShieldAlt,
  // solid.faShieldVirus,
  // solid.faShip,
  // solid.faShippingFast,
  // solid.faShoePrints,
  // solid.faShoppingBag,
  // solid.faShoppingBasket,
  // solid.faShoppingCart,
  // solid.faShower,
  // solid.faShuttleVan,
  // solid.faSign,
  // solid.faSignInAlt,
  // solid.faSignLanguage,
  // solid.faSignOutAlt,
  // solid.faSignal,
  // solid.faSignature,
  // solid.faSimCard,
  // solid.faSink,
  // solid.faSitemap,
  // solid.faSkating,
  // solid.faSkiing,
  // solid.faSkiingNordic,
  // solid.faSkull,
  // solid.faSkullCrossbones,
  // solid.faSlash,
  // solid.faSleigh,
  // solid.faSlidersH,
  // solid.faSmile,
  // solid.faSmileBeam,
  // solid.faSmileWink,
  // solid.faSmog,
  // solid.faSmoking,
  // solid.faSmokingBan,
  // solid.faSms,
  // solid.faSnowboarding,
  // solid.faSnowflake,
  // solid.faSnowman,
  // solid.faSnowplow,
  // solid.faSoap,
  // solid.faSocks,
  // solid.faSolarPanel,
  // solid.faSort,
  // solid.faSortAlphaDown,
  // solid.faSortAlphaDownAlt,
  // solid.faSortAlphaUp,
  // solid.faSortAlphaUpAlt,
  // solid.faSortAmountDown,
  // solid.faSortAmountDownAlt,
  // solid.faSortAmountUp,
  // solid.faSortAmountUpAlt,
  // solid.faSortDown,
  // solid.faSortNumericDown,
  // solid.faSortNumericDownAlt,
  // solid.faSortNumericUp,
  // solid.faSortNumericUpAlt,
  // solid.faSortUp,
  // solid.faSpa,
  // solid.faSpaceShuttle,
  // solid.faSpellCheck,
  // solid.faSpider,
  // solid.faSpinner,
  // solid.faSplotch,
  // solid.faSprayCan,
  // solid.faSquare,
  // solid.faSquareFull,
  // solid.faSquareRootAlt,
  // solid.faStamp,
  // solid.faStar,
  // solid.faStarAndCrescent,
  // solid.faStarHalf,
  // solid.faStarHalfAlt,
  // solid.faStarOfDavid,
  // solid.faStarOfLife,
  // solid.faStepBackward,
  // solid.faStepForward,
  // solid.faStethoscope,
  // solid.faStickyNote,
  // solid.faStop,
  // solid.faStopCircle,
  // solid.faStopwatch,
  // solid.faStopwatch20,
  // solid.faStore,
  // solid.faStoreAlt,
  // solid.faStoreAltSlash,
  // solid.faStoreSlash,
  // solid.faStream,
  // solid.faStreetView,
  // solid.faStrikethrough,
  // solid.faStroopwafel,
  // solid.faSubscript,
  // solid.faSubway,
  // solid.faSuitcase,
  // solid.faSuitcaseRolling,
  // solid.faSun,
  // solid.faSuperscript,
  // solid.faSurprise,
  // solid.faSwatchbook,
  // solid.faSwimmer,
  // solid.faSwimmingPool,
  // solid.faSynagogue,
  // solid.faSync,
  // solid.faSyncAlt,
  // solid.faSyringe,
  // solid.faTable,
  // solid.faTableTennis,
  // solid.faTablet,
  // solid.faTabletAlt,
  // solid.faTablets,
  // solid.faTachometerAlt,
  // solid.faTag,
  // solid.faTags,
  // solid.faTape,
  // solid.faTasks,
  // solid.faTaxi,
  // solid.faTeeth,
  // solid.faTeethOpen,
  // solid.faTemperatureHigh,
  // solid.faTemperatureLow,
  // solid.faTenge,
  // solid.faTerminal,
  // solid.faTextHeight,
  // solid.faTextWidth,
  // solid.faTh,
  // solid.faThLarge,
  // solid.faThList,
  // solid.faTheaterMasks,
  // solid.faThermometer,
  // solid.faThermometerEmpty,
  // solid.faThermometerFull,
  // solid.faThermometerHalf,
  // solid.faThermometerQuarter,
  // solid.faThermometerThreeQuarters,
  // solid.faThumbsDown,
  // solid.faThumbsUp,
  // solid.faThumbtack,
  // solid.faTicketAlt,
  // solid.faTimes,
  // solid.faTimesCircle,
  // solid.faTint,
  // solid.faTintSlash,
  // solid.faTired,
  // solid.faToggleOff,
  // solid.faToggleOn,
  // solid.faToilet,
  // solid.faToiletPaper,
  // solid.faToiletPaperSlash,
  // solid.faToolbox,
  // solid.faTools,
  // solid.faTooth,
  // solid.faTorah,
  // solid.faToriiGate,
  // solid.faTractor,
  // solid.faTrademark,
  // solid.faTrafficLight,
  // solid.faTrailer,
  // solid.faTrain,
  // solid.faTram,
  // solid.faTransgender,
  // solid.faTransgenderAlt,
  // solid.faTrash,
  // solid.faTrashAlt,
  // solid.faTrashRestore,
  // solid.faTrashRestoreAlt,
  // solid.faTree,
  // solid.faTrophy,
  // solid.faTruck,
  // solid.faTruckLoading,
  // solid.faTruckMonster,
  // solid.faTruckMoving,
  // solid.faTruckPickup,
  // solid.faTshirt,
  // solid.faTty,
  // solid.faTv,
  // solid.faUmbrella,
  // solid.faUmbrellaBeach,
  // solid.faUnderline,
  // solid.faUndo,
  // solid.faUndoAlt,
  // solid.faUniversalAccess,
  // solid.faUniversity,
  // solid.faUnlink,
  // solid.faUnlock,
  // solid.faUnlockAlt,
  // solid.faUpload,
  // solid.faUser,
  // solid.faUserAlt,
  // solid.faUserAltSlash,
  // solid.faUserAstronaut,
  // solid.faUserCheck,
  // solid.faUserCircle,
  // solid.faUserClock,
  // solid.faUserCog,
  // solid.faUserEdit,
  // solid.faUserFriends,
  // solid.faUserGraduate,
  // solid.faUserInjured,
  // solid.faUserLock,
  // solid.faUserMd,
  // solid.faUserMinus,
  // solid.faUserNinja,
  // solid.faUserNurse,
  // solid.faUserPlus,
  // solid.faUserSecret,
  // solid.faUserShield,
  // solid.faUserSlash,
  // solid.faUserTag,
  // solid.faUserTie,
  // solid.faUserTimes,
  // solid.faUsers,
  // solid.faUsersCog,
  // solid.faUsersSlash,
  // solid.faUtensilSpoon,
  // solid.faUtensils,
  // solid.faVectorSquare,
  // solid.faVenus,
  // solid.faVenusDouble,
  // solid.faVenusMars,
  // solid.faVest,
  // solid.faVestPatches,
  // solid.faVial,
  // solid.faVials,
  // solid.faVideo,
  // solid.faVideoSlash,
  // solid.faVihara,
  // solid.faVirus,
  // solid.faVirusSlash,
  // solid.faViruses,
  // solid.faVoicemail,
  // solid.faVolleyballBall,
  // solid.faVolumeDown,
  // solid.faVolumeMute,
  // solid.faVolumeOff,
  // solid.faVolumeUp,
  // solid.faVoteYea,
  // solid.faVrCardboard,
  // solid.faWalking,
  // solid.faWallet,
  // solid.faWarehouse,
  // solid.faWater,
  // solid.faWaveSquare,
  // solid.faWeight,
  // solid.faWeightHanging,
  // solid.faWheelchair,
  // solid.faWifi,
  // solid.faWind,
  // solid.faWindowClose,
  // solid.faWindowMaximize,
  // solid.faWindowMinimize,
  // solid.faWindowRestore,
  // solid.faWineBottle,
  // solid.faWineGlass,
  // solid.faWineGlassAlt,
  // solid.faWonSign,
  // solid.faWrench,
  // solid.faXRay,
  // solid.faYenSign,
  // solid.faYinYang,
  // regular.faAddressBook,
  // regular.faAddressCard,
  // regular.faAngry,
  // regular.faArrowAltCircleDown,
  // regular.faArrowAltCircleLeft,
  // regular.faArrowAltCircleRight,
  // regular.faArrowAltCircleUp,
  // regular.faBell,
  // regular.faBellSlash,
  // regular.faBookmark,
  // regular.faBuilding,
  // regular.faCalendar,
  // regular.faCalendarAlt,
  // regular.faCalendarCheck,
  // regular.faCalendarMinus,
  // regular.faCalendarPlus,
  // regular.faCalendarTimes,
  // regular.faCaretSquareDown,
  // regular.faCaretSquareLeft,
  // regular.faCaretSquareRight,
  // regular.faCaretSquareUp,
  // regular.faChartBar,
  // regular.faCheckCircle,
  // regular.faCheckSquare,
  // regular.faCircle,
  // regular.faClipboard,
  // regular.faClock,
  // regular.faClone,
  // regular.faClosedCaptioning,
  // regular.faComment,
  // regular.faCommentAlt,
  // regular.faCommentDots,
  // regular.faComments,
  // regular.faCompass,
  // regular.faCopy,
  // regular.faCopyright,
  // regular.faCreditCard,
  // regular.faDizzy,
  // regular.faDotCircle,
  // regular.faEdit,
  // regular.faEnvelope,
  // regular.faEnvelopeOpen,
  // regular.faEye,
  // regular.faEyeSlash,
  // regular.faFile,
  // regular.faFileAlt,
  // regular.faFileArchive,
  // regular.faFileAudio,
  // regular.faFileCode,
  // regular.faFileExcel,
  // regular.faFileImage,
  // regular.faFilePdf,
  // regular.faFilePowerpoint,
  // regular.faFileVideo,
  // regular.faFileWord,
  // regular.faFlag,
  // regular.faFlushed,
  // regular.faFolder,
  // regular.faFolderOpen,
  // regular.faFontAwesomeLogoFull,
  // regular.faFrown,
  // regular.faFrownOpen,
  // regular.faFutbol,
  // regular.faGem,
  // regular.faGrimace,
  // regular.faGrin,
  // regular.faGrinAlt,
  // regular.faGrinBeam,
  // regular.faGrinBeamSweat,
  // regular.faGrinHearts,
  // regular.faGrinSquint,
  // regular.faGrinSquintTears,
  // regular.faGrinStars,
  // regular.faGrinTears,
  // regular.faGrinTongue,
  // regular.faGrinTongueSquint,
  // regular.faGrinTongueWink,
  // regular.faGrinWink,
  // regular.faHandLizard,
  // regular.faHandPaper,
  // regular.faHandPeace,
  // regular.faHandPointDown,
  // regular.faHandPointLeft,
  // regular.faHandPointRight,
  // regular.faHandPointUp,
  // regular.faHandPointer,
  // regular.faHandRock,
  // regular.faHandScissors,
  // regular.faHandSpock,
  // regular.faHandshake,
  // regular.faHdd,
  // regular.faHeart,
  // regular.faHospital,
  // regular.faHourglass,
  // regular.faIdBadge,
  // regular.faIdCard,
  // regular.faImage,
  // regular.faImages,
  // regular.faKeyboard,
  // regular.faKiss,
  // regular.faKissBeam,
  // regular.faKissWinkHeart,
  // regular.faLaugh,
  // regular.faLaughBeam,
  // regular.faLaughSquint,
  // regular.faLaughWink,
  // regular.faLemon,
  // regular.faLifeRing,
  // regular.faLightbulb,
  // regular.faListAlt,
  // regular.faMap,
  // regular.faMeh,
  // regular.faMehBlank,
  // regular.faMehRollingEyes,
  // regular.faMinusSquare,
  // regular.faMoneyBillAlt,
  // regular.faMoon,
  // regular.faNewspaper,
  // regular.faObjectGroup,
  // regular.faObjectUngroup,
  // regular.faPaperPlane,
  // regular.faPauseCircle,
  // regular.faPlayCircle,
  // regular.faPlusSquare,
  // regular.faQuestionCircle,
  // regular.faRegistered,
  // regular.faSadCry,
  // regular.faSadTear,
  // regular.faSave,
  // regular.faShareSquare,
  // regular.faSmile,
  // regular.faSmileBeam,
  // regular.faSmileWink,
  // regular.faSnowflake,
  // regular.faSquare,
  // regular.faStar,
  // regular.faStarHalf,
  // regular.faStickyNote,
  // regular.faStopCircle,
  // regular.faSun,
  // regular.faSurprise,
  // regular.faThumbsDown,
  // regular.faThumbsUp,
  // regular.faTimesCircle,
  // regular.faTired,
  // regular.faTrashAlt,
  // regular.faUser,
  // regular.faUserCircle,
  // regular.faWindowClose,
  // regular.faWindowMaximize,
  // regular.faWindowMinimize,
  // regular.faWindowRestore,
  // brands.fa500px,
  // brands.faAccessibleIcon,
  // brands.faAccusoft,
  // brands.faAcquisitionsIncorporated,
  // brands.faAdn,
  // brands.faAdversal,
  // brands.faAffiliatetheme,
  // brands.faAirbnb,
  // brands.faAlgolia,
  // brands.faAlipay,
  // brands.faAmazon,
  // brands.faAmazonPay,
  // brands.faAmilia,
  // brands.faAndroid,
  // brands.faAngellist,
  // brands.faAngrycreative,
  // brands.faAngular,
  // brands.faAppStore,
  // brands.faAppStoreIos,
  // brands.faApper,
  // brands.faApple,
  // brands.faApplePay,
  // brands.faArtstation,
  // brands.faAsymmetrik,
  // brands.faAtlassian,
  // brands.faAudible,
  // brands.faAutoprefixer,
  // brands.faAvianex,
  // brands.faAviato,
  // brands.faAws,
  // brands.faBandcamp,
  // brands.faBattleNet,
  // brands.faBehance,
  // brands.faBehanceSquare,
  // brands.faBimobject,
  // brands.faBitbucket,
  // brands.faBitcoin,
  // brands.faBity,
  // brands.faBlackTie,
  // brands.faBlackberry,
  // brands.faBlogger,
  // brands.faBloggerB,
  // brands.faBluetooth,
  // brands.faBluetoothB,
  // brands.faBootstrap,
  // brands.faBtc,
  // brands.faBuffer,
  // brands.faBuromobelexperte,
  // brands.faBuyNLarge,
  // brands.faBuysellads,
  // brands.faCanadianMapleLeaf,
  // brands.faCcAmazonPay,
  // brands.faCcAmex,
  // brands.faCcApplePay,
  // brands.faCcDinersClub,
  // brands.faCcDiscover,
  // brands.faCcJcb,
  // brands.faCcMastercard,
  // brands.faCcPaypal,
  // brands.faCcStripe,
  // brands.faCcVisa,
  // brands.faCentercode,
  // brands.faCentos,
  // brands.faChrome,
  // brands.faChromecast,
  // brands.faCloudflare,
  // brands.faCloudscale,
  // brands.faCloudsmith,
  // brands.faCloudversify,
  // brands.faCodepen,
  // brands.faCodiepie,
  // brands.faConfluence,
  // brands.faConnectdevelop,
  // brands.faContao,
  // brands.faCottonBureau,
  // brands.faCpanel,
  // brands.faCreativeCommons,
  // brands.faCreativeCommonsBy,
  // brands.faCreativeCommonsNc,
  // brands.faCreativeCommonsNcEu,
  // brands.faCreativeCommonsNcJp,
  // brands.faCreativeCommonsNd,
  // brands.faCreativeCommonsPd,
  // brands.faCreativeCommonsPdAlt,
  // brands.faCreativeCommonsRemix,
  // brands.faCreativeCommonsSa,
  // brands.faCreativeCommonsSampling,
  // brands.faCreativeCommonsSamplingPlus,
  // brands.faCreativeCommonsShare,
  // brands.faCreativeCommonsZero,
  // brands.faCriticalRole,
  // brands.faCss3,
  // brands.faCss3Alt,
  // brands.faCuttlefish,
  // brands.faDAndD,
  // brands.faDAndDBeyond,
  // brands.faDailymotion,
  // brands.faDashcube,
  // brands.faDeezer,
  // brands.faDelicious,
  // brands.faDeploydog,
  // brands.faDeskpro,
  // brands.faDev,
  // brands.faDeviantart,
  // brands.faDhl,
  // brands.faDiaspora,
  // brands.faDigg,
  // brands.faDigitalOcean,
  // brands.faDiscord,
  // brands.faDiscourse,
  // brands.faDochub,
  // brands.faDocker,
  // brands.faDraft2digital,
  // brands.faDribbble,
  // brands.faDribbbleSquare,
  // brands.faDropbox,
  // brands.faDrupal,
  // brands.faDyalog,
  // brands.faEarlybirds,
  // brands.faEbay,
  // brands.faEdge,
  // brands.faEdgeLegacy,
  // brands.faElementor,
  // brands.faEllo,
  // brands.faEmber,
  // brands.faEmpire,
  // brands.faEnvira,
  // brands.faErlang,
  // brands.faEthereum,
  // brands.faEtsy,
  // brands.faEvernote,
  // brands.faExpeditedssl,
  // brands.faFacebook,
  // brands.faFacebookF,
  // brands.faFacebookMessenger,
  // brands.faFacebookSquare,
  // brands.faFantasyFlightGames,
  // brands.faFedex,
  // brands.faFedora,
  // brands.faFigma,
  // brands.faFirefox,
  // brands.faFirefoxBrowser,
  // brands.faFirstOrder,
  // brands.faFirstOrderAlt,
  // brands.faFirstdraft,
  // brands.faFlickr,
  // brands.faFlipboard,
  // brands.faFly,
  // brands.faFontAwesome,
  // brands.faFontAwesomeAlt,
  // brands.faFontAwesomeFlag,
  // brands.faFontAwesomeLogoFull,
  // brands.faFonticons,
  // brands.faFonticonsFi,
  // brands.faFortAwesome,
  // brands.faFortAwesomeAlt,
  // brands.faForumbee,
  // brands.faFoursquare,
  // brands.faFreeCodeCamp,
  // brands.faFreebsd,
  // brands.faFulcrum,
  // brands.faGalacticRepublic,
  // brands.faGalacticSenate,
  // brands.faGetPocket,
  // brands.faGg,
  // brands.faGgCircle,
  // brands.faGit,
  // brands.faGitAlt,
  // brands.faGitSquare,
  // brands.faGithub,
  // brands.faGithubAlt,
  // brands.faGithubSquare,
  // brands.faGitkraken,
  // brands.faGitlab,
  // brands.faGitter,
  // brands.faGlide,
  // brands.faGlideG,
  // brands.faGofore,
  // brands.faGoodreads,
  // brands.faGoodreadsG,
  // brands.faGoogle,
  // brands.faGoogleDrive,
  // brands.faGooglePay,
  // brands.faGooglePlay,
  // brands.faGooglePlus,
  // brands.faGooglePlusG,
  // brands.faGooglePlusSquare,
  // brands.faGoogleWallet,
  // brands.faGratipay,
  // brands.faGrav,
  // brands.faGripfire,
  // brands.faGrunt,
  // brands.faGuilded,
  // brands.faGulp,
  // brands.faHackerNews,
  // brands.faHackerNewsSquare,
  // brands.faHackerrank,
  // brands.faHips,
  // brands.faHireAHelper,
  // brands.faHive,
  // brands.faHooli,
  // brands.faHornbill,
  // brands.faHotjar,
  // brands.faHouzz,
  // brands.faHtml5,
  // brands.faHubspot,
  // brands.faIdeal,
  // brands.faImdb,
  // brands.faInnosoft,
  // brands.faInstagram,
  // brands.faInstagramSquare,
  // brands.faInstalod,
  // brands.faIntercom,
  // brands.faInternetExplorer,
  // brands.faInvision,
  // brands.faIoxhost,
  // brands.faItchIo,
  // brands.faItunes,
  // brands.faItunesNote,
  // brands.faJava,
  // brands.faJediOrder,
  // brands.faJenkins,
  // brands.faJira,
  // brands.faJoget,
  // brands.faJoomla,
  // brands.faJs,
  // brands.faJsSquare,
  // brands.faJsfiddle,
  // brands.faKaggle,
  // brands.faKeybase,
  // brands.faKeycdn,
  // brands.faKickstarter,
  // brands.faKickstarterK,
  // brands.faKorvue,
  // brands.faLaravel,
  // brands.faLastfm,
  // brands.faLastfmSquare,
  // brands.faLeanpub,
  // brands.faLess,
  // brands.faLine,
  // brands.faLinkedin,
  // brands.faLinkedinIn,
  // brands.faLinode,
  // brands.faLinux,
  // brands.faLyft,
  // brands.faMagento,
  // brands.faMailchimp,
  // brands.faMandalorian,
  // brands.faMarkdown,
  // brands.faMastodon,
  // brands.faMaxcdn,
  // brands.faMdb,
  // brands.faMedapps,
  // brands.faMedium,
  // brands.faMediumM,
  // brands.faMedrt,
  // brands.faMeetup,
  // brands.faMegaport,
  // brands.faMendeley,
  // brands.faMicroblog,
  // brands.faMicrosoft,
  // brands.faMix,
  // brands.faMixcloud,
  // brands.faMixer,
  // brands.faMizuni,
  // brands.faModx,
  // brands.faMonero,
  // brands.faNapster,
  // brands.faNeos,
  // brands.faNimblr,
  // brands.faNode,
  // brands.faNodeJs,
  // brands.faNpm,
  // brands.faNs8,
  // brands.faNutritionix,
  // brands.faOctopusDeploy,
  // brands.faOdnoklassniki,
  // brands.faOdnoklassnikiSquare,
  // brands.faOldRepublic,
  // brands.faOpencart,
  // brands.faOpenid,
  // brands.faOpera,
  // brands.faOptinMonster,
  // brands.faOrcid,
  // brands.faOsi,
  // brands.faPage4,
  // brands.faPagelines,
  // brands.faPalfed,
  // brands.faPatreon,
  // brands.faPaypal,
  // brands.faPennyArcade,
  // brands.faPerbyte,
  // brands.faPeriscope,
  // brands.faPhabricator,
  // brands.faPhoenixFramework,
  // brands.faPhoenixSquadron,
  // brands.faPhp,
  // brands.faPiedPiper,
  // brands.faPiedPiperAlt,
  // brands.faPiedPiperHat,
  // brands.faPiedPiperPp,
  // brands.faPiedPiperSquare,
  // brands.faPinterest,
  // brands.faPinterestP,
  // brands.faPinterestSquare,
  // brands.faPlaystation,
  // brands.faProductHunt,
  // brands.faPushed,
  // brands.faPython,
  // brands.faQq,
  // brands.faQuinscape,
  // brands.faQuora,
  // brands.faRProject,
  // brands.faRaspberryPi,
  // brands.faRavelry,
  // brands.faReact,
  // brands.faReacteurope,
  // brands.faReadme,
  // brands.faRebel,
  // brands.faRedRiver,
  // brands.faReddit,
  // brands.faRedditAlien,
  // brands.faRedditSquare,
  // brands.faRedhat,
  // brands.faRenren,
  // brands.faReplyd,
  // brands.faResearchgate,
  // brands.faResolving,
  // brands.faRev,
  // brands.faRocketchat,
  // brands.faRockrms,
  // brands.faRust,
  // brands.faSafari,
  // brands.faSalesforce,
  // brands.faSass,
  // brands.faSchlix,
  // brands.faScribd,
  // brands.faSearchengin,
  // brands.faSellcast,
  // brands.faSellsy,
  // brands.faServicestack,
  // brands.faShirtsinbulk,
  // brands.faShopify,
  // brands.faShopware,
  // brands.faSimplybuilt,
  // brands.faSistrix,
  // brands.faSith,
  // brands.faSketch,
  // brands.faSkyatlas,
  // brands.faSkype,
  // brands.faSlack,
  // brands.faSlackHash,
  // brands.faSlideshare,
  // brands.faSnapchat,
  // brands.faSnapchatGhost,
  // brands.faSnapchatSquare,
  // brands.faSoundcloud,
  // brands.faSourcetree,
  // brands.faSpeakap,
  // brands.faSpeakerDeck,
  // brands.faSpotify,
  // brands.faSquarespace,
  // brands.faStackExchange,
  // brands.faStackOverflow,
  // brands.faStackpath,
  // brands.faStaylinked,
  // brands.faSteam,
  // brands.faSteamSquare,
  // brands.faSteamSymbol,
  // brands.faStickerMule,
  // brands.faStrava,
  // brands.faStripe,
  // brands.faStripeS,
  // brands.faStudiovinari,
  // brands.faStumbleupon,
  // brands.faStumbleuponCircle,
  // brands.faSuperpowers,
  // brands.faSupple,
  // brands.faSuse,
  // brands.faSwift,
  // brands.faSymfony,
  // brands.faTeamspeak,
  // brands.faTelegram,
  // brands.faTelegramPlane,
  // brands.faTencentWeibo,
  // brands.faTheRedYeti,
  // brands.faThemeco,
  // brands.faThemeisle,
  // brands.faThinkPeaks,
  // brands.faTiktok,
  // brands.faTradeFederation,
  // brands.faTrello,
  // brands.faTumblr,
  // brands.faTumblrSquare,
  // brands.faTwitch,
  // brands.faTwitter,
  // brands.faTwitterSquare,
  // brands.faTypo3,
  // brands.faUber,
  // brands.faUbuntu,
  // brands.faUikit,
  // brands.faUmbraco,
  // brands.faUncharted,
  // brands.faUniregistry,
  // brands.faUnity,
  // brands.faUnsplash,
  // brands.faUntappd,
  // brands.faUps,
  // brands.faUsb,
  // brands.faUsps,
  // brands.faUssunnah,
  // brands.faVaadin,
  // brands.faViacoin,
  // brands.faViadeo,
  // brands.faViadeoSquare,
  // brands.faViber,
  // brands.faVimeo,
  // brands.faVimeoSquare,
  // brands.faVimeoV,
  // brands.faVine,
  // brands.faVk,
  // brands.faVnv,
  // brands.faVuejs,
  // brands.faWatchmanMonitoring,
  // brands.faWaze,
  // brands.faWeebly,
  // brands.faWeibo,
  // brands.faWeixin,
  // brands.faWhatsapp,
  // brands.faWhatsappSquare,
  // brands.faWhmcs,
  // brands.faWikipediaW,
  // brands.faWindows,
  // brands.faWix,
  // brands.faWizardsOfTheCoast,
  // brands.faWodu,
  // brands.faWolfPackBattalion,
  // brands.faWordpress,
  // brands.faWordpressSimple,
  // brands.faWpbeginner,
  // brands.faWpexplorer,
  // brands.faWpforms,
  // brands.faWpressr,
  // brands.faXbox,
  // brands.faXing,
  // brands.faXingSquare,
  // brands.faYCombinator,
  // brands.faYahoo,
  // brands.faYammer,
  // brands.faYandex,
  // brands.faYandexInternational,
  // brands.faYarn,
  // brands.faYelp,
  // brands.faYoast,
  // brands.faYoutube,
  // brands.faYoutubeSquare,
  // brands.faZhihu,
  brands.faFacebook,
  brands.faFacebookF,
  brands.faBlogger,
  brands.faBloggerB,
  brands.faYoutube,
  brands.faYoutubeSquare,
  solid.faBlog,
  brands.faVimeo,
  brands.faVimeoSquare,
  brands.faVimeoV,
  brands.faTwitter,
  brands.faTwitterSquare,
  brands.faTiktok,
  brands.faTelegram,
  brands.faStripe,
  brands.faStripeS,
  brands.faSnapchat,
  brands.faSnapchatGhost,
  brands.faSnapchatSquare,
  brands.faMicroblog,
  brands.faWordpress,
  brands.faWordpressSimple,
];

library.add(...icons);

icons.sort((a, b) => (a.iconName > b.iconName ? 1 : -1));
