import React, { CSSProperties, ReactNode, useState } from 'react';
import './MuTable.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faCaretRight,
  faExclamationTriangle,
  faFilter,
  faPlus,
  faSave,
  faSortAlphaDown,
  faSortAlphaUp,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { MuInput, MuSelect } from 'components/input';
import { EntityRest } from 'common';
import { Tooltip } from '@mui/material';

type RenderColumn<T> = { render(row: T): ReactNode; field?: undefined } & (
  | { sortable: true; sort(a: T, b: T): number }
  | { sortable?: undefined; sort?: undefined }
) &
  (
    | { filterable: true; renderFilter(): ReactNode; filterOptions?: undefined }
    | { filterable?: undefined; filterOptions?: undefined; renderFilter?: undefined }
  );

type FieldColumn<T> = { field: keyof T; render?: undefined } & (
  | { sortable: true; sort?(a: T, b: T): number }
  | { sortable?: undefined; sort?: undefined }
) &
  (
    | { filterable: true; filterOptions?: { value: string; display: string }[]; renderFilter?(): ReactNode }
    | { filterable?: undefined; filterOptions?: undefined; renderFilter?: undefined }
  );

type Column<T> = {
  title: string;
  style?: CSSProperties;
  hideOnMobile?: boolean;
  left?: boolean;
  center?: boolean;
  right?: boolean;
  // Either render or field should be defined
} & (RenderColumn<T> | FieldColumn<T>);

function MuTableRow<T>({
  row,
  mobile,
  editable,
  columns,
  onClickRow,
  rowStyling,
  onSave,
  rest,
  renderMobileTitle,
}: {
  row: T;
  mobile?: boolean;
  editable?: boolean;
  columns: Column<T>[];
  onClickRow?(t: T): void;
  rowStyling?(row: T): CSSProperties;
  onSave?(t: T): void;
  // @ts-ignore
  rest?: EntityRest<T>;
  renderMobileTitle?(row: T): ReactNode;
}) {
  const [savable, setSavable] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saveError, setSaveError] = useState(false);
  const setKey = useState(0)[1];

  const [expanded, setExpanded] = useState(false);

  const onChange = (field: keyof T) => (value: any) => {
    row[field] = value;
    setSavable(true);
    setKey(Math.random());
  };

  async function save() {
    setSaving(true);
    setSaveError(false);
    try {
      if (rest) {
        // @ts-ignore
        const newRow = row.id ? await rest.update(row) : await rest.create(row);
        // @ts-ignore
        for (const [k, v] of Object.entries(newRow)) row[k] = v;
      }
      if (onSave) onSave(row);
      setSavable(false);
    } catch (e) {
      setSaveError(true);
    }
    setSaving(false);
  }

  if (mobile) {
    return (
      <div className='mu-table-row'>
        <div className='row-header flex' onClick={() => setExpanded(!expanded)}>
          <div className='header flex-grow'>
            {renderMobileTitle ? renderMobileTitle(row) : columns[0].render ? columns[0].render(row) : row[columns[0].field]}
          </div>
          <div className='expansion'>
            <FontAwesomeIcon icon={expanded ? faCaretDown : faCaretRight} />
          </div>
        </div>
        {expanded ? (
          <div className='row-content'>
            {columns.map((col) =>
              col.hideOnMobile ? null : (
                <div className='column' key={col.title}>
                  <div className='column-title'>{col.title}</div>
                  <div className='column-content'>
                    {col.render ? (
                      col.render(row)
                    ) : editable ? (
                      <MuInput value={row[col.field] as any} onChange={onChange(col.field)} />
                    ) : (
                      row[col.field]
                    )}
                  </div>
                </div>
              ),
            )}
            {editable ? (
              savable || saving || saveError ? (
                <div className='column'>
                  <div className='column-title'>Save</div>
                  <div className='column-content'>
                    <FontAwesomeIcon
                      icon={saveError ? faExclamationTriangle : saving ? faSpinner : faSave}
                      spin={saving && !saveError}
                      onClick={save}
                      cursor='pointer'
                    />
                  </div>
                </div>
              ) : null
            ) : null}
          </div>
        ) : null}
        <hr />
      </div>
    );
  }

  return (
    <tr onClick={() => onClickRow && onClickRow(row)} style={rowStyling && rowStyling(row)}>
      {columns.map((col, idx2) => (
        <td key={'td' + idx2} style={col.style} className={col.field && editable ? 'edit' : ''}>
          {col.render ? (
            col.render(row)
          ) : editable ? (
            <MuInput value={row[col.field] as any} onChange={onChange(col.field)} />
          ) : (
            row[col.field]
          )}
        </td>
      ))}
      {editable ? (
        <td>
          {savable || saving || saveError ? (
            <FontAwesomeIcon
              icon={saveError ? faExclamationTriangle : saving ? faSpinner : faSave}
              spin={saving && !saveError}
              onClick={save}
              cursor='pointer'
            />
          ) : null}
        </td>
      ) : null}
    </tr>
  );
}

export function MuTable<T>({
  data,
  editable,
  columns,
  renderMobileTitle,
  rowStyling,
  onClickRow,
  noneText = 'No records found',
  rest,
  onSave,
  defaultValue,
}: {
  data: T[];
  editable?: boolean;
  columns: Column<T>[];
  renderMobileTitle?(row: T): ReactNode;
  rowStyling?(row: T): CSSProperties;
  onClickRow?(row: T): void;
  onSave?(t: T): void;
  // @ts-ignore
  rest?: EntityRest<T>;
  noneText?: string;
  defaultValue?: Partial<T>;
}) {
  for (const col of columns) {
    col.style = col.style || {};
    if (col.left) col.style.textAlign = 'left';
    if (col.center) col.style.textAlign = 'center';
    if (col.right) col.style.textAlign = 'right';
  }
  const setKey = useState(0)[1];
  const [sortColumn, setSortColumn] = useState(-1);
  const [sortAsc, setSortAsc] = useState(true); // true is ascending, false is descending
  const [openFilters, setOpenFilters] = useState<{ [idx: number]: boolean }>({});
  const [filterValues, setFilterValues] = useState<{ [idx: number]: string }>({});

  function createRow() {
    data.push({ ...(defaultValue || {}) } as any);
    setKey(Math.random());
  }

  const setSort = (col: Column<T>, idx: number) => () => {
    if (!col.sortable) return;
    setSortAsc(idx === sortColumn ? !sortAsc : true);
    setSortColumn(idx);
  };

  function sort(a: T, b: T) {
    if (sortColumn === -1) return 0;
    const col = columns[sortColumn];
    if (!col.sortable) return 0;
    if (col.sort) return (sortAsc ? 1 : -1) * col.sort(a, b);
    return (sortAsc ? 1 : -1) * (a[col.field] > b[col.field] ? 1 : -1);
  }

  const openFilter = (idx: number) => () => setOpenFilters({ ...openFilters, [idx]: !openFilters[idx] });
  const setFilterValue = (idx: number) => (value: string) => setFilterValues({ ...filterValues, [idx]: value });

  function filter(x: T) {
    for (let i = 0; i < columns.length; i++) {
      const col = columns[i];
      if (col.filterable && filterValues[i] && col.field) {
        const val = x[col.field] + '';
        if (col.filterOptions && val !== filterValues[i]) return false;
        if (!val.toLowerCase().includes(filterValues[i].toLowerCase())) return false;
      }
    }
    return true;
  }

  return (
    <div className='mu-table-wrapper'>
      <div className='mu-table-mobile'>
        {data.length
          ? data.map((row, idx) => (
              <MuTableRow<T>
                row={row}
                mobile
                editable={editable}
                key={idx}
                columns={columns}
                renderMobileTitle={renderMobileTitle}
                onSave={onSave}
                rest={rest}
              />
            ))
          : noneText}
      </div>
      <table className='mu-table' cellSpacing={0}>
        <thead>
          <tr>
            {columns.map((col, idx) => (
              <th key={'th' + idx} style={col.style}>
                <span onClick={setSort(col, idx)} style={col.sortable ? { cursor: 'pointer' } : {}}>
                  {col.title}&nbsp;
                  {col.sortable ? (
                    <Tooltip title={idx !== sortColumn || sortAsc ? 'Sort Ascending' : 'Sort Descending'} enterTouchDelay={1} arrow>
                      <FontAwesomeIcon
                        icon={idx !== sortColumn || sortAsc ? faSortAlphaDown : faSortAlphaUp}
                        color={idx === sortColumn ? 'black' : 'gray'}
                      />
                    </Tooltip>
                  ) : null}
                </span>
                {col.filterable ? (
                  <Tooltip title='Filter' enterTouchDelay={1} arrow>
                    <FontAwesomeIcon icon={faFilter} onClick={openFilter(idx)} />
                  </Tooltip>
                ) : null}
                {openFilters[idx] ? (
                  <div>
                    {col.renderFilter ? (
                      col.renderFilter()
                    ) : col.filterOptions ? (
                      <MuSelect options={col.filterOptions} value={filterValues[idx]} onChange={setFilterValue(idx)} />
                    ) : (
                      <MuInput value={filterValues[idx]} onChange={setFilterValue(idx)} />
                    )}
                  </div>
                ) : null}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data
            .filter(filter)
            .sort(sort)
            .map((row, idx) => (
              <MuTableRow<T>
                row={row}
                key={'tr' + idx}
                editable={editable}
                columns={columns}
                onClickRow={onClickRow}
                rowStyling={rowStyling}
                onSave={onSave}
                rest={rest}
              />
            ))}
          {data.filter(filter).length === 0 && !editable ? (
            <tr>
              <td colSpan={columns.length}>{noneText}</td>
            </tr>
          ) : null}
          {editable ? (
            <tr>
              <td colSpan={columns.length + 1} style={{ cursor: 'pointer' }} onClick={createRow}>
                <FontAwesomeIcon icon={faPlus} /> &nbsp;Create
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
    </div>
  );
}
