import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { HTMLProps } from 'react';
import './MuButton.scss';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

interface MuButtonProps extends HTMLProps<HTMLButtonElement> {
  onClick?: () => void;
  className?: string;
  loading?: boolean;
  loadingText?: string;
  disabled?: boolean;
  children?: any;
  narrow?: boolean;
}

export const MuButton: React.FC<MuButtonProps> = ({ onClick, className, loading, loadingText, disabled, narrow, ...props }) => {
  return (
    <button
      disabled={loading || disabled}
      className={`mu-button mu-text-md-bold ${className + (narrow ? ' narrow' : '') + (loading || disabled ? ' disabled' : '')}`}
      onClick={onClick}
      {...props}
      type='button'
    >
      {loading ? (
        <React.Fragment>
          <FontAwesomeIcon icon={faSpinner} className='fa-spin' /> &nbsp; {loadingText}
        </React.Fragment>
      ) : (
        props.children
      )}
    </button>
  );
};
