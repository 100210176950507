import { DAY, HOUR, MINUTE, SECOND } from './constants';

export const now = () => new Date().getTime();

export const two = (x: number): string => x.toString().padStart(2, '0');

/**
 * Parse a date of the form 'yyyy-mm-dd'
 * @param date: a date of the form 'yyyy-mm-dd'
 */
export function parseDate(date: string): number {
  if (date === '') return 0;
  const [y, m, d] = date.split('-').map((x) => Number(x));
  return new Date(y, m - 1, d).getTime();
}

/**
 * Format a date as 'yyyy-mm-dd'
 * @param t: a JavaScript timestamp in ms
 */
export function getDate(t: number): string {
  if (t === 0) return '';
  const d = new Date(t);
  return `${d.getFullYear()}-${two(d.getMonth() + 1)}-${two(d.getDate())}`;
}

/**
 * Format the time from a timestamp as 'hh:mm'
 * @param t: a JavaScript timestamp in ms
 */
export function getTime(t: number): string {
  const d = new Date(t);
  return `${two(d.getHours())}:${two(d.getMinutes())}`;
}

export function formatDate(timestamp: number | undefined): string {
  const d = new Date(timestamp || 0);
  const hour = d.getHours() % 12 === 0 ? 12 : d.getHours() % 12;
  const am = 1 <= d.getHours() && d.getHours() <= 12 ? 'AM' : 'PM';
  return `${two(d.getMonth() + 1)}/${two(d.getDate())}/${d.getFullYear()} @${two(hour)}:${two(d.getMinutes())} ${am}`;
}

export function formatDateOnly(timestamp: number | undefined): string {
  const d = new Date(timestamp || 0);
  return `${two(d.getMonth() + 1)}/${two(d.getDate())}/${d.getFullYear()}`;
}

export function currentDate(): string {
  const d = new Date();
  return `${d.getFullYear()}-${two(d.getMonth() + 1)}-${two(d.getDate())}`;
}

export function timestamp(date: string) {
  const re1 = /^([0-9]{4})-([0-9]{1,2})-([0-9]{1,2})$/;
  if (re1.test(date)) {
    const [y, m, d] = re1
      .exec(date)!
      .slice(1, 4)
      .map((x) => parseInt(x));
    return new Date(y, m - 1, d).getTime();
  }
  const re2 = /^([0-9]{1,2})\/([0-9]{1,2})\/([0-9]{4})$/;
  if (re2.test(date)) {
    const [m, d, y] = re2
      .exec(date)!
      .slice(1, 4)
      .map((x) => parseInt(x));
    return new Date(y, m - 1, d).getTime();
  }
  return new Date(date).getTime();
}

export function getTimePast(timestamp: number) {
  const delta = (now() - timestamp) / 1000;
  const h = Math.floor(delta / 3600);
  const m = Math.floor(delta / 60) % 60;
  const s = Math.floor(delta) % 60;
  return h > 0 ? `${h}:${two(m)}:${two(s)}` : `${m}:${two(s)}`;
}

export function formatDuration(ms: number) {
  if (ms < DAY) {
    const h = Math.floor(ms / HOUR);
    const m = Math.floor(ms / MINUTE) % 60;
    const s = Math.floor(ms / SECOND) % 60;
    return `${two(h)}:${two(m)}:${two(s)}`;
  } else {
    const d = Math.floor(ms / DAY);
    const h = ((ms / HOUR) % 24).toFixed(1);
    return `${d} days, ${h} hours`;
  }
}

const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
export function formatMonthDisplay(ts: number): string {
  const d = new Date(ts);
  return `${MONTHS[d.getMonth()]} ${d.getFullYear()}`;
}
