import type { Session } from 'common';
import { formatDate } from 'common';
import { describeSchedule, nextTimestamp } from '@motivateu/schedule-strings';

const SESSION_BUFFER = 300_000;

export function isActive(session: Session): boolean {
  const now = new Date().getTime();
  if (session.timestamp) {
    const t = session.timestamp;
    return now > t - SESSION_BUFFER && t + session.duration + SESSION_BUFFER > now;
  } else if (session.schedule) {
    if (session.startTimestamp && session.startTimestamp - SESSION_BUFFER > now) {
      return false;
    }
    if (session.endTimestamp && session.endTimestamp + session.duration + SESSION_BUFFER < now) {
      return false;
    }
    const t = nextTimestamp(session.schedule, -(session.duration + SESSION_BUFFER));
    return now > t - SESSION_BUFFER && t + session.duration + SESSION_BUFFER > now;
  }
  return false;
}

export function isRecurring(sess: Session) {
  const hourAgo = new Date().getTime() - sess.duration - SESSION_BUFFER;
  if (sess.schedule) return sess.endTimestamp == null || sess.endTimestamp > hourAgo;
  return false;
}

export function isCurrentOrFuture(sess: Session) {
  const hourAgo = new Date().getTime() - sess.duration - SESSION_BUFFER;
  if (sess.timestamp != null && sess.timestamp > hourAgo) {
    return true;
  }
  return isRecurring(sess);
}

export function isCurrent(sess: Session) {
  return isCurrentOrFuture(sess) && (sess.startTimestamp == null || sess.startTimestamp <= new Date().getTime());
}

export function isPast(sess: Session) {
  const timestamp = sess.timestamp || sess.endTimestamp;
  return timestamp != null && timestamp < new Date().getTime();
}

export function describeSessionTime(sess: Session) {
  return sess.timestamp ? formatDate(sess.timestamp) : describeSchedule(sess.schedule!);
}
