export type HttpMethod = 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';
export type HTTP = (path: string, method: HttpMethod, body?: string, headers?: any) => Promise<any>;

export function createGetMethod<T>(http: HTTP, path: string): (...args: (string | number)[]) => Promise<T> {
  return (...args) => http(path + args.join('/'), 'GET');
}

export function createPostMethod<T, Q>(http: HTTP, path: string): (input: T) => Promise<Q> {
  return (input: T) => http(path, 'POST', JSON.stringify(input), { 'Content-Type': 'application/json' });
}

export class EntityRest<T extends { id: number }> {
  constructor(private http: HTTP, private path: string) {}

  get = (): Promise<T[]> => {
    return this.http(this.path, 'GET');
  };

  filter = (details: { filters?: Partial<T>; order?: string; limit?: number; offset?: number }): Promise<T[]> => {
    const parts = [];
    if (details.order) {
      parts.push(`order=${encodeURIComponent(details.order)}`);
    }
    if (details.limit) {
      parts.push(`limit=${encodeURIComponent(details.limit)}`);
    }
    if (details.offset) {
      parts.push(`offset=${encodeURIComponent(details.offset)}`);
    }
    if (details.filters) {
      for (const key of Object.keys(details.filters)) {
        parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(details.filters[key as keyof T] as any)}`);
      }
    }

    return this.http(this.path + '?' + parts.join('&'), 'GET');
  };

  getOne = (id: number): Promise<T> => {
    return this.http(`${this.path}/${id}`, 'GET');
  };

  create = (item: Partial<T>): Promise<T> => {
    return this.http(this.path, 'POST', JSON.stringify(item), { 'Content-Type': 'application/json' });
  };

  update = (item: Partial<T>): Promise<T> => {
    return this.http(`${this.path}/${item.id}`, 'PATCH', JSON.stringify(item), { 'Content-Type': 'application/json' });
  };

  deleteRecord = (id: number): Promise<string> => {
    return this.http(`${this.path}/${id}`, 'DELETE');
  };
}
