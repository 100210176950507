import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { HTMLProps, useRef, useState } from 'react';
import './MuInput.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { MuInfoButton } from '../MuInfoButton/MuInfoButton';

interface SharedProps {
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  errorMessage?: string;
  className?: string;
  helpInfo?: string;
  iconBefore?: IconProp;
  iconAfter?: IconProp;
  iconBeforeColor?: string;
  iconAfterColor?: string;
  iconBeforeBackgroundColor?: string;
  iconAfterBackgroundColor?: string;
  onIconBeforeClick?: () => void;
  onIconAfterClick?: () => void;
  required?: boolean;
  noMargin?: boolean;
}

type InputBaseType = Omit<HTMLProps<HTMLInputElement>, 'onBlur' | 'onChange'> & SharedProps;

export interface MuInputPropsString extends InputBaseType {
  type?: string;
  value: string;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  min?: undefined;
  max?: undefined;
}

interface MuInputPropsNumber extends InputBaseType {
  type: 'number' | 'range';
  value: number;
  onChange?: (value: number) => void;
  onBlur?: (value: number) => void;
  min?: number;
  max?: number;
}

export const MuInput: React.FC<MuInputPropsString | MuInputPropsNumber> = ({
  label,
  type = 'text',
  value,
  placeholder,
  disabled = false,
  onChange,
  onBlur,
  errorMessage,
  className = '',
  helpInfo,
  min,
  max,
  iconBefore,
  iconAfter,
  iconBeforeColor = '#ffffff',
  iconAfterColor = '#ffffff',
  iconBeforeBackgroundColor = '#235bd6',
  iconAfterBackgroundColor = '#235bd6',
  onIconBeforeClick,
  onIconAfterClick,
  required,
  noMargin,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [defaultZero, setDefaultZero] = useState(type === 'number' && value === 0);

  if (required && !value && (defaultZero || value !== 0)) errorMessage = errorMessage || 'Required';

  return (
    <div className={`mu-input ${noMargin ? 'no-margin' : ''}`}>
      {label ? (
        <span className='mu-input-label mu-text-md-bold'>
          {label} &nbsp;
          {helpInfo ? <MuInfoButton title={helpInfo} /> : null}
        </span>
      ) : null}
      <div className='mu-input-line'>
        {iconBefore ? (
          <FontAwesomeIcon
            className='icon-before'
            icon={iconBefore}
            onClick={onIconBeforeClick || (() => inputRef.current?.focus())}
            style={{ color: iconBeforeColor, backgroundColor: iconBeforeBackgroundColor }}
          />
        ) : null}
        <input
          ref={inputRef}
          type={type}
          value={defaultZero && value === 0 ? '' : value}
          placeholder={placeholder}
          disabled={disabled}
          {...props}
          onChange={
            onChange &&
            ((event) => {
              setDefaultZero(event.target.value === '');
              onChange(['number', 'range'].includes(type) ? (Number(event.target.value) as never) : (event.target.value as never));
            })
          }
          onBlur={onBlur && (() => onBlur(value as never))}
          min={min}
          max={max}
          className={`${errorMessage ? 'error' : ''} ${className}`}
        />
        {iconAfter ? (
          <FontAwesomeIcon
            className='icon-after'
            icon={iconAfter}
            onClick={onIconAfterClick || (() => inputRef.current?.focus())}
            style={{ color: iconAfterColor, backgroundColor: iconAfterBackgroundColor }}
          />
        ) : null}
      </div>
      {errorMessage ? (
        <span className='mu-input-error-message mu-text-sm'>
          {errorMessage.split('\n').map((line, idx) => (
            <React.Fragment key={idx}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </span>
      ) : null}
    </div>
  );
};
