const namedVars: { [x: string]: any } = { true: true, false: false, null: null, undefined: undefined };

export function parseFormEncode(value: string): any {
  const hash: any = {};
  for (const part of value.split('&')) {
    if (part) {
      if (part.includes('=')) {
        const [k, v] = part.split('=');
        if (!isNaN(Number(v))) hash[k] = Number(v);
        else if (v in namedVars) hash[k] = namedVars[v];
        else hash[k] = v;
      } else {
        hash[part] = true;
      }
    }
  }
  return hash;
}

export function getHash(): any {
  return parseFormEncode(window.location.hash.substr(1));
}

export function getQueryString(): any {
  return parseFormEncode(window.location.search.substr(1));
}

export function formEncode(value: any): string {
  return Object.entries(value)
    .map((v) => v.join('='))
    .join('&');
}
