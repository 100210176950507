import { useEffect, useState } from 'react';
import { useEffectAsync } from './misc';
import * as events from 'events';

export const invokeUrl = process.env.REACT_APP_API_URL || 'https://dev-api.motivateu-dev.net';

export async function getToken(): Promise<string | null> {
  if (localStorage.customTokenExpiration < new Date().getTime()) await refreshToken();
  return localStorage.customIdToken || null;
}

const tokenChangers: ((token: string | null) => void)[] = [];

export function handleTokenChange(cb: (token: string | null) => void) {
  tokenChangers.push(cb);
}

function updateToken(token: string | null) {
  for (const cb of tokenChangers) cb(token);
}

let refreshTokenLock = false;
let eventEmitter = new events.EventEmitter();

async function refreshToken(cnt = 5): Promise<any> {
  if (cnt <= 0) throw new Error('Could not refresh token');
  if (refreshTokenLock) return new Promise((resolve) => eventEmitter.on('refreshed', resolve));
  refreshTokenLock = true;

  try {
    const resp = await fetch(`${invokeUrl}/refresh-token`, {
      method: 'POST',
      body: JSON.stringify({ idToken: localStorage.customIdToken, refreshToken: localStorage.customRefreshToken }),
      headers: { 'Content-Type': 'application/json' },
    });
    if (resp.status !== 200) return setTimeout(() => refreshToken(cnt - 1), 1_000);

    const token: string | null = await resp.json();

    if (token) {
      localStorage.customIdToken = token;
      localStorage.customTokenExpiration = new Date().getTime() + 3_595_000;
      localStorage.customTokenRefresh = new Date().getTime() + 3_500_000;
      setTimeout(refreshToken, 3_500_000);
    } else {
      delete localStorage.customIdToken;
      delete localStorage.customTokenExpiration;
      delete localStorage.customTokenRefresh;
      delete localStorage.customRefreshToken;
    }

    updateToken(token);
    refreshTokenLock = false;
    eventEmitter.emit('refreshed');
  } catch (e) {
    refreshTokenLock = false;
    setTimeout(refreshToken, 1_000);
    return new Promise((resolve) => eventEmitter.on('refreshed', resolve));
  }
}

function getCognitoExpiration(key: string, user: string): number {
  const token = localStorage.customIdToken;
  const drift = Number(localStorage[`CognitoIdentityServiceProvider.${key}.${user}.clockDrift`]) || 0;
  const expiration = JSON.parse(atob(token.split('.')[1])).exp + drift;
  return expiration * 1000;
}

for (const key of ['21kfd6bf6obnun95rmo29066fi', '4id39ctuilaqsc3vf8akhue8l0']) {
  const user = localStorage[`CognitoIdentityServiceProvider.${key}.LastAuthUser`];
  if (user) {
    localStorage.customIdToken = localStorage[`CognitoIdentityServiceProvider.${key}.${user}.idToken`];
    localStorage.customRefreshToken = localStorage[`CognitoIdentityServiceProvider.${key}.${user}.refreshToken`];
    localStorage.customTokenExpiration = getCognitoExpiration(key, user) - 5_000;
    localStorage.customTokenRefresh = localStorage.customTokenExpiration - 95_000;
    delete localStorage[`CognitoIdentityServiceProvider.${key}.LastAuthUser`];
    delete localStorage[`CognitoIdentityServiceProvider.${key}.${user}.idToken`];
    delete localStorage[`CognitoIdentityServiceProvider.${key}.${user}.accessToken`];
    delete localStorage[`CognitoIdentityServiceProvider.${key}.${user}.refreshToken`];
  }
}

const now = new Date().getTime();
if (localStorage.customTokenRefresh > now) setTimeout(refreshToken, localStorage.customTokenRefresh - now);
else if (localStorage.customTokenRefresh) setTimeout(refreshToken, 0);
else delete localStorage.customIdToken;

export async function initiatePasswordlessLogin(email: string): Promise<{ error?: boolean; valid: boolean; requirePassword: boolean }> {
  try {
    const resp = await fetch(`${invokeUrl}/initiate-passwordless-login`, {
      method: 'POST',
      body: JSON.stringify({ email }),
      headers: { 'Content-Type': 'application/json' },
    });
    return resp.json();
  } catch (e) {
    return { error: true, valid: false, requirePassword: false };
  }
}

async function login(url: string, body: any): Promise<boolean> {
  let res;
  try {
    const resp = await fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json' } });
    res = await resp.json();
  } catch (e) {
    return false;
  }

  if (res.complete) throw new Error('This link has already been used to log in.');
  if (res.invalid) throw new Error('Invalid link');
  if (res.expired) throw new Error('This link has expired. Please try again.');
  if (res.invalidPass) throw new Error('Invalid username or password');
  if (res.tooManyAttempts) throw new Error('Too many login attempts recently. Please try again later.');

  localStorage.customIdToken = res.idToken;
  localStorage.customRefreshToken = res.refreshToken;
  localStorage.customTokenExpiration = new Date().getTime() + 3_595_000;
  localStorage.customTokenRefresh = new Date().getTime() + 3_500_000;
  updateToken(res.idToken);
  setTimeout(refreshToken, 3_500_000);
  return true;
}

export async function loginPassword(email: string, password: string): Promise<boolean> {
  return await login(`${invokeUrl}/login-password`, { email, password });
}

export async function loginPasswordless(code: string): Promise<boolean> {
  return await login(`${invokeUrl}/login-passwordless`, { code });
}

export function useToken(): string | null | undefined {
  const [token, setToken] = useState<string | null>();
  useEffectAsync(async () => setToken(await getToken()), []);
  useEffect(() => handleTokenChange(setToken));
  return token;
}
