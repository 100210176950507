import { english_strings } from './strings/english';
import React, { useEffect, useState } from 'react';
import { spanish_strings } from './strings/spanish';

const strings: { [x: string]: { [x: string]: string | null | ((x: any) => string) } } = {
  en: english_strings,
  'en-US': english_strings,
  es: spanish_strings,
};

//@ts-ignore
const getLanguages = () => [...navigator.languages, navigator.language, navigator.userLanguage, 'en'];

const updaters: ((langauges: string[]) => void)[] = [];
window.onlanguagechange = () => {
  for (const updater of updaters) updater(getLanguages());
};

export function useLanguages() {
  const [languages, setLanguages] = useState(getLanguages());
  useEffect(() => {
    updaters.push(setLanguages);
  }, []);
  return languages;
}

export function useString(name: keyof typeof english_strings, params?: any): string {
  const languages = useLanguages();
  for (const language of languages)
    if (language in strings && strings[language][name]) {
      if (typeof strings[language][name] === 'function') return (strings[language][name] as (x: any) => string)(params);
      return strings[language][name] as string;
    }
  console.error('Unknown i18n string');
  return '';
}

interface StringProps {
  name: keyof typeof english_strings;
  params?: any;
  lower?: boolean;
}

export const String: React.FC<StringProps> = ({ name, params, lower }) => {
  let value = useString(name, params);
  if (lower) value = value.toLowerCase();
  return <span>{value}</span>;
};
