import type { User, UserWithGym } from 'common';
import { MotivateU } from './util';
import { useState } from 'react';
import { useEffectAsync } from './util/';

const userChangers: ((user: User) => void)[] = [];

export async function updateUser(changes: Partial<User>) {
  const existingUser = await MotivateU.getUserInfo();
  const user = await MotivateU.api.users.update({ id: existingUser.id, ...changes });
  MotivateU.updateUserCache({ ...user, gym: existingUser.gym });
  for (const ch of userChangers) {
    ch(user);
  }
}

export function useUser(): UserWithGym | undefined {
  const [user, setUser] = useState<UserWithGym | undefined>(MotivateU.getUserInfoStatic());
  userChangers.push(setUser);
  useEffectAsync(async () => {
    if (!user) setUser(await MotivateU.getUserInfo());
  }, []);
  return user;
}

export function useMetric(): boolean {
  const user = useUser();
  return user?.metric || false;
}

class Formatter {
  unit: string;
  metric: boolean;

  constructor(unit: string, metric: boolean) {
    this.unit = unit;
    this.metric = metric;
  }

  formatUnit(): string {
    if (!this.metric) {
      if (this.unit === 'kg') return 'lb';
      if (this.unit === 'm') return 'ft';
    } else if (this.unit === 'm') return 'cm';
    return this.unit;
  }

  formatValueDisplay(value?: number): number | undefined {
    if (!this.metric && value) {
      if (this.unit === 'kg') return Math.round(value * 2.20462 * 10) / 10;
      if (this.unit === 'm') return Math.round(value * 3.28084 * 10) / 10;
    } else if (this.unit === 'm' && value) return Math.round(value * 100 * 10) / 10;
    if (value) return Math.round(value * 10) / 10;
    return value;
  }

  formatValueStore(value?: number): number | undefined {
    if (!this.metric && value) {
      if (this.unit === 'kg') return value / 2.20462;
      if (this.unit === 'm') return value / 3.28084;
    } else if (this.unit === 'm' && value) return value / 100;
    return value;
  }
}

export function useFormatter(unit: string) {
  const metric = useMetric();
  return new Formatter(unit, metric);
}

export enum ConsumerAppVersion {
  NONE,
  FREE,
  PREMIUM,
  PRO,
  PERFORMANCE,
  PLATINUM,
  PERFORMANCE_PLUS
}

export const CONSUMER_VERSION_MAP = {
  FREE: ConsumerAppVersion.FREE,
  PREMIUM: ConsumerAppVersion.PREMIUM,
  PRO: ConsumerAppVersion.PRO,
  PERFORMANCE: ConsumerAppVersion.PERFORMANCE,
  PLATINUM: ConsumerAppVersion.PLATINUM,
  PERFORMANCE_PLUS: ConsumerAppVersion.PERFORMANCE_PLUS
};

function interpretVersion(user: User): ConsumerAppVersion {
  if (!user.consumerSub) return ConsumerAppVersion.FREE;
  return CONSUMER_VERSION_MAP[user.consumerVer];
}

export function useConsumerAppVersion(): ConsumerAppVersion {
  const user = MotivateU.getUserInfoStatic();
  const [version, setVersion] = useState<ConsumerAppVersion>(user ? interpretVersion(user) : ConsumerAppVersion.FREE);

  useEffectAsync(async () => {
    if (!user) {
      setVersion(interpretVersion(await MotivateU.getUserInfo()));
    }
  }, []);

  return version;
}
