import React, { ReactElement, useEffect, useRef, useState } from 'react';
import './UserPhoto.scss';
import { ENVIRONMENT } from 'common';
import { Tooltip } from '@mui/material';

interface UserPhotoProps {
  user?: { id: number; name: string; hasImage: boolean };
  date?: number;
  noTooltip?: boolean;
}

const ConditionalTooltip: React.FC<{ show: boolean; title: string; children: ReactElement }> = ({ show, title, children }) => {
  if (show) return <Tooltip title={title}>{children}</Tooltip>;
  return <>{children}</>;
};

const UserPhotoInitials: React.FC<UserPhotoProps> = ({ user, date, noTooltip }) => {
  const initials = user
    ? user.name
        .split(' ')
        .map((x) => x[0])
        .slice(0, 2)
        .join('')
    : '';

  return (
    <ConditionalTooltip show={!noTooltip} title={user?.name || ''}>
      <div className='user-photo'>
        <span className='initials'>{initials}</span>
      </div>
    </ConditionalTooltip>
  );
};

const UserPhotoPhoto: React.FC<UserPhotoProps> = ({ user, date, noTooltip }) => {
  const [displayImage, setDisplayImage] = useState(true);
  const [inView, setInView] = useState(false);
  const div = useRef<HTMLDivElement>(null);

  const initials = user
    ? user.name
        .split(' ')
        .map((x) => x[0])
        .slice(0, 2)
        .join('')
    : '';

  function setVisible() {
    if (!user?.hasImage) return;
    if (!div.current) return setInView(false);
    const { top } = div.current.getBoundingClientRect();
    setInView(top > 0 && top < window.outerHeight);
  }

  function listenScroll(elem: HTMLElement) {
    if (!user?.hasImage) return;
    elem.addEventListener('scroll', setVisible);
    if (elem.parentElement) listenScroll(elem.parentElement);
  }

  useEffect(setVisible, [user, div.current]);
  useEffect(() => {
    if (div.current) listenScroll(div.current);
  }, [user, div.current]);

  return (
    <ConditionalTooltip show={!noTooltip} title={user?.name || ''}>
      <div className='user-photo' ref={div}>
        <span className='initials'>{initials}</span>
        {displayImage && inView ? (
          <img
            src={`https://cdn.motivateu-dev.net/profile-photos/${ENVIRONMENT}/${user?.id}.jpg${date ? `?${date}` : ''}`}
            alt={`${user?.name}'s profile photo`}
            onError={() => setDisplayImage(false)}
          />
        ) : null}
      </div>
    </ConditionalTooltip>
  );
};

export const UserPhoto: React.FC<UserPhotoProps> = ({ user, date, noTooltip }) => {
  if (user?.hasImage) return <UserPhotoPhoto user={user} date={date} noTooltip={noTooltip} />;
  else return <UserPhotoInitials user={user} date={date} noTooltip={noTooltip} />;
};
