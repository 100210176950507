import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

export const MuInfoButton: React.FC<{ title: string }> = ({ title }) => {
  return (
    <Tooltip title={title} enterTouchDelay={1} arrow>
      <IconButton style={{ padding: 4 }}>
        <InfoIcon style={{ fontSize: 16 }} />
      </IconButton>
    </Tooltip>
  );
};
