import React, { ReactNode, useEffect, useRef, useState } from 'react';

import './header.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { getPreload, MotivateU, String, trackEvent, useEffectAsync, useUser } from 'services';
import { CONSUMER_TYPES, ConsumerVersion, ENVIRONMENT, USER_TYPES, UserType } from 'common';
import { MuChatbot, MuSelect, UserPhoto } from '../../input';
import ReactModal from 'react-modal';
import { Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from '@mui/material';
import { Chat, ContactSupport, Logout, Notifications, Settings } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { MuLogo } from 'components/page/MuLogo/MuLogo';

export const PaperProps = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0
    }
  }
};

const ChooseUser: React.FC<{ onClose(): void }> = ({ onClose }) => {
  const [rawType, setRawType] = useState<UserType>('CONSUMER');
  const [rawConsumerType, setRawConsumerType] = useState<ConsumerVersion>('FREE');
  const user = useUser();

  useEffectAsync(async () => {
    const user = await MotivateU.api.getUserInfo();
    setRawType(user.userType);
    setRawConsumerType(user.consumerVer);
  }, []);

  function setUserType(type: string) {
    localStorage.overrideUserType = type;
    window.location.reload();
  }

  function setConsumerType(type: string) {
    localStorage.overrideConsumerType = type;
    window.location.reload();
  }

  function getUserTypes() {
    const types: { value: string; display: string }[] = [];
    for (const type of USER_TYPES)
      if (USER_TYPES.indexOf(type) <= USER_TYPES.indexOf(rawType))
        types.push({
          value: type,
          display: type
        });
    return types;
  }

  function getConsumerTypes() {
    const types: { value: string; display: string }[] = [];
    for (const type of CONSUMER_TYPES)
      if (CONSUMER_TYPES.indexOf(type) <= CONSUMER_TYPES.indexOf(rawConsumerType))
        types.push({
          value: type,
          display: type
        });
    return types;
  }

  return (
    <div>
      <h3>Choose User Account</h3>
      {/* TODO: list user accounts */}
      {!['CLIENT', 'CONSUMER'].includes(rawType) ? (
        <MuSelect value={user?.userType} options={getUserTypes()} onChange={setUserType} />
      ) : null}
      {rawType === 'ADMIN' ? <MuSelect value={user?.consumerVer} options={getConsumerTypes()} onChange={setConsumerType} /> : null}
    </div>
  );
};

interface IconWrapperProps {
  title: string;
  count?: number;
  content: ReactNode;

  onClick(event: React.MouseEvent<HTMLElement>): void;

  onRightClick?(event: React.MouseEvent<HTMLElement>): void;
}

const IconWrapper: React.FC<IconWrapperProps> = ({ title, onClick, count, content, onRightClick }) => {
  return (
    <div className='icon-wrapper'>
      {count ? <div className='count'>{count}</div> : null}
      <Tooltip title={title}>
        <IconButton onClick={onClick} onContextMenu={onRightClick} size='small' sx={{ ml: 2 }}>
          {content}
        </IconButton>
      </Tooltip>
    </div>
  );
};

interface MUHeaderProps {
  menu: boolean;

  setMenu(val: boolean): void;
}

export const MUHeader: React.FC<MUHeaderProps> = ({ menu, setMenu }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [email, setEmail] = useState('');
  const [unreadChatCount, setUnreadChatCount] = useState(0);
  const [notificationCount, setNotificationCount] = useState(0);
  const [chooseUser, setChooseUser] = useState(false);
  const lastChatCount = useRef(0);
  const [accountMenuEl, setAccountMenuEl] = useState<null | HTMLElement>(null);
  const [chatbotEl, setChatbotEl] = useState<null | HTMLElement>(null);
  const accountMenuOpen = Boolean(accountMenuEl);
  const user = useUser();
  const history = useHistory();

  useEffectAsync(async () => {
    const user = await MotivateU.getUserInfo();
    setEmail(user.email);
    setIsLoggedIn(true);
  }, []);

  useEffect(() => {
    setInterval(async () => {
      const chatCount = await MotivateU.api.getUnreadChatCount();
      if (chatCount > lastChatCount.current) {
        const audio = new Audio('/assets/notification.mp3');
        audio.play().catch(() => 0);
      }
      lastChatCount.current = chatCount;
      setUnreadChatCount(chatCount);
    }, 15_000);
    setInterval(async () => setNotificationCount(await MotivateU.api.getNotificationCount()), 900_000);
  }, []);

  useEffectAsync(async () => {
    const preload = await getPreload();
    lastChatCount.current = preload.unreadChatCount;
    setUnreadChatCount(preload.unreadChatCount);
    setNotificationCount(preload.unreadNotificationCount);
  }, []);

  function accountSettings() {
    history.push('/account-settings');
    setMenu(false);
  }

  async function logout() {
    trackEvent('Auth', 'signout', email);
    try {
      await MotivateU.api.signout({ refreshToken: localStorage.customRefreshToken });
    } catch (e) {}
    delete localStorage.customIdToken;
    delete localStorage.customTokenExpiration;
    delete localStorage.customTokenRefresh;
    delete localStorage.customRefreshToken;
    window.location.pathname = '/';
    setMenu(false);
  }

  function profile() {
    history.push('/profile');
    setMenu(false);
  }

  function messages() {
    history.push('/chat');
    setMenu(false);
  }

  function notifications() {
    history.push('/notifications');
    setMenu(false);
  }

  const openAccountMenu = (event: React.MouseEvent<HTMLElement>) => setAccountMenuEl(event.currentTarget);
  const closeAccountMenu = () => setAccountMenuEl(null);

  const openChatbot = (event: React.MouseEvent<HTMLElement>) => setChatbotEl(event.currentTarget);
  const closeChatbot = () => setChatbotEl(null);

  return (
    <div className='mu-header-desktop'>
      <div className={'mu-header__inner ' + ENVIRONMENT}>
        <div id='menu-toggle' onClick={() => setMenu(!menu)}>
          <FontAwesomeIcon icon={faBars} />
        </div>
        <a href='/' className='logo'>
          <MuLogo />
        </a>
        <div className='flex-space' />
        {ENVIRONMENT === 'prod' ? null : (
          <>
            <div className='environment'>{ENVIRONMENT.toUpperCase()}</div>
            <div className='flex-space' />
          </>
        )}
        {isLoggedIn ? (
          <>
            {!['CONSUMER', 'CLIENT'].includes(user?.userType || '') ? (
              <IconWrapper title='Chatbot' onClick={openChatbot} content={<ContactSupport />} />
            ) : null}
            <IconWrapper title='Notifications' onClick={notifications} count={notificationCount} content={<Notifications />} />
            <IconWrapper title='Chat' onClick={messages} count={unreadChatCount} content={<Chat />} />
            <IconWrapper
              title='Account'
              onClick={openAccountMenu}
              onRightClick={(event) => {
                setChooseUser(true);
                event.preventDefault();
              }}
              content={<UserPhoto user={user} noTooltip />}
            />
            <Menu
              anchorEl={chatbotEl}
              id='chatbot-menu'
              open={!!chatbotEl}
              onClose={closeChatbot}
              PaperProps={PaperProps}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MuChatbot />
            </Menu>
            <Menu
              anchorEl={accountMenuEl}
              id='account-menu'
              open={accountMenuOpen}
              onClose={closeAccountMenu}
              onClick={closeAccountMenu}
              PaperProps={PaperProps}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem onClick={profile}>
                <UserPhoto user={user} noTooltip /> &nbsp; <String name='profile' />
              </MenuItem>

              <Divider />

              <MenuItem onClick={accountSettings}>
                <ListItemIcon>
                  <Settings fontSize='small' />
                </ListItemIcon>
                <String name='account_settings' />
              </MenuItem>

              <MenuItem onClick={logout}>
                <ListItemIcon>
                  <Logout fontSize='small' />
                </ListItemIcon>
                <String name='logout' />
              </MenuItem>
            </Menu>
          </>
        ) : null}
      </div>
      <ReactModal isOpen={chooseUser} onRequestClose={() => setChooseUser(false)}>
        <ChooseUser onClose={() => setChooseUser(false)} />
      </ReactModal>
    </div>
  );
};
