import React, { ReactNode, useEffect, useRef, useState } from 'react';
import './MuAutocomplete.scss';
import { MuLoading } from 'components/input';
import { useEffectAsync } from 'services';
import { MuInput, MuInputPropsString } from '../MuInput/MuInput';

interface MuAutocompleteProps extends Omit<MuInputPropsString, 'value'> {
  defaultSearchText?: string;
  minLength?: number;
  onSearch: (searchText: string) => Promise<any[]>;
  render: (result: any) => ReactNode;
  onSelect?: (result: any) => void;
}

export const MuAutocomplete: React.FC<MuAutocompleteProps> = ({
  defaultSearchText = '',
  minLength = 3,
  onSearch,
  render,
  onSelect,
  ...inputProps
}) => {
  const [searchText, setSearchText] = useState(defaultSearchText);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<any[]>([]);
  const [changed, setChanged] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const open = searchText.length >= minLength && changed;
  const wrapper = useRef<HTMLDivElement>(null);
  const resultsDiv = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSearchText(defaultSearchText);
  }, [defaultSearchText]);

  useEffectAsync(async () => {
    if (searchText.length >= minLength) {
      setLoading(true);
      setResults(await onSearch(searchText));
      setLoading(false);
    }
  }, [searchText]);

  function hover(idx: number) {
    setHoveredIndex(idx);
    if (resultsDiv.current && idx >= 0) {
      const top = resultsDiv.current.getBoundingClientRect().y;
      const hoverTop = resultsDiv.current.children[idx].getBoundingClientRect().y;
      resultsDiv.current.parentElement?.scrollTo(0, hoverTop - top);
    }
  }

  inputProps.onFocus = () => (minLength === 0 ? setChanged(true) : undefined);
  inputProps.onKeyDown = (event) => {
    if (event.key === 'ArrowUp') hover(Math.max(-1, hoveredIndex - 1));
    else if (event.key === 'ArrowDown') hover(Math.min(results.length - 1, hoveredIndex + 1));
    else if (event.key === 'Enter' && hoveredIndex >= 0 && hoveredIndex < results.length) {
      if (onSelect) {
        setSearchText(defaultSearchText);
        setChanged(false);
        onSelect(results[hoveredIndex]);
      }
    }
  };

  return (
    <div className='mu-autocomplete-wrapper' ref={wrapper} style={inputProps.noMargin ? { margin: 0 } : {}}>
      <div className={'mu-autocomplete' + (open ? ' open' : '')} style={{ width: wrapper.current?.getBoundingClientRect().width }}>
        <MuInput
          noMargin
          value={searchText}
          onChange={(e: string) => {
            setChanged(true);
            setSearchText(e);
          }}
          {...inputProps}
        />
        {open ? (
          <div className='results'>
            {loading ? (
              <MuLoading />
            ) : results.length === 0 ? (
              <div className='no-results'>No results found</div>
            ) : (
              <div className='search-results' ref={resultsDiv}>
                {results.map((result, idx) => (
                  <div
                    className={'search-result' + (hoveredIndex === idx ? ' hovered' : '')}
                    key={idx}
                    onClick={
                      onSelect &&
                      (() => {
                        setSearchText(defaultSearchText);
                        setChanged(false);
                        onSelect(result);
                      })
                    }
                    onMouseOver={() => setHoveredIndex(idx)}
                  >
                    {render(result)}
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};
