import './MuSelect.scss';
import React from 'react';
import { MuInfoButton } from '../MuInfoButton/MuInfoButton';

interface MuSelectProps {
  label?: string;
  value: any;
  options: { value: any; display: string }[];
  onChange: (value: any) => void;
  errorMessage?: string;
  className?: string;
  helpInfo?: string;
  required?: boolean;
  noMargin?: boolean;
}

export const MuSelect: React.FC<MuSelectProps> = ({
  label,
  value,
  options,
  onChange,
  errorMessage,
  className,
  helpInfo,
  required,
  noMargin,
}) => {
  if (required && !value) errorMessage = errorMessage || 'Required';

  return (
    <div className={`mu-select ${noMargin ? 'no-margin' : ''}`}>
      {label ? (
        <span className='mu-input-label mu-text-md-bold'>
          {label} &nbsp;
          {helpInfo ? <MuInfoButton title={helpInfo} /> : null}
        </span>
      ) : null}
      <select
        value={value}
        onChange={(event) => onChange(typeof value === 'number' ? Number(event.target.value) : event.target.value)}
        className={`mu-select ${className} ${errorMessage ? 'error' : ''}`}
      >
        {options.map((option, idx) => (
          <option value={option.value} key={option.value + '|' + idx}>
            {option.display}
          </option>
        ))}
      </select>
      {errorMessage ? (
        <span className='mu-input-error-message mu-text-sm'>
          {errorMessage.split('\n').map((line, idx) => (
            <React.Fragment key={idx}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </span>
      ) : null}
    </div>
  );
};
