import React, { ComponentType, lazy, Suspense, useEffect, useState } from 'react';
import './App.scss';
import './theme/variables.scss';
import * as ReactRouterDom from 'react-router-dom';
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';
import { MuLoading } from 'components/input';
import { MUHeader } from 'components/page/Header/header';
import { MUMenu } from 'components/page/Menu/menu';
import { TourWrapper } from 'components/page/TourWrapper/TourWrapper';
import { Home } from './pages/general/Home/home';
import { MotivateU, trackEvent, useToken, useUser } from 'services';
import ReactModal from 'react-modal';
import { ErrorBoundary } from 'react-error-boundary';
import { LoginPage } from './pages/general/LoginPage/LoginPage';

ReactModal.setAppElement(document.getElementById('root')!);

const ErrorHandler: React.FC<{ error: Error; resetErrorBoundary(): void }> = ({ error, resetErrorBoundary }) => {
  const user = useUser();
  const location = useLocation();
  const [originalLocation, _] = useState(location);

  useEffect(() => {
    if (location !== originalLocation) resetErrorBoundary();
  }, [location]);

  useEffect(() => {
    MotivateU.api.recordFrontendError({ url: window.location.pathname, error: error.message + '\n' + error.stack }).then(() => {});
    trackEvent(
      'Error',
      window.location.pathname,
      JSON.stringify({
        error: error.message,
        stack: error.stack,
        url: window.location.href,
        user: user?.email
      })
    );
  }, [error]);

  return (
    <div className='page'>
      <div className='page-title'>Error</div>
      <div>There was an error on this page. It has been recorded and our engineers are working on fixing it.</div>
    </div>
  );
};

let lastUrl: string | null = window.location.pathname;

export function logActions() {
  window.setInterval(() => {
    const currentUrl = window.location.pathname;
    if (lastUrl !== currentUrl) {
      MotivateU.api.userActions
        .create({
          actionType: 'PAGE_VIEW',
          details: JSON.stringify({ url: currentUrl })
        })
        .then(() => {});
    }
    lastUrl = currentUrl;
  }, 1000);
}

const fallbackPage = (
  <div className='page'>
    <MuLoading />
  </div>
);

const fallbackRoot = (
  <div style={{ display: 'flex', width: '100vw', height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
    <MuLoading />
  </div>
);

interface Page {
  url: string;
  key: string;

  component(): Promise<{ default: ComponentType<any> }>;
}

const pages: Page[] = [
  { url: '/profile', component: () => import('./pages/general/Profile/Profile'), key: 'profile' },
  { url: '/connect-friend/:friendCode', component: () => import('./pages/general/Profile/ConnectFriend'), key: 'connect-friend' },
  { url: '/insights', component: () => import('./pages/general/Insights/Insights'), key: 'insights' },
  { url: '/instructor/manage-users', component: () => import('./pages/instructor/ManageUsers/ManageUsers'), key: 'inst-mg-users' },
  { url: '/instructor/manage-sessions', component: () => import('./pages/instructor/ManageSessions/ManageSessions'), key: 'inst-mg-sess' },
  { url: '/instructor/manage-session', component: () => import('./pages/instructor/ManageSession/ManageSession'), key: 'inst-mg-ses' },
  { url: '/instructor/manage-session/:id', component: () => import('./pages/instructor/ManageSession/ManageSession'), key: 'inst-mg-ses2' },
  { url: '/instructor/accept-clients', component: () => import('./pages/instructor/AcceptClients/AcceptClients'), key: 'inst-acc-client' },
  { url: '/instructor/manage-assessments', component: () => import('./pages/instructor/ManageAssessments/ManageAssessments'), key: 'mg-a' },
  { url: '/employer/manage-users', component: () => import('./pages/employer/ManageUsers/ManageEmployees'), key: 'manage-employees' },
  { url: '/instructor/video-session/:id', component: () => import('./pages/instructor/VideoSession/VideoSession'), key: 'inst-vid-sess' },
  { url: '/schedule', component: () => import('./pages/instructor/Schedule/Schedule'), key: 'schedule' },
  { url: '/video-library', component: () => import('./pages/instructor/VideoLibrary/VideoLibrary'), key: 'video-library' },
  { url: '/client/sessions', component: () => import('./pages/client/ClassList/ClassList'), key: 'client-sessions' },
  { url: '/client/join-session/:id', component: () => import('./pages/client/JoinClass/JoinClass'), key: 'client-join-session' },
  { url: '/class-stats', component: () => import('./pages/general/WorkoutResults/WorkoutResults'), key: 'class-stats' },
  { url: '/class-stats/:id', component: () => import('./pages/general/WorkoutResults/WorkoutResults'), key: 'class-stats2' },
  { url: '/client-goals/:id', component: () => import('./pages/instructor/ClientGoals/ClientGoals'), key: 'client-goals' },
  { url: '/payments', component: () => import('./pages/general/Payments/Payments'), key: 'payments' },
  { url: '/manage-payments', component: () => import('./pages/instructor/ManagePayments/ManagePayments'), key: 'manage-payments' },
  { url: '/recording-list', component: () => import('./pages/client/RecordingList/RecordingList'), key: 'recording-list' },
  { url: '/challenges', component: () => import('./pages/client/RecordingList/RecordingList'), key: 'challenges' },
  { url: '/goals', component: () => import('./pages/consumer/GoalsWrapper/GoalsWrapper'), key: 'goals' },
  { url: '/leaderboard', component: () => import('./pages/general/Leaderboard/Leaderboard'), key: 'leaderboard' },
  { url: '/assessments', component: () => import('./pages/consumer/Assessments/Assessments'), key: 'assessments' },
  { url: '/manage-trainers', component: () => import('./pages/owner/ManageTrainers/ManageTrainers'), key: 'manage-trainers' },
  { url: '/admin', component: () => import('./pages/admin/Admin/Admin'), key: 'admin' },
  { url: '/rewards', component: () => import('./pages/consumer/Rewards/Rewards'), key: 'rewards' },
  { url: '/chat', component: () => import('./pages/general/Chat/Chat'), key: 'chat' },
  { url: '/setup-consulting', component: () => import('./pages/admin/Consulting/SetupConsulting'), key: 'setup-consulting' },
  { url: '/connect-to-trainer', component: () => import('./pages/consumer/ConnectToTrainer/ConnectToTrainer'), key: 'connect-to-trainer' },
  { url: '/notifications', component: () => import('./pages/general/Notifications/Notifications'), key: 'notifications' },
  { url: '/signups', component: () => import('./pages/instructor/Signups/Signups'), key: 'signups' },
  { url: '/manage-gyms', component: () => import('./pages/owner/ManageGyms/ManageGyms'), key: 'manage-gyms' },
  { url: '/playback-workout/:workout_id', component: () => import('./pages/general/PlaybackWorkout/PlaybackWorkout'), key: 'play-workout' },
  { url: '/send-auto-messages', component: () => import('./pages/instructor/SendAutoMessages/SendAutoMessages'), key: 'send-auto' },
  { url: '/customer-service', component: () => import('./pages/admin/CustomerService/CustomerService'), key: 'customer-service' },
  { url: '/gym-plans', component: () => import('./pages/consumer/GymPlans/GymPlans'), key: 'gym-plans' },
  { url: '/account-settings', component: () => import('./pages/general/AccountSettings/AccountSettings'), key: 'account-settings' },
  { url: '/analytics', component: () => import('./pages/analytics/Analytics/Analytics'), key: 'analytics' },
  { url: '/test-page', component: () => import('./test-page'), key: 'test-page' }
];

const menulessPages: Page[] = [
  { url: '/recording-playback/:guid', component: () => import('./pages/general/RecordingPlayback/RecordingPlayback'), key: 'rec-play' }
];

const finishSignupPages: Page[] = [
  { url: '/finish-consumer-signup', component: () => import('./pages/onboard/FinishConsumerSignup/FinishConsumerSignup'), key: 'cons-ob' },
  { url: '/finish-trainer-signup', component: () => import('./pages/onboard/FinishTrainerSignup/FinishTrainerSignup'), key: 'train-ob' },
  { url: '/finish-employer-signup', component: () => import('./pages/onboard/FinishEmployerSignup/FinishEmployerSignup'), key: 'emp-ob' }
];

const Content: React.FC = React.memo(() => {
  // const level = useConsumerAppVersion();
  // const user = useUser();
  return (
    <div className='mu-content-wrapper'>
      {/*{user?.userType === 'CONSUMER' && level === ConsumerAppVersion.FREE ? (*/}
      {/*  <>*/}
      {/*    <script*/}
      {/*      async*/}
      {/*      src='https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5599344544564566'*/}
      {/*      crossOrigin='anonymous'*/}
      {/*    ></script>*/}
      {/*    <ins*/}
      {/*      className='adsbygoogle'*/}
      {/*      style={{ display: 'block', margin: 20 }}*/}
      {/*      data-ad-client='ca-pub-5599344544564566'*/}
      {/*      data-ad-slot='7270036923'*/}
      {/*      data-ad-format='auto'*/}
      {/*      data-full-width-responsive='true'*/}
      {/*    ></ins>*/}
      {/*    <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>*/}
      {/*  </>*/}
      {/*) : null}*/}
      <div className='mu-content' key='content'>
        <ErrorBoundary FallbackComponent={ErrorHandler} key={window.location.href}>
          <Suspense fallback={fallbackPage}>
            <Route exact path='/' component={Home} key='home' />
            {pages.map((p) => (
              <Route path={p.url} exact component={lazy(p.component)} key={p.key} />
            ))}
          </Suspense>
        </ErrorBoundary>
      </div>
    </div>
  );
});

const MenuApp: React.FC = () => {
  const [menu, setMenu] = useState(false);

  return (
    <div className='App'>
      <MUHeader menu={menu} setMenu={setMenu} />
      <div className='main-level'>
        <MUMenu menu={menu} setMenu={setMenu} />
        <Content key={window.location.href} />
      </div>
    </div>
  );
};

const AppWrapper: React.FC = () => {
  const setKey = useState(0)[1];

  return (
    <TourWrapper>
      <BrowserRouter key={window.location.href}>
        {menulessPages.map((p) => (
          <Route path={p.url} exact component={lazy(p.component)} key={p.key} />
        ))}
        <MenuApp />
      </BrowserRouter>
    </TourWrapper>
  );
};

const FinishSignupWrapper: React.FC = React.memo(() => {
  return (
    <TourWrapper>
      <BrowserRouter>
        <Suspense fallback={fallbackRoot}>
          {finishSignupPages.map((p) => (
            <Route path={p.url} component={lazy(p.component)} key={p.key} />
          ))}
        </Suspense>
      </BrowserRouter>
    </TourWrapper>
  );
});

// function log(msg: string) {
//   fetch('https://dev-api.motivateu-dev.net/log', {
//     method: 'POST',
//     body: JSON.stringify({ msg }),
//     headers: { 'Content-Type': 'application/json' },
//   }).then(() => {});
// }

const ONBOARD_PAGE: { [userType: string]: string } = {
  CONSUMER: '/finish-consumer-signup',
  INSTRUCTOR: '/finish-trainer-signup',
  EMPLOYER: '/finish-employer-signup'
};

const LoggedInApp: React.FC = () => {
  const token = useToken();
  const user = useUser();

  if (token === null) return <LoginPage />;
  if (token === undefined || !user) return fallbackRoot;

  logActions();
  if (user) {
    try {
      // @ts-ignore
      const tracker = Matomo.getAsyncTracker('//analytics.motivateu.live/matomo.php', window.siteId);
      tracker.setUserId(user.email);
      localStorage.userId = user.email;
    } catch (e) {}

    if (user.unfinishedSignup) {
      if (!window.location.pathname.includes('signup')) return <ReactRouterDom.Redirect to={ONBOARD_PAGE[user.userType]} />;
      else return <FinishSignupWrapper key={window.location.href} />;
    }
  }

  return <AppWrapper />;
};

const Redirect: React.FC = () => {
  if (window.location.pathname.indexOf('help') > -1) window.location.href = 'https://www.motivateu.net/help.html';
  else if (window.location.pathname.indexOf('resources') > -1) window.location.href = 'https://www.motivateu.net/resources.html';
  else if (window.location.pathname.indexOf('terms') > -1) window.location.href = 'https://www.motivateu.net/termsandconditions.html';
  else if (window.location.pathname.indexOf('privacy') > -1) window.location.href = 'https://www.motivateu.net/privacypolicy.html';
  else if (window.location.pathname.indexOf('about-consumer') > -1) window.location.href = 'https://www.motivateu.net/individuals.html';
  else if (window.location.pathname.indexOf('about') > -1) window.location.href = 'https://www.motivateu.net/';

  return <div />;
};

const topPages: Page[] = [
  { url: '/consumer-signup', component: () => import('./pages/signup/ConsumerSignup/ConsumerSignup'), key: 'cons-su' },
  { url: '/trainer-signup', component: () => import('./pages/signup/TrainerSignup/TrainerSignup'), key: 'train-su' },
  { url: '/employer-signup', component: () => import('./pages/signup/EmployerSignup/EmployerSignup'), key: 'emp-su' },
  { url: '/offering-signup', component: () => import('./pages/signup/OfferingSignup/OfferingSignup'), key: 'off-su' },
  { url: '/finish-signup/:code', component: () => import('./pages/signup/FinishSignup/FinishSignup'), key: 'fin-su' },
  { url: '/recordings/embed', component: () => import('./pages/general/EmbedRecording/EmbedRecording'), key: 'embed-recordings' },
  { url: '/consulting', component: () => import('./pages/admin/Consulting/Consulting'), key: 'consulting' },
  { url: '/private-classes', component: () => import('./pages/admin/Consulting/PrivateClasses'), key: 'private-classes' },
  { url: '/startups', component: () => import('./pages/admin/Consulting/Startups'), key: 'startups' },
  { url: '/unsubscribe/:code', component: () => import('./pages/admin/ManageEmails/Unsubscribe'), key: 'unsubscribe' },
  { url: '/finish-login', component: () => import('./pages/general/FinishLogin/FinishLogin'), key: 'finish-login' },
  { url: '/test', component: () => import('./test'), key: 'test' }
];

// For pages that you don't have to be logged in for
export default function App() {
  return (
    <ErrorBoundary FallbackComponent={ErrorHandler}>
      <BrowserRouter>
        <Switch>
          <Suspense fallback={fallbackRoot}>
            {topPages.map((p) => (
              <Route exact path={p.url} component={lazy(p.component)} key={p.key} />
            ))}
            <Route path='/help' component={Redirect} key='redirect-help' />
            <Route path='/resources' component={Redirect} key='redirect-resources' />
            <Route path='/terms' component={Redirect} key='redirect-terms' />
            <Route path='/privacy' component={Redirect} key='redirect-privacy' />
            <Route path='/about' component={Redirect} key='redirect-about' />
            <Route path='/about-consumer' component={Redirect} key='redirect-about-consumer' />
            <Route path='/convoy-members' render={() => <ReactRouterDom.Redirect to='/employer-signup?partner=convoy' />} />
            <Route
              exact
              path={[
                '/',
                ...pages
                  .concat(finishSignupPages)
                  .concat(menulessPages)
                  .map((p) => p.url)
              ]}
              component={LoggedInApp}
              key='home'
            />
          </Suspense>
        </Switch>
      </BrowserRouter>
    </ErrorBoundary>
  );
}
