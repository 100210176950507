import React, { HTMLProps } from 'react';
import './MuTextarea.scss';
import { MuInfoButton } from '../MuInfoButton/MuInfoButton';

interface MuTextareaProps extends Omit<HTMLProps<HTMLTextAreaElement>, 'onBlur' | 'onChange'> {
  label?: string;
  value: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  errorMessage?: string;
  className?: string;
  helpInfo?: string;
  rows?: number;
  cols?: number;
  required?: boolean;
  noMargin?: boolean;
  maxLength?: number;
}

export const MuTextarea: React.FC<MuTextareaProps> = ({
  label,
  value,
  placeholder,
  onChange,
  onBlur,
  errorMessage,
  className,
  helpInfo,
  rows,
  cols,
  required,
  noMargin,
  maxLength,
  ...props
}) => {
  function onChange2(event: any) {
    const value = event.target.value;
    if (onChange) {
      onChange(value);
    }
  }

  function onBlur2() {
    if (onBlur) {
      onBlur(value);
    }
  }

  if (required && !value) errorMessage = errorMessage || 'Required';

  return (
    <div className={`mu-textarea ${noMargin ? 'no-margin' : ''}`}>
      {label ? (
        <span className='mu-input-label mu-text-md-bold'>
          {label} &nbsp;
          {helpInfo ? <MuInfoButton title={helpInfo} /> : null}
        </span>
      ) : null}
      <textarea
        value={value}
        placeholder={placeholder}
        onChange={onChange2}
        onBlur={onBlur2}
        className={`${errorMessage ? 'error' : ''} ${className}`}
        rows={rows}
        cols={cols}
        maxLength={maxLength}
        {...props}
      />
      {errorMessage ? (
        <span className='mu-input-error-message mu-text-sm'>
          {errorMessage.split('\n').map((line, idx) => (
            <React.Fragment key={idx}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </span>
      ) : null}
      {maxLength ? (
        <div className='char-counter'>
          {value.length} / {maxLength}
        </div>
      ) : null}
    </div>
  );
};
