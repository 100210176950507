export const spanish_strings = {
  accept_clients: null, // 'Accept New Clients',
  administration: null, // 'Administration',
  assessments: null, // 'Assessments',
  billing: null, // 'Billing',
  billing_setup: null, // 'Billing Setup',
  calendar: null, // 'Calendar',
  challenges: null, // 'Challenges',
  chat: null, // 'Chat',
  classes: null, // 'Classes',
  client_billing: null, // 'Client Billing',
  clients: null, // 'Clients',
  community: null, // 'Community',
  connect_to_trainers: null, // 'Connect To Trainers',
  connections: null, // 'Connections',
  employees: null, // 'Employees',
  help: null, // 'Help',
  insights: null, // 'Insights',
  launch_class: null, // 'Launch Class',
  leaderboard: null, // 'Leaderboard',
  logout: null, // 'Logout',
  manage: null, // 'Manage',
  manage_gyms: null, // 'Manage Gyms',
  my_goals: null, // 'My Goals',
  notifications: null, // 'Notifications',
  on_demand: null, // 'On-Demand',
  on_demand_videos: null, // 'On-Demand Videos',
  profile: null, // 'Profile',
  progress: null, // 'Progress',
  report_bug: null, // 'Report Bug',
  rewards: null, // 'Rewards',
  schedule: null, // 'Schedule',
  swag_store: null, // 'Swag Store',
  trainers_clients: null, // 'Trainers / Clients',
  view: null, // 'View',
  workout_results: null, // 'Workout Results',
};
