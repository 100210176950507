import React from 'react';
import './MuRadio.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle as faCircleFilled } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';

export interface MuRadioOption<T> {
  value: T;
  display: string;
  description?: string;
  disabled?: boolean;
}

interface MuRadioProps<T> {
  options: MuRadioOption<T>[];
  value: T;
  label?: string;

  onChange(value: T): void;
}

export function MuRadio<T>({ options, value, onChange, label }: MuRadioProps<T>) {
  return (
    <div className='mu-radio'>
      {label ? <div className='mu-text-md-bold'>{label}</div> : null}
      {options.map((option) => (
        <div
          className={'radio-button' + (option.disabled ? ' disabled' : '')}
          onClick={option.disabled ? undefined : () => onChange(option.value)}
          key={'' + option.value}
        >
          <FontAwesomeIcon icon={value === option.value ? faCircleFilled : faCircle} /> {option.display}
          <div className='mu-text-sm-light'>{option.description}</div>
        </div>
      ))}
    </div>
  );
}
