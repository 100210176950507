import { getToken, MotivateU } from './util';
import EventEmitter from 'events';

export type Kind = 'audio' | 'video';

export class ServerConnection {
  videoServerGuid?: string;
  syncServerGuid?: string;
  serverMap?: { [x: string]: number };
  userUpdate: EventEmitter = new EventEmitter();
  syncWS?: WebSocket;
  syncHandlers: { [type: string]: ((message: any) => void)[] } = {};
  syncAuthComplete = false;
  private syncMessageQueue: string[] = [];
  manuallyClosed = false;

  constructor(public sessionId?: number, public recordingId?: number) {}

  async init() {
    const initialSite = window.location.href;
    const servers = await MotivateU.api.getSessionServers({ sessionId: this.sessionId, recordingId: this.recordingId });
    console.log(JSON.stringify(servers));
    this.syncServerGuid = servers.SYNCHRONIZATION.guid;
    if (servers.VIDEO) {
      this.videoServerGuid = servers.VIDEO.guid;
      window.setInterval(async () => {
        const servers = await MotivateU.api.sessionInstanceServers.filter({ filters: { sessionId: this.sessionId } });
        if (this.serverMap == null) {
          this.serverMap = {};
          const servers = await MotivateU.api.servers.get();
          servers.map((server) => (this.serverMap![server.guid] = server.id));
        }
        for (const server of servers) {
          if (server.serverType === 'VIDEO' && server.serverId !== this.serverMap[this.videoServerGuid!]) {
            window.location.reload();
          }
          if (server.serverType === 'SYNCHRONIZATION' && server.serverId !== this.serverMap[this.syncServerGuid!]) {
            window.location.reload();
          }
        }
      }, 30_000);
    }

    const userToken = await getToken();
    if (servers.VIDEO) {
      let wsUrl = 'wss://nathan.motivateu-dev.net:7123';
      if (this.videoServerGuid !== 'local') {
        const origin = window.location.origin.split('/')[2].split('.');
        const domain = origin.slice(origin.length - 2).join('.');
        wsUrl = `wss://${this.videoServerGuid}.${domain}:7123`;
      }
      const ws = new WebSocket(wsUrl);
      ws.onclose = () => {
        if (initialSite !== window.location.href) return;
        if (this.manuallyClosed) return;
        alert('Could not connect to the server');
        window.location.href = '/';
        // window.location.reload();
      };

      await new Promise((resolve) => (ws.onopen = resolve));

      ws.send(JSON.stringify({ userToken, sessionId: this.sessionId }));
      ws.onmessage = (msg) => {
        const message = JSON.parse(msg.data);
        if (message.type === 'updateUsers') {
          this.userUpdate.emit('userUpdate', message.users);
        }
      };
    }

    let syncUrl = 'wss://nathan.motivateu-dev.net:8123';
    if (this.syncServerGuid !== 'local') {
      const origin = window.location.origin.split('/')[2].split('.');
      const domain = origin.slice(origin.length - 2).join('.');
      syncUrl = `wss://${this.syncServerGuid}.${domain}:8123`;
    }
    this.syncWS = new WebSocket(syncUrl);
    this.syncWS.onclose = () => {
      if (initialSite !== window.location.href) return;
      if (this.manuallyClosed) return;
      // TODO: how do we handle the websocket closing?
      alert('Could not connect to the server');
      window.location.href = '/';
      // window.location.reload();
    };
    await new Promise((resolve) => (this.syncWS!.onopen = resolve));

    this.syncWS.send(JSON.stringify({ userToken, sessionId: this.sessionId, recordingId: this.recordingId }));
    this.syncWS.onmessage = this.handleSyncMessage;
  }

  async getVideoUrl(): Promise<string> {
    const origin = window.location.origin.split('/')[2].split('.');
    const domain = origin.slice(origin.length - 2).join('.');
    if (!this.videoServerGuid) {
      return new Promise((resolve) => {
        const timer = window.setInterval(() => {
          if (this.videoServerGuid) {
            window.clearInterval(timer);
            if (this.videoServerGuid === 'local') {
              resolve('https://nathan.motivateu-dev.net:6123');
            } else {
              resolve(`https://${this.videoServerGuid}.${domain}:6123`);
            }
          }
        }, 100);
      });
    }
    if (this.videoServerGuid === 'local') {
      return 'https://nathan.motivateu-dev.net:6123';
    }
    return `https://${this.videoServerGuid}.${domain}:6123`;
  }

  // async getConnectedUsers(): Promise<number[]> {
  //   return this.postVideo('get-connected-users', {});
  // }

  async postVideo(url: string, params: any) {
    const authToken = (await getToken()) || '';
    const resp = await fetch(`${await this.getVideoUrl()}/${url}`, {
      method: 'POST',
      body: JSON.stringify({ ...params, sessionId: this.sessionId }),
      headers: {
        Authorization: authToken,
        Authorization2: authToken,
        'Content-Type': 'application/json'
      }
    });
    return await resp.json();
  }

  async getRouterRtpCapabilities() {
    return this.postVideo('get-router-rtp-capabilities', {});
  }

  async createProducerTransport() {
    return this.postVideo('create-producer-transport', {});
  }

  async connectProducerTransport(data: any) {
    return this.postVideo('connect-producer-transport', { data });
  }

  async produce(data: { kind: Kind; rtpParameters: any }) {
    return this.postVideo('produce', { data });
  }

  async createConsumerTransport() {
    return this.postVideo('create-consumer-transport', {});
  }

  async connectConsumerTransport(data: any) {
    return this.postVideo('connect-consumer-transport', { data });
  }

  async consume(from: number, data: { kind: Kind; rtpCapabilities: any }) {
    return this.postVideo('consume', { from, data });
  }

  resume(from: number, kind: Kind) {
    return this.postVideo('resume', { from, kind });
  }

  startRecording() {
    return this.postVideo('start-recording', {});
  }

  stopRecording() {
    return this.postVideo('stop-recording', {});
  }

  updateSharing() {
    return this.postVideo('update-sharing', {});
  }

  registerSyncHandler(type: string, handler: (message: any) => void) {
    this.syncHandlers[type] = this.syncHandlers[type] || [];
    this.syncHandlers[type].push(handler);
  }

  sendSyncMessage(type: string, message: any) {
    const msg = JSON.stringify({ type, ...message });
    if (this.syncWS?.readyState === WebSocket.OPEN && this.syncAuthComplete) this.syncWS.send(msg);
    else this.syncMessageQueue.push(msg);
  }

  close() {
    this.manuallyClosed = true;
    this.syncWS?.close();
  }

  private handleSyncMessage = (msg: MessageEvent) => {
    msg = JSON.parse(msg.data);
    if (msg.type === 'auth-complete') {
      this.syncAuthComplete = true;
      for (const msg of this.syncMessageQueue) this.syncWS?.send(msg);
    }
    if (msg.type in this.syncHandlers) {
      for (const handler of this.syncHandlers[msg.type]) {
        handler(msg);
      }
    }
  };
}
