import React, { useEffect, useState } from 'react';
import './MuTabs.scss';
import { MuSelect } from '../MuSelect/MuSelect';

interface MuTab {
  id: string;
  title: string;
  content: () => any;
}

interface MuTabsProps {
  tabs: MuTab[];
  defaultTab?: string;
  tab?: string;
  disableHash?: boolean;
  mobileFriendly?: boolean;

  onChangeTab?(tab: string): void;
}

export const MuTabs: React.FC<MuTabsProps> = ({ tabs, defaultTab, tab, onChangeTab, disableHash, mobileFriendly }) => {
  const hash = window.location.hash.replace('#', '');
  const def = (disableHash ? null : tabs.filter((t) => t.id === hash).length ? hash : null) || defaultTab || tabs[0]?.id;
  const [currentTab, setCurrentTab] = useState(def);
  const [chosenTab, setChosenTab] = useState(false);
  const tb = tab || currentTab;

  useEffect(() => {
    if (currentTab !== def && !chosenTab) setCurrentTab(def);
  }, [tabs]);

  function setTab(tb: string) {
    return () => {
      setCurrentTab(tb);
      setChosenTab(true);
      if (onChangeTab) onChangeTab(tb);
      if (!tab && !disableHash) window.location.hash = `#${tb}`;
    };
  }

  return (
    <div className={'mu-tabs-container' + (mobileFriendly ? ' mobile-friendly' : '')}>
      <div className='mu-tabs'>
        {tabs.map((tab) => (
          <div key={tab.id} className={'mu-tab' + (tab.id === tb ? ' selected' : '')} onClick={setTab(tab.id)}>
            {tab.title}
          </div>
        ))}
      </div>
      <div className='mu-tabs-mobile'>
        <MuSelect value={tb} options={tabs.map((tab) => ({ value: tab.id, display: tab.title }))} onChange={(tb) => setTab(tb)()} />
      </div>
      <div>{tabs.filter((t: MuTab) => t.id === tb)[0]?.content()}</div>
    </div>
  );
};
