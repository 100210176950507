import { MultipartUpload } from './multipart-upload';
import { sleep } from './util/';

export const RECORDING_TYPE = MediaRecorder.isTypeSupported('video/webm') ? 'webm' : 'mp4';

export class WebcamRecorder {
  recorder: MediaRecorder;
  upload: MultipartUpload;
  parts: Promise<void>[] = [];
  partNum = 0;
  canceled = false;
  finishing = false;
  private dataBuffer = new Blob();

  constructor(private stream: MediaStream, private baseUrl: string, private queryString: string, private path: string) {
    this.recorder = new MediaRecorder(stream, { mimeType: 'video/' + RECORDING_TYPE, bitsPerSecond: 1_000_000 });
    this.recorder.ondataavailable = this.uploadPart;
    this.recorder.onstop = () => stream.getTracks().forEach((track) => track.stop());
    this.upload = new MultipartUpload(baseUrl, queryString, path);
  }

  public async init() {
    if (this.canceled) return;
    await this.upload.init();
    // Upload a chunk every 10s
    this.recorder.start(10_000);
  }

  public async finalize() {
    if (this.canceled) return;
    this.finishing = true;
    if (this.recorder.state === 'recording') this.recorder.stop();
    await sleep(100);
    await Promise.all(this.parts);
    await this.upload.finalize();
  }

  public async cancel() {
    this.canceled = true;
    if (this.recorder.state === 'recording') this.recorder.stop();
  }

  private uploadPart = async (data: BlobEvent) => {
    if (this.canceled) return;
    this.dataBuffer = new Blob([this.dataBuffer, data.data]);
    if (this.dataBuffer.size >= 6_000_000 || this.finishing) this.parts.push(this.upload.uploadPart(this.partNum++, this.dataBuffer));
  };
}
