import React, { HTMLProps } from 'react';
import './MuImageCard.scss';

interface MuImageCardProps extends HTMLProps<HTMLDivElement> {
  imageHeight?: number;
  imageWidth?: number;
  height?: number;
  imageSrc: string;
  imageAlt?: string;
}

export const MuImageCard: React.FC<MuImageCardProps> = ({
  imageHeight,
  imageWidth,
  height,
  imageSrc,
  imageAlt = 'image',
  children,
  className = '',
  ...props
}) => {
  return (
    <div className={'mu-image-card ' + className} {...props}>
      <div className='image' style={{ height: imageHeight, width: imageWidth, minWidth: imageWidth }}>
        <img src={imageSrc} alt={imageAlt} />
      </div>
      <div className='content' style={{ height }}>
        {children}
      </div>
    </div>
  );
};
